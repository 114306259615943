// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../Icon.bs.js";
import * as React from "react";
import * as Dropdown2 from "./Dropdown2.bs.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function str(prim) {
  return prim;
}

function contents(links) {
  return links.map(function (link, index) {
              return React.createElement("div", {
                          key: String(index),
                          className: ""
                        }, React.createElement("a", {
                              className: "cursor-pointer block p-3 text-xs font-semibold text-gray-900 border-b border-gray-50 bg-white hover:text-primary-500 hover:bg-gray-50 focus:outline-none focus:text-primary-500 focus:bg-gray-50",
                              href: link.url,
                              rel: "noopener"
                            }, link.name));
            });
}

function showSelected(placeholder, selected) {
  return React.createElement("button", {
              key: "selected",
              className: "mt-1 flex space-x-2 items-center justify-between appearance-none w-full bg-white border border-gray-300 rounded py-2.5 px-3 text-sm focus:outline-none focus:bg-white focus:border-transparent focus:ring-2 focus:ring-focusColor-500"
            }, React.createElement("span", undefined, selected !== undefined ? selected.name : placeholder), React.createElement(Icon.make, {
                  className: "if i-chevron-down-regular if-fw"
                }));
}

function SelectLink(Props) {
  var links = Props.links;
  var selectedLink = Props.selectedLink;
  var placeholderOpt = Props.placeholder;
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "Select";
  if (links.length !== 0) {
    return React.createElement(Dropdown2.make, {
                selected: showSelected(placeholder, selectedLink),
                contents: contents(links),
                right: true,
                key: "links-dropdown"
              });
  } else {
    return null;
  }
}

function decodeLink(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          url: Json_decode.field("url", Json_decode.string, json)
        };
}

function makeFromJson(json) {
  return SelectLink({
              selectedLink: Json_decode.optional((function (param) {
                      return Json_decode.field("selectedLink", decodeLink, param);
                    }), json),
              placeholder: Json_decode.optional((function (param) {
                      return Json_decode.field("placeholder", Json_decode.string, param);
                    }), json),
              links: Json_decode.field("links", (function (param) {
                      return Json_decode.array(decodeLink, param);
                    }), json)
            });
}

var make = SelectLink;

export {
  str ,
  contents ,
  showSelected ,
  make ,
  decodeLink ,
  makeFromJson ,
  
}
/* Icon Not a pure module */
