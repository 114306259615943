// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Icon from "../../../shared/Icon.bs.js";
import * as Link from "../../../shared/components/Link.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as ReactUtils from "../../../shared/utils/ReactUtils.bs.js";
import * as AppRouter__Page from "../types/AppRouter__Page.bs.js";
import * as AppRouter__Course from "../types/AppRouter__Course.bs.js";
import * as AppRouter__Header from "./AppRouter__Header.bs.js";
import * as AppRouter__CourseSelector from "./AppRouter__CourseSelector.bs.js";

import "./AppRouter__Nav.css"
;

var partial_arg = "components.AppRouter__Nav";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function str(prim) {
  return prim;
}

function showLink(id, selectedPage, page, classes, title, contents) {
  if (AppRouter__Page.useSPA(selectedPage, {
          TAG: /* SelectedCourse */0,
          _0: id,
          _1: page
        })) {
    return React.createElement(Link.make, {
                href: AppRouter__Page.coursePath(id, page),
                className: classes,
                title: title,
                children: contents,
                key: title
              });
  } else {
    return React.createElement("a", {
                key: title,
                className: classes,
                title: title,
                href: AppRouter__Page.coursePath(id, page)
              }, contents);
  }
}

function renderLinks(courses, selectedPage) {
  switch (selectedPage.TAG | 0) {
    case /* SelectedCourse */0 :
        var coursePage = selectedPage._1;
        var courseId = selectedPage._0;
        var currentCourse = ArrayUtils.unsafeFind((function (course) {
                return AppRouter__Course.id(course) === courseId;
              }), "Could not find currentCourse with ID " + courseId, courses);
        var url = AppRouter__Course.thumbnailUrl(currentCourse);
        return React.createElement("div", undefined, React.createElement("div", {
                        className: "relative pb-1/2 bg-gray-800 rounded-lg"
                      }, url !== undefined ? React.createElement("img", {
                              className: "absolute h-full w-full object-cover rounded-lg",
                              src: url
                            }) : React.createElement("div", {
                              className: "app-router-nav__course-cover absolute h-full w-full svg-bg-pattern-1 rounded-lg "
                            })), React.createElement("div", {
                        className: "mt-4"
                      }, React.createElement(AppRouter__CourseSelector.make, {
                            courses: courses,
                            selectedPage: selectedPage,
                            coursePage: coursePage,
                            currentCourse: currentCourse
                          })), React.createElement("div", {
                        className: "mt-4 space-y-3"
                      }, AppRouter__Page.activeLinks(currentCourse).map(function (link) {
                            var match;
                            switch (link) {
                              case /* Curriculum */0 :
                                  match = [
                                    t(undefined, undefined, "curriculum"),
                                    "i-journal-text-light"
                                  ];
                                  break;
                              case /* Report */1 :
                                  match = [
                                    t(undefined, undefined, "report"),
                                    "i-graph-up-light"
                                  ];
                                  break;
                              case /* Review */2 :
                                  match = [
                                    t(undefined, undefined, "review"),
                                    "i-clipboard-check-light"
                                  ];
                                  break;
                              case /* Students */3 :
                                  match = [
                                    t(undefined, undefined, "students"),
                                    "i-users-light"
                                  ];
                                  break;
                              case /* Leaderboard */4 :
                                  match = [
                                    t(undefined, undefined, "leaderboard"),
                                    "i-tachometer-alt-light"
                                  ];
                                  break;
                              case /* Calendar */5 :
                                  match = [
                                    t(undefined, undefined, "calendar"),
                                    "i-calendar-regular"
                                  ];
                                  break;
                              
                            }
                            var title = match[0];
                            var classes = "app-router-navbar__primary-nav-link py-3 px-2 mb-1 " + (
                              link === coursePage ? "app-router-navbar__primary-nav-link--active" : ""
                            );
                            return showLink(courseId, selectedPage, link, classes, title, [
                                        React.createElement(Icon.make, {
                                              className: "if " + match[1] + " text-xl if-fw",
                                              key: "icon"
                                            }),
                                        React.createElement("div", {
                                              key: "title",
                                              className: "pl-3"
                                            }, title)
                                      ]);
                          })));
    case /* Student__SubmissionShow */1 :
    case /* Student__StudentsReport */2 :
        return null;
    
  }
}

function courseSelector(courses, selectedPage) {
  switch (selectedPage.TAG | 0) {
    case /* SelectedCourse */0 :
        var courseId = selectedPage._0;
        var currentCourse = ArrayUtils.unsafeFind((function (course) {
                return AppRouter__Course.id(course) === courseId;
              }), "Could not find currentCourse with ID " + courseId, courses);
        var url = AppRouter__Course.thumbnailUrl(currentCourse);
        return React.createElement("div", undefined, React.createElement("div", {
                        className: "relative pb-1/2 bg-gray-800"
                      }, url !== undefined ? React.createElement("img", {
                              className: "absolute h-full w-full object-cover",
                              src: url
                            }) : React.createElement("div", {
                              className: "app-router-nav__course-cover absolute h-full w-full svg-bg-pattern-1 rounded-lg "
                            })), React.createElement("div", {
                        className: "-mt-11 pb-2 md:pb-0 md:mt-4 px-2"
                      }, React.createElement(AppRouter__CourseSelector.make, {
                            courses: courses,
                            selectedPage: selectedPage,
                            coursePage: selectedPage._1,
                            currentCourse: currentCourse
                          })));
    case /* Student__SubmissionShow */1 :
    case /* Student__StudentsReport */2 :
        return null;
    
  }
}

function renderLinksMobile(courses, selectedPage) {
  switch (selectedPage.TAG | 0) {
    case /* SelectedCourse */0 :
        var coursePage = selectedPage._1;
        var courseId = selectedPage._0;
        var currentCourse = ArrayUtils.unsafeFind((function (course) {
                return AppRouter__Course.id(course) === courseId;
              }), "Could not find currentCourse with ID " + courseId, courses);
        return React.createElement("div", {
                    className: "flex"
                  }, AppRouter__Page.activeLinks(currentCourse).map(function (link) {
                        var match;
                        switch (link) {
                          case /* Curriculum */0 :
                              match = [
                                t(undefined, undefined, "curriculum"),
                                "i-journal-text-regular"
                              ];
                              break;
                          case /* Report */1 :
                              match = [
                                t(undefined, undefined, "report"),
                                "i-graph-up-regular"
                              ];
                              break;
                          case /* Review */2 :
                              match = [
                                t(undefined, undefined, "review"),
                                "i-clipboard-check-regular"
                              ];
                              break;
                          case /* Students */3 :
                              match = [
                                t(undefined, undefined, "students"),
                                "i-users-regular"
                              ];
                              break;
                          case /* Leaderboard */4 :
                              match = [
                                t(undefined, undefined, "leaderboard"),
                                "i-tachometer-alt-regular"
                              ];
                              break;
                          case /* Calendar */5 :
                              match = [
                                t(undefined, undefined, "calendar"),
                                "i-calendar-regular"
                              ];
                              break;
                          
                        }
                        var title = match[0];
                        var classes = "flex flex-col flex-1 items-center py-3 text-xs text-gray-800 font-semibold " + (
                          coursePage === link ? "text-primary-500 bg-gray-50" : "hover:text-primary-500 hover:bg-gray-50"
                        );
                        return showLink(courseId, selectedPage, link, classes, title, [
                                    React.createElement(Icon.make, {
                                          className: "if " + match[1] + " text-lg if-fw",
                                          key: "icon"
                                        }),
                                    React.createElement("div", {
                                          key: "title",
                                          className: "pt-1"
                                        }, title)
                                  ]);
                      }));
    case /* Student__SubmissionShow */1 :
    case /* Student__StudentsReport */2 :
        return null;
    
  }
}

function AppRouter__Nav(Props) {
  var school = Props.school;
  var courses = Props.courses;
  var selectedPage = Props.selectedPage;
  var currentUser = Props.currentUser;
  var match = React.useState(function () {
        return false;
      });
  var setSidebarOpen = match[1];
  return [
          ReactUtils.nullUnless(React.createElement("div", {
                    key: "sidebar",
                    className: "fixed inset-0 flex z-40 md:hidden"
                  }, React.createElement("div", undefined, React.createElement("div", {
                            className: "relative flex-1 flex flex-col max-w-xs w-full bg-white"
                          }, React.createElement("div", undefined, React.createElement("div", {
                                    className: "absolute top-0 right-0 -mr-12 pt-2"
                                  }, React.createElement("button", {
                                        className: "ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white",
                                        onClick: (function (_e) {
                                            return Curry._1(setSidebarOpen, (function (param) {
                                                          return false;
                                                        }));
                                          })
                                      }, React.createElement("span", {
                                            className: "sr-only"
                                          }, t(undefined, undefined, "close_sidebar"))))), React.createElement("div", {
                                className: "flex-1 h-0 pt-5 pb-4 overflow-y-auto"
                              }, React.createElement("nav", {
                                    className: "mt-5 px-2 space-y-1"
                                  }, renderLinks(courses, selectedPage))))), React.createElement("div", {
                        className: "shrink-0 w-14"
                      })), match[0]),
          React.createElement("div", {
                key: "main",
                className: "flex shrink-0"
              }, React.createElement("div", {
                    className: "flex flex-1 flex-col"
                  }, React.createElement("div", {
                        className: "px-4 py-2 bg-white border-b h-16 md:fixed w-full md:inset-x-0 md:top-0 z-50"
                      }, React.createElement(AppRouter__Header.make, {
                            school: school,
                            currentUser: currentUser
                          })), React.createElement("div", {
                        className: "md:hidden"
                      }, courseSelector(courses, selectedPage))), React.createElement("div", {
                    className: "approuter-nav__sidebar hidden md:flex flex-col"
                  }, React.createElement("div", {
                        className: "flex h-full flex-col flex-1 border-r bg-white"
                      }, React.createElement("div", {
                            className: "flex-1 flex flex-col pt-4 pb-4 overflow-y-auto md:mt-16"
                          }, React.createElement("nav", {
                                className: "flex-1 px-4 bg-white"
                              }, renderLinks(courses, selectedPage)))))),
          React.createElement("div", {
                key: "mobile-links",
                className: "md:hidden fixed inset-x-0 bottom-0 flex-1 bg-white border-t z-50"
              }, renderLinksMobile(courses, selectedPage))
        ];
}

var make = AppRouter__Nav;

export {
  t ,
  str ,
  showLink ,
  renderLinks ,
  courseSelector ,
  renderLinksMobile ,
  make ,
  
}
/*  Not a pure module */
