// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../utils/I18n.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";

import "./LoadingSpinner.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "shared";

function ts(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function onAnimationEnd(loading, setRender) {
  if (!loading) {
    return Curry._1(setRender, (function (param) {
                  return false;
                }));
  }
  
}

function animationClass(loading) {
  if (loading) {
    return "loading-spinner__slide-up";
  } else {
    return "loading-spinner__slide-down";
  }
}

function LoadingSpinner(Props) {
  var loading = Props.loading;
  var messageOpt = Props.message;
  var message = messageOpt !== undefined ? messageOpt : ts(undefined, undefined, "loading");
  var match = React.useState(function () {
        return loading;
      });
  var setRender = match[1];
  var initialRender = React.useRef(true);
  React.useEffect((function () {
          if (initialRender.current) {
            initialRender.current = false;
          } else if (loading) {
            Curry._1(setRender, (function (param) {
                    return true;
                  }));
          }
          
        }), [loading]);
  if (match[0]) {
    return React.createElement("div", {
                className: "fixed bottom-0 z-50 w-full left-0 right-0 flex justify-center w-full"
              }, React.createElement("div", {
                    className: "loading-spinner__container " + (
                      loading ? "loading-spinner__slide-up" : "loading-spinner__slide-down"
                    ),
                    onAnimationEnd: (function (param) {
                        return onAnimationEnd(loading, setRender);
                      })
                  }, React.createElement("div", {
                        className: "loading-spinner__xs"
                      }, React.createElement("svg", {
                            className: "loading-spinner__svg",
                            viewBox: "0 0 50 50"
                          }, React.createElement("circle", {
                                className: "loading-spinner__svg-path",
                                cx: "25",
                                cy: "25",
                                fill: "none",
                                r: "20",
                                strokeWidth: "5"
                              }))), React.createElement("span", {
                        className: "inline-block ml-2 text-xs text-white font-semibold tracking-wide"
                      }, message)));
  } else {
    return null;
  }
}

var make = LoadingSpinner;

export {
  str ,
  ts ,
  onAnimationEnd ,
  animationClass ,
  make ,
  
}
/*  Not a pure module */
