// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as PfIcon from "../../../pf-icon/src/PfIcon.bs.js";
import * as $$String from "../../../../../../node_modules/rescript/lib/es6/string.js";
import * as Js_math from "../../../../../../node_modules/rescript/lib/es6/js_math.js";
import * as Belt_SortArray from "../../../../../../node_modules/rescript/lib/es6/belt_SortArray.js";

import "./MultiselectInline.css"
;

function str(prim) {
  return prim;
}

function Make(Selectable) {
  var search = function (searchString, selections) {
    return Belt_SortArray.stableSortBy(selections.filter(function (selection) {
                    return $$String.lowercase_ascii(Curry._1(Selectable.searchString, selection)).includes($$String.lowercase_ascii(searchString));
                  }), (function (x, y) {
                  return $$String.compare(Curry._1(Selectable.value, x), Curry._1(Selectable.value, y));
                }));
  };
  var searchUnselected = function (searchInput, unselectedData) {
    var normalizedString = searchInput.trim().replace(new RegExp("\\s+", "g"), " ");
    if (normalizedString === "") {
      return unselectedData;
    } else {
      return search(normalizedString, unselectedData);
    }
  };
  var borderColor = function (colorForSelected) {
    return "border border-" + (colorForSelected + "-500");
  };
  var getBgColor = function (colorForSelected) {
    switch (colorForSelected) {
      case "blue" :
          return "bg-blue-200";
      case "focusColor" :
          return "bg-focusColor-200";
      case "gray" :
          return "bg-gray-200";
      case "green" :
          return "bg-green-200";
      case "orange" :
          return "bg-orange-200";
      case "primary" :
          return "bg-primary-200";
      case "red" :
          return "bg-red-200";
      case "yellow" :
          return "bg-yellow-200";
      default:
        return "bg-orange-200";
    }
  };
  var selectedItemClasses = function (colorForSelected) {
    return getBgColor(colorForSelected) + " " + borderColor(colorForSelected);
  };
  var searchVisible = function (unselected, value) {
    if (value !== "") {
      return true;
    } else {
      return unselected.length > 3;
    }
  };
  var MultiselectInline$Make = function (Props) {
    var id = Props.id;
    var placeholderOpt = Props.placeholder;
    var onChange = Props.onChange;
    var value = Props.value;
    var unselected = Props.unselected;
    var selected = Props.selected;
    var onSelect = Props.onSelect;
    var onDeselect = Props.onDeselect;
    var emptySelectionMessageOpt = Props.emptySelectionMessage;
    var allItemsSelectedMessageOpt = Props.allItemsSelectedMessage;
    var colorForSelectedOpt = Props.colorForSelected;
    var placeholder = placeholderOpt !== undefined ? placeholderOpt : "Search";
    var emptySelectionMessage = emptySelectionMessageOpt !== undefined ? emptySelectionMessageOpt : "No items selected";
    var allItemsSelectedMessage = allItemsSelectedMessageOpt !== undefined ? allItemsSelectedMessageOpt : "You have selected all items!";
    var colorForSelected = colorForSelectedOpt !== undefined ? colorForSelectedOpt : "orange";
    var match = React.useState(function () {
          if (id !== undefined) {
            return id;
          } else {
            return "re-multiselect-" + (Date.now().toString() + ("-" + String(Js_math.random_int(100000, 999999))));
          }
        });
    var searchResults = searchUnselected(value, unselected);
    var showSearchForm = searchVisible(unselected, value);
    return React.createElement("div", {
                className: "p-6 border rounded bg-gray-50"
              }, React.createElement("div", undefined, selected.length !== 0 ? $$Array.mapi((function (index, selected) {
                            return React.createElement("span", {
                                        key: String(index),
                                        className: "inline-flex items-center font-semibold text-xs mb-2 mr-2 rounded-full overflow-hidden " + selectedItemClasses(colorForSelected)
                                      }, React.createElement("span", {
                                            className: "px-2 py-1 flex-1"
                                          }, Curry._1(Selectable.value, selected)), React.createElement("button", {
                                            className: "inline-flex shrink-0 px-2 py-1 text-sm border-l-0 rounded-r items-center text-gray-800 hover:bg-gray-50 hover:text-red-500 focus:outline-none focus:bg-gray-50 focus:text-red-500 " + borderColor(colorForSelected),
                                            title: "Remove " + Curry._1(Selectable.value, selected),
                                            onClick: (function ($$event) {
                                                $$event.preventDefault();
                                                return Curry._1(onDeselect, selected);
                                              })
                                          }, React.createElement(PfIcon.make, {
                                                className: "if i-times-regular"
                                              })));
                          }), selected) : React.createElement("div", {
                          className: "flex flex-col items-center justify-center bg-gray-50 text-gray-800 rounded px-3 pt-3 "
                        }, React.createElement("i", {
                              className: "fas fa-inbox text-3xl"
                            }), React.createElement("h5", {
                              className: "mt-1 font-semibold"
                            }, emptySelectionMessage)), React.createElement("div", {
                        className: "text-sm font-medium border-t pt-2 mt-2"
                      }, unselected.length !== 0 ? "Add more from the list below:" : allItemsSelectedMessage)), unselected.length !== 0 ? React.createElement("div", {
                      className: "flex relative pt-3"
                    }, React.createElement("div", {
                          className: "text-sm bg-white rounded shadow w-full" + (
                            showSearchForm ? " pb-2" : ""
                          )
                        }, showSearchForm ? React.createElement("div", {
                                className: "px-3 pt-3 pb-2"
                              }, React.createElement("input", {
                                    className: "appearance-none bg-transparent border-b w-full text-gray-600 pb-3 px-2 pl-0 leading-normal focus:outline-none",
                                    id: match[0],
                                    placeholder: placeholder,
                                    type: "text",
                                    value: value,
                                    onChange: (function ($$event) {
                                        return Curry._1(onChange, $$event.target.value);
                                      })
                                  })) : null, React.createElement("div", {
                              className: showSearchForm ? "multiselect-inline__list overflow-y-scroll" : ""
                            }, $$Array.mapi((function (index, item) {
                                    return React.createElement("button", {
                                                key: String(index),
                                                "aria-label": "Select " + Curry._1(Selectable.value, item),
                                                className: "flex w-full multiselect-inline__list-item  items-center px-3 py-2 font-medium hover:bg-primary-100 hover:text-primary-500 focus:outline-none focus:bg-primary-100 focus:text-primary-500 cursor-pointer",
                                                title: "Select " + Curry._1(Selectable.value, item),
                                                onClick: (function (_event) {
                                                    _event.preventDefault();
                                                    return Curry._1(onSelect, item);
                                                  })
                                              }, React.createElement("i", {
                                                    className: "far fa-square multiselect-inline__list-item-select-icon-unselected text-gray-400 text-xl"
                                                  }), React.createElement("i", {
                                                    className: "far fa-plus-square multiselect-inline__list-item-select-icon-selected text-xl"
                                                  }), React.createElement("span", {
                                                    className: "ml-2"
                                                  }, Curry._1(Selectable.value, item)));
                                  }), searchResults)))) : null);
  };
  return {
          search: search,
          searchUnselected: searchUnselected,
          borderColor: borderColor,
          getBgColor: getBgColor,
          selectedItemClasses: selectedItemClasses,
          searchVisible: searchVisible,
          make: MultiselectInline$Make
        };
}

export {
  str ,
  Make ,
  
}
/*  Not a pure module */
