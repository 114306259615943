// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Icon from "../../../shared/Icon.bs.js";
import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Avatar from "../../../shared/Avatar.bs.js";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as PfIcon from "../../../packages/pf-icon/src/PfIcon.bs.js";
import * as Spread from "../../../shared/components/Spread.bs.js";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Caml_obj from "../../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as DomUtils from "../../../shared/utils/DomUtils.bs.js";
import * as HelpIcon from "../../../shared/components/HelpIcon.bs.js";
import * as UserProxy from "../../../shared/types/UserProxy.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Caml_array from "../../../../../node_modules/rescript/lib/es6/caml_array.js";
import * as GradeLabel from "../../../shared/types/GradeLabel.bs.js";
import * as LevelLabel from "../../../shared/utils/LevelLabel.bs.js";
import * as ReactUtils from "../../../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as WindowUtils from "../../../shared/utils/WindowUtils.bs.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as $$Notification from "../../../shared/Notification.bs.js";
import * as ReactHelmet from "react-helmet";
import * as MarkdownBlock from "../../../shared/components/MarkdownBlock.bs.js";
import * as DisablingCover from "../../../shared/components/DisablingCover.bs.js";
import * as MarkdownEditor from "../../../shared/components/MarkdownEditor.bs.js";
import * as AppRouter__User from "../../../layouts/app_router/types/AppRouter__User.bs.js";
import * as EvaluationCriterion from "../../../shared/types/EvaluationCriterion.bs.js";
import * as RescriptReactRouter from "../../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as CoursesReview__Grade from "../types/CoursesReview__Grade.bs.js";
import * as CoursesReview__Filter from "../types/CoursesReview__Filter.bs.js";
import * as CoursesReview__Student from "../types/CoursesReview__Student.bs.js";
import * as CoursesReview__Feedback from "../types/CoursesReview__Feedback.bs.js";
import * as CoursesReview__Reviewer from "../types/CoursesReview__Reviewer.bs.js";
import * as SubmissionChecklistItem from "../types/SubmissionChecklistItem.bs.js";
import * as SubmissionChecklistShow from "./SubmissionChecklistShow.bs.js";
import * as CoursesReview__Checklist from "./CoursesReview__Checklist.bs.js";
import * as CoursesReview__SubmissionMeta from "../types/CoursesReview__SubmissionMeta.bs.js";
import * as CoursesReview__ReviewerManager from "./CoursesReview__ReviewerManager.bs.js";
import * as CoursesReview__SubmissionReport from "../types/CoursesReview__SubmissionReport.bs.js";
import * as CoursesReview__OverlaySubmission from "../types/CoursesReview__OverlaySubmission.bs.js";
import * as CoursesReview__SubmissionDetails from "../types/CoursesReview__SubmissionDetails.bs.js";
import * as CoursesStudents__PersonalCoaches from "../../students/components/CoursesStudents__PersonalCoaches.bs.js";
import * as CoursesReview__SubmissionInfoCard from "./CoursesReview__SubmissionInfoCard.bs.js";

import "./CoursesReview__Editor.css"
;

var partial_arg = "components.CoursesReview__Editor";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function str(prim) {
  return prim;
}

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* BeginSaving */0 :
          return {
                  grades: state.grades,
                  newFeedback: state.newFeedback,
                  saving: true,
                  showReport: state.showReport,
                  checklist: state.checklist,
                  note: state.note,
                  editor: state.editor,
                  additonalFeedbackEditorVisible: state.additonalFeedbackEditorVisible,
                  feedbackGenerated: state.feedbackGenerated,
                  nextSubmission: state.nextSubmission,
                  reloadSubmissionReport: state.reloadSubmissionReport
                };
      case /* FinishSaving */1 :
          return {
                  grades: state.grades,
                  newFeedback: state.newFeedback,
                  saving: false,
                  showReport: state.showReport,
                  checklist: state.checklist,
                  note: state.note,
                  editor: state.editor,
                  additonalFeedbackEditorVisible: state.additonalFeedbackEditorVisible,
                  feedbackGenerated: state.feedbackGenerated,
                  nextSubmission: state.nextSubmission,
                  reloadSubmissionReport: state.reloadSubmissionReport
                };
      case /* ShowGradesEditor */2 :
          return {
                  grades: state.grades,
                  newFeedback: state.newFeedback,
                  saving: state.saving,
                  showReport: state.showReport,
                  checklist: state.checklist,
                  note: state.note,
                  editor: /* GradesEditor */1,
                  additonalFeedbackEditorVisible: state.additonalFeedbackEditorVisible,
                  feedbackGenerated: state.feedbackGenerated,
                  nextSubmission: state.nextSubmission,
                  reloadSubmissionReport: state.reloadSubmissionReport
                };
      case /* ShowChecklistEditor */3 :
          return {
                  grades: state.grades,
                  newFeedback: state.newFeedback,
                  saving: state.saving,
                  showReport: state.showReport,
                  checklist: state.checklist,
                  note: state.note,
                  editor: /* ChecklistEditor */2,
                  additonalFeedbackEditorVisible: state.additonalFeedbackEditorVisible,
                  feedbackGenerated: state.feedbackGenerated,
                  nextSubmission: state.nextSubmission,
                  reloadSubmissionReport: state.reloadSubmissionReport
                };
      case /* ShowAdditionalFeedbackEditor */4 :
          return {
                  grades: state.grades,
                  newFeedback: state.newFeedback,
                  saving: state.saving,
                  showReport: state.showReport,
                  checklist: state.checklist,
                  note: state.note,
                  editor: state.editor,
                  additonalFeedbackEditorVisible: true,
                  feedbackGenerated: state.feedbackGenerated,
                  nextSubmission: state.nextSubmission,
                  reloadSubmissionReport: state.reloadSubmissionReport
                };
      case /* FeedbackAfterSave */5 :
          return {
                  grades: state.grades,
                  newFeedback: "",
                  saving: false,
                  showReport: state.showReport,
                  checklist: state.checklist,
                  note: state.note,
                  editor: state.editor,
                  additonalFeedbackEditorVisible: false,
                  feedbackGenerated: state.feedbackGenerated,
                  nextSubmission: state.nextSubmission,
                  reloadSubmissionReport: state.reloadSubmissionReport
                };
      case /* SetNextSubmissionDataLoading */6 :
          return {
                  grades: state.grades,
                  newFeedback: state.newFeedback,
                  saving: state.saving,
                  showReport: state.showReport,
                  checklist: state.checklist,
                  note: state.note,
                  editor: state.editor,
                  additonalFeedbackEditorVisible: state.additonalFeedbackEditorVisible,
                  feedbackGenerated: state.feedbackGenerated,
                  nextSubmission: /* DataLoading */1,
                  reloadSubmissionReport: state.reloadSubmissionReport
                };
      case /* SetNextSubmissionDataEmpty */7 :
          return {
                  grades: state.grades,
                  newFeedback: state.newFeedback,
                  saving: state.saving,
                  showReport: state.showReport,
                  checklist: state.checklist,
                  note: state.note,
                  editor: state.editor,
                  additonalFeedbackEditorVisible: state.additonalFeedbackEditorVisible,
                  feedbackGenerated: state.feedbackGenerated,
                  nextSubmission: /* DataEmpty */2,
                  reloadSubmissionReport: state.reloadSubmissionReport
                };
      case /* UnassignReviewer */8 :
          return {
                  grades: state.grades,
                  newFeedback: state.newFeedback,
                  saving: false,
                  showReport: state.showReport,
                  checklist: state.checklist,
                  note: state.note,
                  editor: /* AssignReviewer */0,
                  additonalFeedbackEditorVisible: state.additonalFeedbackEditorVisible,
                  feedbackGenerated: state.feedbackGenerated,
                  nextSubmission: state.nextSubmission,
                  reloadSubmissionReport: state.reloadSubmissionReport
                };
      case /* ChangeReportVisibility */9 :
          return {
                  grades: state.grades,
                  newFeedback: state.newFeedback,
                  saving: state.saving,
                  showReport: !state.showReport,
                  checklist: state.checklist,
                  note: state.note,
                  editor: state.editor,
                  additonalFeedbackEditorVisible: state.additonalFeedbackEditorVisible,
                  feedbackGenerated: state.feedbackGenerated,
                  nextSubmission: state.nextSubmission,
                  reloadSubmissionReport: state.reloadSubmissionReport
                };
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* UpdateFeedback */0 :
          return {
                  grades: state.grades,
                  newFeedback: action._0,
                  saving: state.saving,
                  showReport: state.showReport,
                  checklist: state.checklist,
                  note: state.note,
                  editor: state.editor,
                  additonalFeedbackEditorVisible: state.additonalFeedbackEditorVisible,
                  feedbackGenerated: state.feedbackGenerated,
                  nextSubmission: state.nextSubmission,
                  reloadSubmissionReport: state.reloadSubmissionReport
                };
      case /* GenerateFeeback */1 :
          return {
                  grades: state.grades,
                  newFeedback: action._0,
                  saving: state.saving,
                  showReport: state.showReport,
                  checklist: state.checklist,
                  note: state.note,
                  editor: action._1,
                  additonalFeedbackEditorVisible: state.additonalFeedbackEditorVisible,
                  feedbackGenerated: true,
                  nextSubmission: state.nextSubmission,
                  reloadSubmissionReport: state.reloadSubmissionReport
                };
      case /* UpdateGrades */2 :
          return {
                  grades: action._0,
                  newFeedback: state.newFeedback,
                  saving: state.saving,
                  showReport: state.showReport,
                  checklist: state.checklist,
                  note: state.note,
                  editor: state.editor,
                  additonalFeedbackEditorVisible: state.additonalFeedbackEditorVisible,
                  feedbackGenerated: state.feedbackGenerated,
                  nextSubmission: state.nextSubmission,
                  reloadSubmissionReport: state.reloadSubmissionReport
                };
      case /* UpdateChecklist */3 :
          return {
                  grades: state.grades,
                  newFeedback: state.newFeedback,
                  saving: state.saving,
                  showReport: state.showReport,
                  checklist: action._0,
                  note: state.note,
                  editor: state.editor,
                  additonalFeedbackEditorVisible: state.additonalFeedbackEditorVisible,
                  feedbackGenerated: state.feedbackGenerated,
                  nextSubmission: state.nextSubmission,
                  reloadSubmissionReport: state.reloadSubmissionReport
                };
      case /* UpdateNote */4 :
          return {
                  grades: state.grades,
                  newFeedback: state.newFeedback,
                  saving: state.saving,
                  showReport: state.showReport,
                  checklist: state.checklist,
                  note: action._0,
                  editor: state.editor,
                  additonalFeedbackEditorVisible: state.additonalFeedbackEditorVisible,
                  feedbackGenerated: state.feedbackGenerated,
                  nextSubmission: state.nextSubmission,
                  reloadSubmissionReport: state.reloadSubmissionReport
                };
      case /* UpdateEditor */5 :
          return {
                  grades: state.grades,
                  newFeedback: state.newFeedback,
                  saving: state.saving,
                  showReport: state.showReport,
                  checklist: state.checklist,
                  note: state.note,
                  editor: action._0,
                  additonalFeedbackEditorVisible: state.additonalFeedbackEditorVisible,
                  feedbackGenerated: state.feedbackGenerated,
                  nextSubmission: state.nextSubmission,
                  reloadSubmissionReport: state.reloadSubmissionReport
                };
      case /* FinishGrading */6 :
          return {
                  grades: state.grades,
                  newFeedback: "",
                  saving: false,
                  showReport: state.showReport,
                  checklist: state.checklist,
                  note: undefined,
                  editor: /* ReviewedSubmissionEditor */{
                    _0: action._0
                  },
                  additonalFeedbackEditorVisible: state.additonalFeedbackEditorVisible,
                  feedbackGenerated: state.feedbackGenerated,
                  nextSubmission: state.nextSubmission,
                  reloadSubmissionReport: state.reloadSubmissionReport
                };
      
    }
  }
}

var Raw = {};

var query = "mutation CreateGradingMutation($submissionId: ID!, $feedback: String, $grades: [GradeInput!]!, $note: String, $checklist: JSON!)  {\ncreateGrading(submissionId: $submissionId, feedback: $feedback, grades: $grades, note: $note, checklist: $checklist)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.createGrading;
  return {
          createGrading: {
            success: value$1.success
          }
        };
}

function serialize(value) {
  var value$1 = value.createGrading;
  var value$2 = value$1.success;
  var createGrading = {
    success: value$2
  };
  return {
          createGrading: createGrading
        };
}

function serializeInputObjectGradeInput(inp) {
  return {
          evaluationCriterionId: inp.evaluationCriterionId,
          grade: inp.grade
        };
}

function serializeVariables(inp) {
  var a = inp.feedback;
  var a$1 = inp.grades;
  var a$2 = inp.note;
  return {
          submissionId: inp.submissionId,
          feedback: a !== undefined ? a : undefined,
          grades: a$1.map(serializeInputObjectGradeInput),
          note: a$2 !== undefined ? a$2 : undefined,
          checklist: inp.checklist
        };
}

function makeVariables(submissionId, feedback, grades, note, checklist, param) {
  return {
          submissionId: submissionId,
          feedback: feedback,
          grades: grades,
          note: note,
          checklist: checklist
        };
}

function makeInputObjectGradeInput(evaluationCriterionId, grade, param) {
  return {
          evaluationCriterionId: evaluationCriterionId,
          grade: grade
        };
}

var CreateGradingMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectGradeInput: serializeInputObjectGradeInput,
  makeVariables: makeVariables,
  makeInputObjectGradeInput: makeInputObjectGradeInput
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include.$$fetch;

var CreateGradingMutation_Graphql_error = include.Graphql_error;

var CreateGradingMutation_decodeNotification = include.decodeNotification;

var CreateGradingMutation_decodeObject = include.decodeObject;

var CreateGradingMutation_decodeNotifications = include.decodeNotifications;

var CreateGradingMutation_decodeErrors = include.decodeErrors;

var CreateGradingMutation_flashNotifications = include.flashNotifications;

var CreateGradingMutation_sendQuery = include.sendQuery;

var CreateGradingMutation_query = include.query;

var CreateGradingMutation_make = include.make;

var CreateGradingMutation = {
  CreateGradingMutation_inner: CreateGradingMutation_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectGradeInput: serializeInputObjectGradeInput,
  makeVariables: makeVariables,
  makeInputObjectGradeInput: makeInputObjectGradeInput,
  Graphql_error: CreateGradingMutation_Graphql_error,
  decodeNotification: CreateGradingMutation_decodeNotification,
  decodeObject: CreateGradingMutation_decodeObject,
  decodeNotifications: CreateGradingMutation_decodeNotifications,
  decodeErrors: CreateGradingMutation_decodeErrors,
  flashNotifications: CreateGradingMutation_flashNotifications,
  sendQuery: CreateGradingMutation_sendQuery,
  query: CreateGradingMutation_query,
  $$fetch: $$fetch,
  make: CreateGradingMutation_make
};

var Raw$1 = {};

var query$1 = "mutation UndoGradingMutation($submissionId: ID!)  {\nundoGrading(submissionId: $submissionId)  {\nsuccess  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value.undoGrading;
  return {
          undoGrading: {
            success: value$1.success
          }
        };
}

function serialize$1(value) {
  var value$1 = value.undoGrading;
  var value$2 = value$1.success;
  var undoGrading = {
    success: value$2
  };
  return {
          undoGrading: undoGrading
        };
}

function serializeVariables$1(inp) {
  return {
          submissionId: inp.submissionId
        };
}

function makeVariables$1(submissionId, param) {
  return {
          submissionId: submissionId
        };
}

var UndoGradingMutation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = GraphqlQuery.Extender({
      Raw: Raw$1,
      query: query$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch$1 = include$1.$$fetch;

var UndoGradingMutation_Graphql_error = include$1.Graphql_error;

var UndoGradingMutation_decodeNotification = include$1.decodeNotification;

var UndoGradingMutation_decodeObject = include$1.decodeObject;

var UndoGradingMutation_decodeNotifications = include$1.decodeNotifications;

var UndoGradingMutation_decodeErrors = include$1.decodeErrors;

var UndoGradingMutation_flashNotifications = include$1.flashNotifications;

var UndoGradingMutation_sendQuery = include$1.sendQuery;

var UndoGradingMutation_query = include$1.query;

var UndoGradingMutation_make = include$1.make;

var UndoGradingMutation = {
  UndoGradingMutation_inner: UndoGradingMutation_inner,
  Raw: Raw$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  Graphql_error: UndoGradingMutation_Graphql_error,
  decodeNotification: UndoGradingMutation_decodeNotification,
  decodeObject: UndoGradingMutation_decodeObject,
  decodeNotifications: UndoGradingMutation_decodeNotifications,
  decodeErrors: UndoGradingMutation_decodeErrors,
  flashNotifications: UndoGradingMutation_flashNotifications,
  sendQuery: UndoGradingMutation_sendQuery,
  query: UndoGradingMutation_query,
  $$fetch: $$fetch$1,
  make: UndoGradingMutation_make
};

var Raw$2 = {};

var query$2 = "mutation CreateFeedbackMutation($submissionId: ID!, $feedback: String!)  {\ncreateFeedback(submissionId: $submissionId, feedback: $feedback)  {\nsuccess  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = value.createFeedback;
  return {
          createFeedback: {
            success: value$1.success
          }
        };
}

function serialize$2(value) {
  var value$1 = value.createFeedback;
  var value$2 = value$1.success;
  var createFeedback = {
    success: value$2
  };
  return {
          createFeedback: createFeedback
        };
}

function serializeVariables$2(inp) {
  return {
          submissionId: inp.submissionId,
          feedback: inp.feedback
        };
}

function makeVariables$2(submissionId, feedback, param) {
  return {
          submissionId: submissionId,
          feedback: feedback
        };
}

var CreateFeedbackMutation_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2
};

var include$2 = GraphqlQuery.Extender({
      Raw: Raw$2,
      query: query$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include$2.make;

var CreateFeedbackMutation_Graphql_error = include$2.Graphql_error;

var CreateFeedbackMutation_decodeNotification = include$2.decodeNotification;

var CreateFeedbackMutation_decodeObject = include$2.decodeObject;

var CreateFeedbackMutation_decodeNotifications = include$2.decodeNotifications;

var CreateFeedbackMutation_decodeErrors = include$2.decodeErrors;

var CreateFeedbackMutation_flashNotifications = include$2.flashNotifications;

var CreateFeedbackMutation_sendQuery = include$2.sendQuery;

var CreateFeedbackMutation_query = include$2.query;

var CreateFeedbackMutation_fetch = include$2.$$fetch;

var CreateFeedbackMutation = {
  CreateFeedbackMutation_inner: CreateFeedbackMutation_inner,
  Raw: Raw$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2,
  Graphql_error: CreateFeedbackMutation_Graphql_error,
  decodeNotification: CreateFeedbackMutation_decodeNotification,
  decodeObject: CreateFeedbackMutation_decodeObject,
  decodeNotifications: CreateFeedbackMutation_decodeNotifications,
  decodeErrors: CreateFeedbackMutation_decodeErrors,
  flashNotifications: CreateFeedbackMutation_flashNotifications,
  sendQuery: CreateFeedbackMutation_sendQuery,
  query: CreateFeedbackMutation_query,
  $$fetch: CreateFeedbackMutation_fetch,
  make: make
};

var Raw$3 = {};

var query$3 = "query NextSubmissionQuery($courseId: ID!, $search: String, $targetId: ID, $status: SubmissionStatus, $sortDirection: SortDirection!, $sortCriterion: SubmissionSortCriterion!, $levelId: ID, $personalCoachId: ID, $assignedCoachId: ID, $excludeSubmissionId: ID, $after: String)  {\nsubmissions(courseId: $courseId, search: $search, targetId: $targetId, status: $status, sortDirection: $sortDirection, excludeSubmissionId: $excludeSubmissionId, sortCriterion: $sortCriterion, levelId: $levelId, personalCoachId: $personalCoachId, assignedCoachId: $assignedCoachId, first: 1, after: $after)  {\nnodes  {\nid  \n}\n\n}\n\n}\n";

function parse$3(value) {
  var value$1 = value.submissions;
  var value$2 = value$1.nodes;
  return {
          submissions: {
            nodes: value$2.map(function (value) {
                  return {
                          id: value.id
                        };
                })
          }
        };
}

function serialize$3(value) {
  var value$1 = value.submissions;
  var value$2 = value$1.nodes;
  var nodes = value$2.map(function (value) {
        var value$1 = value.id;
        return {
                id: value$1
              };
      });
  var submissions = {
    nodes: nodes
  };
  return {
          submissions: submissions
        };
}

function serializeVariables$3(inp) {
  var a = inp.search;
  var a$1 = inp.targetId;
  var a$2 = inp.status;
  var a$3 = inp.sortDirection;
  var a$4 = inp.sortCriterion;
  var a$5 = inp.levelId;
  var a$6 = inp.personalCoachId;
  var a$7 = inp.assignedCoachId;
  var a$8 = inp.excludeSubmissionId;
  var a$9 = inp.after;
  return {
          courseId: inp.courseId,
          search: a !== undefined ? a : undefined,
          targetId: a$1 !== undefined ? a$1 : undefined,
          status: a$2 !== undefined ? (
              a$2 === "Reviewed" ? "Reviewed" : "Pending"
            ) : undefined,
          sortDirection: a$3 === "Descending" ? "Descending" : "Ascending",
          sortCriterion: a$4 === "EvaluatedAt" ? "EvaluatedAt" : "SubmittedAt",
          levelId: a$5 !== undefined ? a$5 : undefined,
          personalCoachId: a$6 !== undefined ? a$6 : undefined,
          assignedCoachId: a$7 !== undefined ? a$7 : undefined,
          excludeSubmissionId: a$8 !== undefined ? a$8 : undefined,
          after: a$9 !== undefined ? a$9 : undefined
        };
}

function makeVariables$3(courseId, search, targetId, status, sortDirection, sortCriterion, levelId, personalCoachId, assignedCoachId, excludeSubmissionId, after, param) {
  return {
          courseId: courseId,
          search: search,
          targetId: targetId,
          status: status,
          sortDirection: sortDirection,
          sortCriterion: sortCriterion,
          levelId: levelId,
          personalCoachId: personalCoachId,
          assignedCoachId: assignedCoachId,
          excludeSubmissionId: excludeSubmissionId,
          after: after
        };
}

var NextSubmissionQuery_inner = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3
};

var include$3 = GraphqlQuery.Extender({
      Raw: Raw$3,
      query: query$3,
      parse: parse$3,
      serialize: serialize$3,
      serializeVariables: serializeVariables$3,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make$1 = include$3.make;

var NextSubmissionQuery_Graphql_error = include$3.Graphql_error;

var NextSubmissionQuery_decodeNotification = include$3.decodeNotification;

var NextSubmissionQuery_decodeObject = include$3.decodeObject;

var NextSubmissionQuery_decodeNotifications = include$3.decodeNotifications;

var NextSubmissionQuery_decodeErrors = include$3.decodeErrors;

var NextSubmissionQuery_flashNotifications = include$3.flashNotifications;

var NextSubmissionQuery_sendQuery = include$3.sendQuery;

var NextSubmissionQuery_query = include$3.query;

var NextSubmissionQuery_fetch = include$3.$$fetch;

var NextSubmissionQuery = {
  NextSubmissionQuery_inner: NextSubmissionQuery_inner,
  Raw: Raw$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3,
  Graphql_error: NextSubmissionQuery_Graphql_error,
  decodeNotification: NextSubmissionQuery_decodeNotification,
  decodeObject: NextSubmissionQuery_decodeObject,
  decodeNotifications: NextSubmissionQuery_decodeNotifications,
  decodeErrors: NextSubmissionQuery_decodeErrors,
  flashNotifications: NextSubmissionQuery_flashNotifications,
  sendQuery: NextSubmissionQuery_sendQuery,
  query: NextSubmissionQuery_query,
  $$fetch: NextSubmissionQuery_fetch,
  make: make$1
};

var Raw$4 = {};

var query$4 = "mutation UnassignReviewerMutation($submissionId: ID!)  {\nunassignReviewer(submissionId: $submissionId)  {\nsuccess  \n}\n\n}\n";

function parse$4(value) {
  var value$1 = value.unassignReviewer;
  return {
          unassignReviewer: {
            success: value$1.success
          }
        };
}

function serialize$4(value) {
  var value$1 = value.unassignReviewer;
  var value$2 = value$1.success;
  var unassignReviewer = {
    success: value$2
  };
  return {
          unassignReviewer: unassignReviewer
        };
}

function serializeVariables$4(inp) {
  return {
          submissionId: inp.submissionId
        };
}

function makeVariables$4(submissionId, param) {
  return {
          submissionId: submissionId
        };
}

var UnassignReviewerMutation_inner = {
  Raw: Raw$4,
  query: query$4,
  parse: parse$4,
  serialize: serialize$4,
  serializeVariables: serializeVariables$4,
  makeVariables: makeVariables$4
};

var include$4 = GraphqlQuery.Extender({
      Raw: Raw$4,
      query: query$4,
      parse: parse$4,
      serialize: serialize$4,
      serializeVariables: serializeVariables$4,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch$2 = include$4.$$fetch;

var UnassignReviewerMutation_Graphql_error = include$4.Graphql_error;

var UnassignReviewerMutation_decodeNotification = include$4.decodeNotification;

var UnassignReviewerMutation_decodeObject = include$4.decodeObject;

var UnassignReviewerMutation_decodeNotifications = include$4.decodeNotifications;

var UnassignReviewerMutation_decodeErrors = include$4.decodeErrors;

var UnassignReviewerMutation_flashNotifications = include$4.flashNotifications;

var UnassignReviewerMutation_sendQuery = include$4.sendQuery;

var UnassignReviewerMutation_query = include$4.query;

var UnassignReviewerMutation_make = include$4.make;

var UnassignReviewerMutation = {
  UnassignReviewerMutation_inner: UnassignReviewerMutation_inner,
  Raw: Raw$4,
  parse: parse$4,
  serialize: serialize$4,
  serializeVariables: serializeVariables$4,
  makeVariables: makeVariables$4,
  Graphql_error: UnassignReviewerMutation_Graphql_error,
  decodeNotification: UnassignReviewerMutation_decodeNotification,
  decodeObject: UnassignReviewerMutation_decodeObject,
  decodeNotifications: UnassignReviewerMutation_decodeNotifications,
  decodeErrors: UnassignReviewerMutation_decodeErrors,
  flashNotifications: UnassignReviewerMutation_flashNotifications,
  sendQuery: UnassignReviewerMutation_sendQuery,
  query: UnassignReviewerMutation_query,
  $$fetch: $$fetch$2,
  make: UnassignReviewerMutation_make
};

var Raw$5 = {};

var query$5 = "query SubmissionReportQuery($id: ID!)  {\nsubmissionReport(id: $id)  {\nid  \nstatus  \ntestReport  \nstartedAt  \ncompletedAt  \nconclusion  \nqueuedAt  \n}\n\n}\n";

function parse$5(value) {
  var value$1 = value.submissionReport;
  var value$2 = value$1.status;
  var tmp;
  switch (value$2) {
    case "completed" :
        tmp = "completed";
        break;
    case "in_progress" :
        tmp = "in_progress";
        break;
    case "queued" :
        tmp = "queued";
        break;
    default:
      tmp = {
        NAME: "FutureAddedValue",
        VAL: value$2
      };
  }
  var value$3 = value$1.testReport;
  var value$4 = value$1.startedAt;
  var value$5 = value$1.completedAt;
  var value$6 = value$1.conclusion;
  var tmp$1;
  if (value$6 == null) {
    tmp$1 = undefined;
  } else {
    var tmp$2;
    switch (value$6) {
      case "error" :
          tmp$2 = "error";
          break;
      case "failure" :
          tmp$2 = "failure";
          break;
      case "success" :
          tmp$2 = "success";
          break;
      default:
        tmp$2 = {
          NAME: "FutureAddedValue",
          VAL: value$6
        };
    }
    tmp$1 = tmp$2;
  }
  return {
          submissionReport: {
            id: value$1.id,
            status: tmp,
            testReport: !(value$3 == null) ? value$3 : undefined,
            startedAt: !(value$4 == null) ? Caml_option.some(value$4) : undefined,
            completedAt: !(value$5 == null) ? Caml_option.some(value$5) : undefined,
            conclusion: tmp$1,
            queuedAt: value$1.queuedAt
          }
        };
}

function serialize$5(value) {
  var value$1 = value.submissionReport;
  var value$2 = value$1.queuedAt;
  var value$3 = value$1.conclusion;
  var conclusion = value$3 !== undefined ? (
      typeof value$3 === "object" ? value$3.VAL : (
          value$3 === "failure" ? "failure" : (
              value$3 === "success" ? "success" : "error"
            )
        )
    ) : null;
  var value$4 = value$1.completedAt;
  var completedAt = value$4 !== undefined ? Caml_option.valFromOption(value$4) : null;
  var value$5 = value$1.startedAt;
  var startedAt = value$5 !== undefined ? Caml_option.valFromOption(value$5) : null;
  var value$6 = value$1.testReport;
  var testReport = value$6 !== undefined ? value$6 : null;
  var value$7 = value$1.status;
  var status = typeof value$7 === "object" ? value$7.VAL : (
      value$7 === "completed" ? "completed" : (
          value$7 === "queued" ? "queued" : "in_progress"
        )
    );
  var value$8 = value$1.id;
  var submissionReport = {
    id: value$8,
    status: status,
    testReport: testReport,
    startedAt: startedAt,
    completedAt: completedAt,
    conclusion: conclusion,
    queuedAt: value$2
  };
  return {
          submissionReport: submissionReport
        };
}

function serializeVariables$5(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$5(id, param) {
  return {
          id: id
        };
}

var SubmissionReportQuery_inner = {
  Raw: Raw$5,
  query: query$5,
  parse: parse$5,
  serialize: serialize$5,
  serializeVariables: serializeVariables$5,
  makeVariables: makeVariables$5
};

var include$5 = GraphqlQuery.Extender({
      Raw: Raw$5,
      query: query$5,
      parse: parse$5,
      serialize: serialize$5,
      serializeVariables: serializeVariables$5,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make$2 = include$5.make;

var SubmissionReportQuery_Graphql_error = include$5.Graphql_error;

var SubmissionReportQuery_decodeNotification = include$5.decodeNotification;

var SubmissionReportQuery_decodeObject = include$5.decodeObject;

var SubmissionReportQuery_decodeNotifications = include$5.decodeNotifications;

var SubmissionReportQuery_decodeErrors = include$5.decodeErrors;

var SubmissionReportQuery_flashNotifications = include$5.flashNotifications;

var SubmissionReportQuery_sendQuery = include$5.sendQuery;

var SubmissionReportQuery_query = include$5.query;

var SubmissionReportQuery_fetch = include$5.$$fetch;

var SubmissionReportQuery = {
  SubmissionReportQuery_inner: SubmissionReportQuery_inner,
  Raw: Raw$5,
  parse: parse$5,
  serialize: serialize$5,
  serializeVariables: serializeVariables$5,
  makeVariables: makeVariables$5,
  Graphql_error: SubmissionReportQuery_Graphql_error,
  decodeNotification: SubmissionReportQuery_decodeNotification,
  decodeObject: SubmissionReportQuery_decodeObject,
  decodeNotifications: SubmissionReportQuery_decodeNotifications,
  decodeErrors: SubmissionReportQuery_decodeErrors,
  flashNotifications: SubmissionReportQuery_flashNotifications,
  sendQuery: SubmissionReportQuery_sendQuery,
  query: SubmissionReportQuery_query,
  $$fetch: SubmissionReportQuery_fetch,
  make: make$2
};

function unassignReviewer(submissionId, send, updateReviewerCB) {
  Curry._1(send, /* BeginSaving */0);
  Curry._3($$fetch$2, undefined, undefined, {
            submissionId: submissionId
          }).then(function (response) {
          if (response.unassignReviewer.success) {
            Curry._1(updateReviewerCB, undefined);
            Curry._1(send, /* UnassignReviewer */8);
          }
          Curry._1(send, /* FinishSaving */1);
          return Promise.resolve(undefined);
        }).catch(function (param) {
        Curry._1(send, /* FinishSaving */1);
        return Promise.resolve(undefined);
      });
  
}

function getNextSubmission(send, courseId, filter, submissionId) {
  Curry._1(send, /* SetNextSubmissionDataLoading */6);
  var variables = makeVariables$3(courseId, CoursesReview__Filter.nameOrEmail(filter), CoursesReview__Filter.targetId(filter), CoursesReview__Filter.tab(filter), CoursesReview__Filter.defaultDirection(filter), CoursesReview__Filter.sortCriterion(filter), CoursesReview__Filter.levelId(filter), CoursesReview__Filter.personalCoachId(filter), CoursesReview__Filter.assignedCoachId(filter), submissionId, undefined, undefined);
  Curry._3(make$1, undefined, undefined, variables).then(function (response) {
        if (ArrayUtils.isEmpty(response.submissions.nodes)) {
          Curry._1(send, /* SetNextSubmissionDataEmpty */7);
          $$Notification.notice(t(undefined, undefined, "get_next_submission.notice.done"), t(undefined, undefined, "get_next_submission.notice.done_description"));
        } else {
          RescriptReactRouter.push("/submissions/" + Caml_array.get(response.submissions.nodes, 0).id + "/review?" + CoursesReview__Filter.toQueryString(filter));
        }
        return Promise.resolve(undefined);
      });
  
}

function isSubmissionReviewAllowed(submissionDetails) {
  var daysSinceSubmission = DateFns.differenceInDays(new Date(), CoursesReview__SubmissionDetails.createdAt(submissionDetails));
  var submissionReviewAllowedTime = CoursesReview__SubmissionDetails.inactiveSubmissionReviewAllowedDays(submissionDetails);
  var submissionReviewAllowed = daysSinceSubmission < submissionReviewAllowedTime;
  if (CoursesReview__SubmissionDetails.preview(submissionDetails)) {
    return !submissionReviewAllowed;
  } else {
    return false;
  }
}

function makeFeedback(user, feedback) {
  return CoursesReview__Feedback.make(AppRouter__User.name(user), AppRouter__User.avatarUrl(user), AppRouter__User.title(user), new Date(), feedback);
}

function createFeedback(submissionId, feedback, send, overlaySubmission, user, updateSubmissionCB) {
  Curry._1(send, /* BeginSaving */0);
  Curry._3(make, undefined, undefined, {
          submissionId: submissionId,
          feedback: feedback
        }).then(function (response) {
        if (response.createFeedback.success) {
          Curry._1(updateSubmissionCB, CoursesReview__OverlaySubmission.updateFeedback(CoursesReview__OverlaySubmission.feedback(overlaySubmission).concat([makeFeedback(user, feedback)]), overlaySubmission));
          Curry._1(send, /* FeedbackAfterSave */5);
        } else {
          Curry._1(send, /* FinishSaving */1);
        }
        return Promise.resolve(undefined);
      });
  
}

function undoGrading(submissionId, send) {
  Curry._1(send, /* BeginSaving */0);
  Curry._3($$fetch$1, undefined, undefined, {
          submissionId: submissionId
        }).then(function (response) {
        if (response.undoGrading.success) {
          DomUtils.reload(undefined);
        } else {
          Curry._1(send, /* FinishSaving */1);
        }
        return Promise.resolve(undefined);
      });
  
}

function passed(grades, evaluationCriteria) {
  return ArrayUtils.isEmpty(grades.filter(function (g) {
                  var passGrade = EvaluationCriterion.passGrade(ArrayUtils.unsafeFind((function (ec) {
                              return EvaluationCriterion.id(ec) === CoursesReview__Grade.evaluationCriterionId(g);
                            }), "CoursesReview__Editor: Unable to find evaluation criterion with id - " + CoursesReview__Grade.evaluationCriterionId(g), evaluationCriteria));
                  return CoursesReview__Grade.value(g) < passGrade;
                }));
}

function trimToOption(s) {
  if (s.trim() === "") {
    return ;
  } else {
    return s;
  }
}

function navigationDisabled(state) {
  if (state.newFeedback.trim() !== "" || state.note !== undefined) {
    return true;
  } else {
    return state.saving;
  }
}

function gradeSubmissionQuery(submissionId, state, send, evaluationCriteria, overlaySubmission, currentUser, updateSubmissionCB) {
  Curry._1(send, /* BeginSaving */0);
  var feedback = trimToOption(state.newFeedback);
  var grades = state.grades.map(function (g) {
        return {
                evaluationCriterionId: CoursesReview__Grade.evaluationCriterionId(g),
                grade: CoursesReview__Grade.value(g)
              };
      });
  var variables = makeVariables(submissionId, feedback, grades, Belt_Option.flatMap(state.note, trimToOption), SubmissionChecklistItem.encodeArray(state.checklist), undefined);
  Curry._3($$fetch, undefined, undefined, variables).then(function (response) {
        if (response.createGrading.success) {
          Curry._1(updateSubmissionCB, CoursesReview__OverlaySubmission.update(passed(state.grades, evaluationCriteria) ? Caml_option.some(new Date()) : undefined, AppRouter__User.name(currentUser), CoursesReview__OverlaySubmission.feedback(overlaySubmission).concat(Belt_Option.mapWithDefault(feedback, [], (function (f) {
                              return [makeFeedback(currentUser, f)];
                            }))), state.grades, Caml_option.some(new Date()), state.checklist, overlaySubmission));
          Curry._1(send, {
                TAG: /* FinishGrading */6,
                _0: state.grades
              });
        } else {
          Curry._1(send, /* FinishSaving */1);
        }
        return Promise.resolve(undefined);
      });
  
}

function inactiveWarning(submissionDetails) {
  if (!CoursesReview__SubmissionDetails.inactiveStudents(submissionDetails)) {
    return null;
  }
  var submissionDeadlineDate = DateFns.addDays(CoursesReview__SubmissionDetails.createdAt(submissionDetails), CoursesReview__SubmissionDetails.inactiveSubmissionReviewAllowedDays(submissionDetails));
  var warning = CoursesReview__SubmissionDetails.students(submissionDetails).length > 1 ? (
      isSubmissionReviewAllowed(submissionDetails) ? t(undefined, undefined, "students_dropped_out_message_without_timestamp") : t([[
                "timestamp",
                DateFns.format(submissionDeadlineDate, "do MMMM, yyyy")
              ]], undefined, "students_dropped_out_message_with_timestamp")
    ) : (
      isSubmissionReviewAllowed(submissionDetails) ? t(undefined, undefined, "student_dropped_out_message_without_timestamp") : t([[
                "timestamp",
                DateFns.format(submissionDeadlineDate, "do MMMM, yyyy")
              ]], undefined, "student_dropped_out_message_with_timestamp")
    );
  return React.createElement("div", {
              className: "border border-yellow-400 rounded bg-yellow-200 py-2 px-3 text-xs md:text-sm md:text-center"
            }, React.createElement("i", {
                  className: "fas fa-exclamation-triangle"
                }), React.createElement("span", {
                  className: "ml-2"
                }, warning));
}

function closeOverlay(state, courseId, filter) {
  var path = "/courses/" + courseId + "/review?" + CoursesReview__Filter.toQueryString(filter);
  if (navigationDisabled(state)) {
    return WindowUtils.confirm((function (param) {
                  
                }), t(undefined, undefined, "close_submission_warning"), (function (param) {
                  return RescriptReactRouter.push(path);
                }));
  } else {
    return RescriptReactRouter.push(path);
  }
}

function reviewNextButton(nextSubmission, send, courseId, filter, submissionId, className) {
  return ReactUtils.nullIf(React.createElement("button", {
                  className: className,
                  disabled: nextSubmission === /* DataLoading */1,
                  onClick: (function (param) {
                      return getNextSubmission(send, courseId, filter, submissionId);
                    })
                }, ReactUtils.nullUnless(React.createElement("i", {
                          className: "fas fa-spinner fa-pulse mr-2"
                        }), nextSubmission === /* DataLoading */1), React.createElement("p", {
                      className: "pr-2"
                    }, t(undefined, undefined, "review_next")), React.createElement(Icon.make, {
                      className: "if i-arrow-right-short-light text-lg lg:text-2xl"
                    })), nextSubmission === /* DataEmpty */2);
}

function headerSection(state, nextSubmission, send, submissionDetails, filter, submissionId) {
  var teamName = CoursesReview__SubmissionDetails.teamName(submissionDetails);
  var studentCount = CoursesReview__SubmissionDetails.students(submissionDetails).length;
  return React.createElement("div", {
              "aria-label": "submissions-overlay-header",
              className: "bg-gray-50 border-b border-gray-300 flex justify-center"
            }, React.createElement("div", {
                  className: "bg-white flex justify-between w-full"
                }, React.createElement("div", {
                      className: "flex flex-col md:flex-row w-full md:w-auto"
                    }, React.createElement("div", {
                          className: "flex flex-1 md:flex-none justify-between border-b md:border-0"
                        }, React.createElement("button", {
                              "aria-label": "submissions-overlay-close",
                              className: "flex flex-col items-center justify-center leading-tight px-3 py-2 md:px-5 md:py-4 cursor-pointer border-r bg-white text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:ring-2 focus:ring-focusColor-500 ring-inset ",
                              title: t(undefined, undefined, "close"),
                              onClick: (function (param) {
                                  return closeOverlay(state, CoursesReview__SubmissionDetails.courseId(submissionDetails), filter);
                                })
                            }, React.createElement("div", {
                                  className: "flex items-center justify-center bg-gray-100 rounded-full w-8 h-8"
                                }, React.createElement(Icon.make, {
                                      className: "if i-times-regular text-lg lg:text-2xl"
                                    })), React.createElement("span", {
                                  className: "text-xs mt-0.5"
                                }, t(undefined, undefined, "close"))), React.createElement("div", {
                              className: "flex space-x-4"
                            }, React.createElement(CoursesStudents__PersonalCoaches.make, {
                                  tooltipPosition: "Bottom",
                                  defaultAvatarSize: "8",
                                  mdAvatarSize: "8",
                                  title: React.createElement("span", {
                                        className: "hidden"
                                      }, t(undefined, undefined, "assigned_coaches")),
                                  className: "flex md:hidden items-center shrink-0",
                                  coaches: CoursesReview__SubmissionDetails.coaches(submissionDetails)
                                }), reviewNextButton(nextSubmission, send, CoursesReview__SubmissionDetails.courseId(submissionDetails), filter, submissionId, "flex shrink-0 items-center md:hidden border-l text-sm font-semibold px-3 py-2 md:px-5 md:py-4 hover:bg-gray-50 hover:text-primary-500"))), React.createElement("div", {
                          className: "px-4 py-3"
                        }, React.createElement("div", {
                              className: "block text-sm md:pr-2"
                            }, React.createElement("span", {
                                  className: "bg-gray-300 text-xs font-semibold px-2 py-px rounded"
                                }, LevelLabel.format(undefined, undefined, CoursesReview__SubmissionDetails.levelNumber(submissionDetails))), React.createElement("a", {
                                  className: "ml-2 font-semibold underline text-gray-900 hover:bg-primary-100 hover:text-primary-600 text-base focus:ring-2 focus:ring-offset-2 focus:ring-focusColor-500",
                                  href: "/targets/" + CoursesReview__SubmissionDetails.targetId(submissionDetails),
                                  target: "_blank"
                                }, CoursesReview__SubmissionDetails.targetTitle(submissionDetails))), React.createElement("div", {
                              className: "text-left mt-1 text-xs text-gray-800"
                            }, teamName !== undefined ? React.createElement("span", undefined, t(undefined, undefined, "submitted_by_team"), React.createElement("span", {
                                        className: "font-semibold"
                                      }, teamName), " - ") : React.createElement("span", undefined, t(undefined, undefined, "submitted_by")), CoursesReview__SubmissionDetails.students(submissionDetails).map(function (student, index) {
                                  var commaRequired = (index + 1 | 0) !== studentCount;
                                  return React.createElement("span", {
                                              key: CoursesReview__Student.id(student)
                                            }, React.createElement("a", {
                                                  className: "font-semibold underline focus:ring-2 focus:ring-offset-2 focus:ring-focusColor-500",
                                                  href: "/students/" + CoursesReview__Student.id(student) + "/report",
                                                  target: "_blank"
                                                }, CoursesReview__Student.name(student)), commaRequired ? ", " : "");
                                })))), React.createElement("div", {
                      className: "hidden md:flex shrink-0 space-x-6"
                    }, React.createElement(CoursesStudents__PersonalCoaches.make, {
                          tooltipPosition: "Bottom",
                          defaultAvatarSize: "8",
                          mdAvatarSize: "8",
                          title: React.createElement("span", {
                                className: "mr-2"
                              }, t(undefined, undefined, "assigned_coaches")),
                          className: "flex w-full md:w-auto items-center shrink-0",
                          coaches: CoursesReview__SubmissionDetails.coaches(submissionDetails)
                        }), reviewNextButton(nextSubmission, send, CoursesReview__SubmissionDetails.courseId(submissionDetails), filter, submissionId, "flex items-center border-l text-sm font-semibold px-5 py-4 hover:bg-gray-50 hover:text-primary-500 focus:ring-2 focus:ring-focusColor-500 ring-inset "))));
}

function updateGrading(grade, state, send) {
  var newGrades = state.grades.filter(function (g) {
          return CoursesReview__Grade.evaluationCriterionId(g) !== CoursesReview__Grade.evaluationCriterionId(grade);
        }).concat([grade]);
  return Curry._1(send, {
              TAG: /* UpdateGrades */2,
              _0: newGrades
            });
}

function handleGradePillClick(evaluationCriterionId, value, state, send, $$event) {
  $$event.preventDefault();
  if (send !== undefined) {
    return updateGrading(CoursesReview__Grade.make(evaluationCriterionId, value), state, send);
  }
  
}

function findEvaluationCriterion(evaluationCriteria, evaluationCriterionId) {
  var ec = evaluationCriteria.find(function (ec) {
        return EvaluationCriterion.id(ec) === evaluationCriterionId;
      });
  if (ec !== undefined) {
    return ec;
  } else {
    Rollbar.error("Unable to find evaluation Criterion with id: " + (evaluationCriterionId + "in CoursesRevew__Editor"));
    return Caml_array.get(evaluationCriteria, 0);
  }
}

function gradePillHeader(evaluationCriteriaName, selectedGrade, gradeLabels) {
  return React.createElement("div", {
              className: "flex justify-between"
            }, React.createElement("p", {
                  className: "text-xs font-semibold"
                }, evaluationCriteriaName), React.createElement("p", {
                  className: "text-xs font-semibold"
                }, String(selectedGrade) + ("/" + String(GradeLabel.maxGrade($$Array.to_list(gradeLabels))))));
}

function gradePillClasses(selectedGrade, currentGrade, passgrade, send) {
  var defaultClasses = "course-review-editor__grade-pill border-gray-300 py-1 px-2 text-sm flex-1 font-semibold transition " + (
    send !== undefined ? "cursor-pointer hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-inset focus:ring-focusColor-500 " + (
        Caml_obj.caml_greaterequal(currentGrade, passgrade) ? "hover:bg-green-500 hover:text-white " : "hover:bg-red-500 hover:text-white "
      ) : ""
  );
  return defaultClasses + (
          Caml_obj.caml_lessequal(currentGrade, selectedGrade) ? (
              Caml_obj.caml_greaterequal(selectedGrade, passgrade) ? "cursor-default bg-green-500 text-white shadow-lg" : "cursor-default bg-red-500 text-white shadow-lg"
            ) : "bg-white text-gray-900"
        );
}

function showGradePill(key, submissionDetails, evaluationCriterion, gradeValue, passGrade, state, send) {
  return React.createElement("div", {
              key: String(key),
              "aria-label": "evaluation-criterion-" + EvaluationCriterion.id(evaluationCriterion),
              className: "mt-2"
            }, gradePillHeader(EvaluationCriterion.name(evaluationCriterion), gradeValue, EvaluationCriterion.gradesAndLabels(evaluationCriterion)), React.createElement("div", {
                  className: "course-review-editor__grade-bar inline-flex w-full text-center mt-1"
                }, EvaluationCriterion.gradesAndLabels(evaluationCriterion).map(function (gradeLabel) {
                      var gradeLabelGrade = GradeLabel.grade(gradeLabel);
                      var partial_arg = EvaluationCriterion.id(evaluationCriterion);
                      return React.createElement("button", {
                                  key: String(gradeLabelGrade),
                                  className: gradePillClasses(gradeValue, gradeLabelGrade, passGrade, send),
                                  title: GradeLabel.label(gradeLabel),
                                  disabled: isSubmissionReviewAllowed(submissionDetails),
                                  onClick: (function (param) {
                                      return handleGradePillClick(partial_arg, gradeLabelGrade, state, send, param);
                                    })
                                }, send !== undefined ? String(gradeLabelGrade) : null);
                    })));
}

function showGrades(grades, evaluationCriteria, submissionDetails, state) {
  return React.createElement("div", undefined, CoursesReview__Grade.sort(evaluationCriteria, grades).map(function (grade, key) {
                  var gradeEcId = CoursesReview__Grade.evaluationCriterionId(grade);
                  var ec = ArrayUtils.unsafeFind((function (ec) {
                          return EvaluationCriterion.id(ec) === gradeEcId;
                        }), "Unable to find evaluation Criterion with id: " + (gradeEcId + "in CoursesRevew__Editor"), evaluationCriteria);
                  return showGradePill(key, submissionDetails, ec, CoursesReview__Grade.value(grade), EvaluationCriterion.passGrade(ec), state, undefined);
                }));
}

function renderGradePills(evaluationCriteria, targetEvaluationCriteriaIds, submissionDetails, state, send) {
  return targetEvaluationCriteriaIds.map(function (evaluationCriterionId, key) {
              var ec = ArrayUtils.unsafeFind((function (e) {
                      return EvaluationCriterion.id(e) === evaluationCriterionId;
                    }), "CoursesRevew__Editor: Unable to find evaluation criterion with id - " + evaluationCriterionId, evaluationCriteria);
              var grade = state.grades.find(function (g) {
                    return CoursesReview__Grade.evaluationCriterionId(g) === EvaluationCriterion.id(ec);
                  });
              var gradeValue = grade !== undefined ? CoursesReview__Grade.value(grade) : 0;
              var passGrade = EvaluationCriterion.passGrade(ec);
              return showGradePill(key, submissionDetails, ec, gradeValue, passGrade, state, send);
            });
}

function badgeColorClasses(statusColor) {
  switch (statusColor) {
    case /* Red */0 :
        return "bg-red-100 border-red-400";
    case /* Orange */1 :
        return "bg-orange-100 border-orange-400";
    case /* Green */2 :
        return "bg-green-100 border-green-400";
    case /* Gray */3 :
        return "bg-gray-50 border-gray-300";
    
  }
}

function gradeBadgeClasses(statusColor, status, badge) {
  return (
          badge ? "px-2 py-2 space-x-2 flex justify-center border rounded items-center " : "w-12 h-10 p-1 mr-2 md:mr-0 md:w-26 md:h-22 rounded md:rounded-lg border flex justify-center items-center "
        ) + badgeColorClasses(statusColor) + (
          status === 0 ? "course-review-editor__status-pulse" : ""
        );
}

function textColor(statusColor) {
  switch (statusColor) {
    case /* Red */0 :
        return "text-red-800";
    case /* Orange */1 :
        return "text-orange-800";
    case /* Green */2 :
        return "text-green-800";
    case /* Gray */3 :
        return "text-gray-800";
    
  }
}

function submissionReviewStatus(status, overlaySubmission) {
  var match = typeof status === "number" ? (
      status !== 0 ? [
          t(undefined, undefined, "status.pending_review"),
          /* Gray */3
        ] : [
          t(undefined, undefined, "status.reviewing"),
          /* Orange */1
        ]
    ) : (
      status._0 ? [
          t(undefined, undefined, "status.completed"),
          /* Green */2
        ] : [
          t(undefined, undefined, "status.rejected"),
          /* Red */0
        ]
    );
  var color = match[1];
  var match$1 = CoursesReview__OverlaySubmission.evaluatedAt(overlaySubmission);
  var tmp;
  if (match$1 !== undefined && typeof status !== "number") {
    var name = CoursesReview__OverlaySubmission.evaluatorName(overlaySubmission);
    tmp = React.createElement("div", undefined, React.createElement("div", undefined, React.createElement("p", {
                  className: "text-xs text-gray-800"
                }, t(undefined, undefined, "evaluated_by")), React.createElement("p", {
                  className: "text-xs font-semibold"
                }, name !== undefined ? name : React.createElement("em", undefined, t(undefined, undefined, "deleted_coach")))));
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              "aria-label": "submission-status",
              className: "hidden md:flex space-x-4 justify-end w-3/4"
            }, React.createElement("div", {
                  className: "flex items-center"
                }, tmp, React.createElement("div", {
                      className: "flex justify-center ml-2 md:ml-4"
                    }, React.createElement("div", {
                          className: gradeBadgeClasses(color, status, true)
                        }, typeof status === "number" ? (
                            status !== 0 ? React.createElement(Icon.make, {
                                    className: "if i-eye-solid text-xl text-gray-400"
                                  }) : React.createElement(Icon.make, {
                                    className: "if i-writing-pad-solid text-xl text-orange-300"
                                  })
                          ) : (
                            status._0 ? React.createElement(Icon.make, {
                                    className: "if i-badge-check-solid text-xl text-green-500"
                                  }) : React.createElement(FaIcon.make, {
                                    classes: "fas fa-exclamation-triangle text-xl text-red-500"
                                  })
                          ), React.createElement("p", {
                              className: "text-xs font-semibold " + textColor(color)
                            }, match[0])))));
}

function submissionStatusIcon(status, overlaySubmission) {
  var match = typeof status === "number" ? (
      status !== 0 ? [
          t(undefined, undefined, "status.pending_review"),
          /* Gray */3
        ] : [
          t(undefined, undefined, "status.reviewing"),
          /* Orange */1
        ]
    ) : (
      status._0 ? [
          t(undefined, undefined, "status.completed"),
          /* Green */2
        ] : [
          t(undefined, undefined, "status.rejected"),
          /* Red */0
        ]
    );
  var color = match[1];
  var match$1 = CoursesReview__OverlaySubmission.evaluatedAt(overlaySubmission);
  var tmp;
  if (match$1 !== undefined && typeof status !== "number") {
    var name = CoursesReview__OverlaySubmission.evaluatorName(overlaySubmission);
    tmp = React.createElement("div", {
          className: "bg-gray-50 block md:flex flex-col w-full justify-between rounded-lg pt-3 mr-2 mt-4 md:mt-0"
        }, React.createElement("div", undefined, React.createElement("p", {
                  className: "text-xs px-3"
                }, "Evaluated By"), React.createElement("p", {
                  className: "text-sm font-semibold px-3 pb-3"
                }, name !== undefined ? name : React.createElement("em", undefined, t(undefined, undefined, "deleted_coach")))), React.createElement("div", {
              className: "text-xs bg-gray-300 flex items-center rounded-b-lg px-3 py-2 md:px-3 md:py-1"
            }, t([[
                    "evaluated_at",
                    DateFns.format(Caml_option.valFromOption(match$1), "MMMM d, yyyy")
                  ]], undefined, "evaluated_at")));
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              "aria-label": "submission-status",
              className: "flex flex-1 flex-col items-center justify-center md:border-l mt-4 md:mt-0"
            }, React.createElement("div", {
                  className: "flex flex-col-reverse md:flex-row items-start md:items-stretch justify-center w-full md:pl-6"
                }, tmp, React.createElement("div", {
                      className: "w-full md:w-26 flex flex-row md:flex-col md:items-center justify-center"
                    }, React.createElement("div", {
                          className: gradeBadgeClasses(color, status, false)
                        }, typeof status === "number" ? (
                            status !== 0 ? React.createElement(Icon.make, {
                                    className: "if i-eye-solid text-xl md:text-4xl text-gray-400"
                                  }) : React.createElement(Icon.make, {
                                    className: "if i-writing-pad-solid text-xl md:text-5xl text-orange-300"
                                  })
                          ) : (
                            status._0 ? React.createElement(Icon.make, {
                                    className: "if i-badge-check-solid text-xl md:text-5xl text-green-500"
                                  }) : React.createElement(FaIcon.make, {
                                    classes: "fas fa-exclamation-triangle text-xl md:text-4xl text-red-500"
                                  })
                          )), React.createElement("p", {
                          className: "text-xs flex items-center justify-center md:block text-center w-full border rounded px-1 py-px font-semibold md:mt-1 " + badgeColorClasses(color) + " " + textColor(color)
                        }, match[0]))));
}

function gradeSubmission(submissionId, state, send, evaluationCriteria, updateSubmissionCB, status, currentUser, overlaySubmission, $$event) {
  $$event.preventDefault();
  if (typeof status === "number") {
    return ;
  } else {
    return gradeSubmissionQuery(submissionId, state, send, evaluationCriteria, overlaySubmission, currentUser, updateSubmissionCB);
  }
}

function reviewButtonDisabled(status) {
  if (typeof status === "number") {
    return true;
  } else {
    return false;
  }
}

function computeStatus(overlaySubmission, selectedGrades, evaluationCriteria, targetEvaluationCriteriaIds) {
  var currentGradingCriteria = evaluationCriteria.filter(function (criterion) {
        return $$Array.mem(EvaluationCriterion.id(criterion), targetEvaluationCriteriaIds);
      });
  var match = CoursesReview__OverlaySubmission.passedAt(overlaySubmission);
  var match$1 = ArrayUtils.isNotEmpty(CoursesReview__OverlaySubmission.grades(overlaySubmission));
  if (match !== undefined) {
    return /* Graded */{
            _0: true
          };
  } else if (match$1) {
    return /* Graded */{
            _0: false
          };
  } else if (Caml_obj.caml_equal(selectedGrades, [])) {
    return /* Ungraded */1;
  } else if (selectedGrades.length !== currentGradingCriteria.length) {
    return /* Grading */0;
  } else {
    return /* Graded */{
            _0: passed(selectedGrades, currentGradingCriteria)
          };
  }
}

function submitButtonText(feedback, grades) {
  var match = feedback !== "";
  ArrayUtils.isNotEmpty(grades);
  if (match) {
    return t(undefined, undefined, "save_grades_and_send_feedback");
  } else {
    return t(undefined, undefined, "save_grades");
  }
}

function noteForm(submissionDetails, overlaySubmission, teamSubmission, note, send) {
  var _someGrades = CoursesReview__OverlaySubmission.grades(overlaySubmission);
  if (_someGrades.length !== 0) {
    return null;
  }
  var match = teamSubmission ? [
      t(undefined, undefined, "team"),
      t(undefined, undefined, "team_notice")
    ] : [
      t(undefined, undefined, "student"),
      ""
    ];
  var noteAbout = match[0];
  var help = React.createElement(HelpIcon.make, {
        className: "ml-1",
        children: t([
              [
                "note_about",
                noteAbout
              ],
              [
                "additional_help",
                match[1]
              ]
            ], undefined, "help_text")
      });
  var textareaId = "note-for-submission-" + CoursesReview__OverlaySubmission.id(overlaySubmission);
  return React.createElement("div", {
              className: "text-sm"
            }, React.createElement("div", {
                  className: "font-medium text-sm flex"
                }, React.createElement(Icon.make, {
                      className: "if i-long-text-light text-gray-800 text-base"
                    }), note !== undefined ? React.createElement("span", {
                        className: "ml-2 md:ml-4 tracking-wide"
                      }, React.createElement("label", {
                            htmlFor: textareaId
                          }, t(undefined, undefined, "write_a_note")), help) : React.createElement("div", {
                        className: "ml-2 md:ml-4 tracking-wide w-full"
                      }, React.createElement("div", undefined, React.createElement("span", undefined, t(undefined, undefined, "note_help") + (noteAbout + "?")), help), React.createElement("button", {
                            className: "btn btn-default mt-2",
                            disabled: isSubmissionReviewAllowed(submissionDetails),
                            onClick: (function (param) {
                                return Curry._1(send, {
                                            TAG: /* UpdateNote */4,
                                            _0: ""
                                          });
                              })
                          }, React.createElement("i", {
                                className: "far fa-edit"
                              }), React.createElement("span", {
                                className: "pl-2"
                              }, t(undefined, undefined, "write_a_note"))))), note !== undefined ? React.createElement("div", {
                    className: "ml-6 md:ml-7 mt-2"
                  }, React.createElement(MarkdownEditor.make, {
                        value: note,
                        onChange: (function (value) {
                            return Curry._1(send, {
                                        TAG: /* UpdateNote */4,
                                        _0: value
                                      });
                          }),
                        profile: /* Permissive */0,
                        textareaId: textareaId,
                        maxLength: 10000,
                        placeholder: t(undefined, undefined, "note_placeholder")
                      })) : null);
}

function feedbackGenerator(submissionDetails, reviewChecklist, state, showAddFeedbackEditorOpt, send) {
  var showAddFeedbackEditor = showAddFeedbackEditorOpt !== undefined ? showAddFeedbackEditorOpt : true;
  return React.createElement("div", {
              className: "px-4 md:px-6 pt-4 space-y-8"
            }, React.createElement("div", undefined, React.createElement("div", {
                      className: "flex h-7 items-end"
                    }, React.createElement("h5", {
                          className: "font-medium text-sm flex items-center"
                        }, React.createElement(PfIcon.make, {
                              className: "if i-check-square-alt-light text-gray-800 text-base md:text-lg inline-block"
                            }), React.createElement("span", {
                              className: "ml-2 md:ml-3 tracking-wide"
                            }, t(undefined, undefined, "review_checklist")))), React.createElement("div", {
                      className: "mt-2 md:ml-8"
                    }, React.createElement("button", {
                          className: "bg-primary-100 flex items-center justify-between px-4 py-3 border border-dashed border-gray-600 rounded-md w-full text-left font-semibold text-sm text-primary-500 hover:bg-gray-300 hover:text-primary-600 hover:border-primary-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-focusColor-500 transition",
                          disabled: isSubmissionReviewAllowed(submissionDetails),
                          onClick: (function (param) {
                              return Curry._1(send, /* ShowChecklistEditor */3);
                            })
                        }, React.createElement("span", undefined, ArrayUtils.isEmpty(reviewChecklist) ? t(undefined, undefined, "create_review_checklist") : t(undefined, undefined, "show_review_checklist")), React.createElement(FaIcon.make, {
                              classes: "fas fa-arrow-right"
                            })))), showAddFeedbackEditor ? React.createElement("div", {
                    className: "course-review__feedback-editor text-sm"
                  }, React.createElement("h5", {
                        className: "font-medium text-sm flex items-center"
                      }, React.createElement(PfIcon.make, {
                            className: "if i-comment-alt-light text-gray-800 text-base md:text-lg inline-block"
                          }), React.createElement("span", {
                            className: "ml-2 md:ml-3 tracking-wide"
                          }, t(undefined, undefined, "add_your_feedback"))), ReactUtils.nullUnless(React.createElement("div", {
                            className: "inline-flex items-center bg-green-200 mt-2 md:ml-8 text-green-800 px-2 py-1 rounded-md"
                          }, React.createElement(Icon.make, {
                                className: "if i-check-circle-solid text-green-700 text-base"
                              }), React.createElement("span", {
                                className: "pl-2 text-sm font-semibold"
                              }, t(undefined, undefined, "feedback_generated_text"))), state.feedbackGenerated), React.createElement("div", {
                        "aria-label": "feedback",
                        className: "mt-2 md:ml-8"
                      }, React.createElement(MarkdownEditor.make, {
                            value: state.newFeedback,
                            onChange: (function (feedback) {
                                return Curry._1(send, {
                                            TAG: /* UpdateFeedback */0,
                                            _0: feedback
                                          });
                              }),
                            profile: /* Permissive */0,
                            maxLength: 10000,
                            placeholder: t(undefined, undefined, "feedback_placeholder"),
                            disabled: isSubmissionReviewAllowed(submissionDetails)
                          }))) : null);
}

function showFeedback(feedback) {
  return React.createElement("div", {
              className: "divide-y space-y-6 md:ml-8"
            }, ArrayUtils.copyAndSort((function (x, y) {
                      return DateFns.differenceInSeconds(CoursesReview__Feedback.createdAt(y), CoursesReview__Feedback.createdAt(x));
                    }), feedback).map(function (f, index) {
                  var avatarUrl = CoursesReview__Feedback.coachAvatarUrl(f);
                  return React.createElement(Spread.make, {
                              props: {
                                "data-title": "feedback-section"
                              },
                              children: React.createElement("div", undefined, React.createElement("div", {
                                        className: "pt-6"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, React.createElement("div", {
                                                className: "shrink-0 w-10 h-10 bg-gray-300 rounded-full overflow-hidden mr-4 object-cover"
                                              }, avatarUrl !== undefined ? React.createElement("img", {
                                                      src: avatarUrl
                                                    }) : React.createElement(Avatar.make, {
                                                      name: CoursesReview__Feedback.coachName(f)
                                                    })), React.createElement("div", undefined, React.createElement("div", {
                                                    className: "flex flex-col md:flex-row"
                                                  }, React.createElement("p", {
                                                        className: "font-semibold text-sm leading-tight inline-flex"
                                                      }, CoursesReview__Feedback.coachName(f)), React.createElement("p", {
                                                        className: "block md:inline-flex text-xs text-gray-800 md:ml-2 leading-tight"
                                                      }, "(" + (CoursesReview__Feedback.coachTitle(f) + ")"))), React.createElement("p", {
                                                    className: "text-xs leading-tight font-semibold inline-block text-gray-800"
                                                  }, CoursesReview__Feedback.createdAtPretty(f)))), React.createElement("div", {
                                            className: "md:ml-14"
                                          }, React.createElement(MarkdownBlock.make, {
                                                markdown: CoursesReview__Feedback.value(f),
                                                className: "pt-1 text-sm",
                                                profile: /* Permissive */0
                                              })))),
                              key: String(index)
                            });
                }));
}

function showSubmissionStatus(status) {
  var match = typeof status === "number" ? [
      t(undefined, undefined, "status.pending_review"),
      "bg-yellow-100 text-yellow-800 "
    ] : (
      status._0 ? [
          t(undefined, undefined, "status.completed"),
          "bg-green-100 text-green-800"
        ] : [
          t(undefined, undefined, "status.rejected"),
          "bg-red-100 text-red-700"
        ]
    );
  return React.createElement("div", {
              className: "font-semibold px-2 py-px rounded " + match[1]
            }, React.createElement("p", undefined, match[0]));
}

function updateReviewChecklist(cb, send, checklist) {
  if (ArrayUtils.isEmpty(checklist)) {
    Curry._1(send, /* ShowGradesEditor */2);
  }
  return Curry._1(cb, checklist);
}

function updateReviewer(cb, send, reviewer) {
  Curry._1(cb, reviewer);
  return Curry._1(send, /* ShowGradesEditor */2);
}

function pageTitle(number, submissionDetails) {
  var teamName = CoursesReview__SubmissionDetails.teamName(submissionDetails);
  var studentOrTeamName = teamName !== undefined ? teamName : CoursesReview__SubmissionDetails.students(submissionDetails).map(CoursesReview__Student.name).join(", ");
  return t(undefined, undefined, "submission") + " " + String(number) + " | " + t(undefined, undefined, "level_acronym") + CoursesReview__SubmissionDetails.levelNumber(submissionDetails) + " | " + studentOrTeamName;
}

function reportStatusString(report) {
  var match = CoursesReview__SubmissionReport.status(report);
  switch (match.TAG | 0) {
    case /* Queued */0 :
        return t(undefined, undefined, "report_status_string.queued");
    case /* InProgress */1 :
        return t(undefined, undefined, "report_status_string.in_progress");
    case /* Completed */2 :
        switch (match._1) {
          case /* Success */0 :
              return t(undefined, undefined, "report_status_string.completed.success");
          case /* Failure */1 :
              return t(undefined, undefined, "report_status_string.completed.failure");
          case /* Error */2 :
              return t(undefined, undefined, "report_status_string.completed.error");
          
        }
    
  }
}

function reportStatusIconClasses(report) {
  var match = CoursesReview__SubmissionReport.status(report);
  switch (match.TAG | 0) {
    case /* Queued */0 :
        return "if i-clock-light text-2xl text-gray-600 rounded-full";
    case /* InProgress */1 :
        return "if animate-spin i-dashed-circle-regular text-2xl text-yellow-500 rounded-full";
    case /* Completed */2 :
        switch (match._1) {
          case /* Success */0 :
              return "if i-check-circle-solid text-2xl text-green-500 bg-white rounded-full";
          case /* Failure */1 :
              return "if i-times-circle-solid text-2xl text-red-500 bg-white rounded-full";
          case /* Error */2 :
              return "if i-exclamation-triangle-circle-solid text-2xl text-gray-600 bg-white rounded-full";
          
        }
    
  }
}

function reportConclusionTimeString(report) {
  var queuedAt = CoursesReview__SubmissionReport.status(report);
  switch (queuedAt.TAG | 0) {
    case /* Queued */0 :
        return "Queued " + DateFns.formatDistanceToNowStrict(queuedAt._0, true, undefined, undefined, undefined);
    case /* InProgress */1 :
        return "Started " + DateFns.formatDistanceToNowStrict(queuedAt._0, true, undefined, undefined, undefined);
    case /* Completed */2 :
        var completedTimestamps = queuedAt._0;
        return "Finished " + DateFns.formatDistanceToNowStrict(completedTimestamps.completedAt, true, undefined, undefined, undefined) + ", and took " + DateFns.formatDistance(completedTimestamps.completedAt, completedTimestamps.startedAt, true, undefined, undefined);
    
  }
}

function loadSubmissionReport(report, updateSubmissionReportCB) {
  var id = CoursesReview__SubmissionReport.id(report);
  Curry._3(make$2, undefined, undefined, {
          id: id
        }).then(function (response) {
        var reportData = response.submissionReport;
        var updatedReport = CoursesReview__SubmissionReport.makeFromJS(reportData);
        Curry._1(updateSubmissionReportCB, updatedReport);
        return Promise.resolve(undefined);
      });
  
}

function reloadSubmissionReport(report, updateSubmissionReportCB) {
  var match = CoursesReview__SubmissionReport.status(report);
  switch (match.TAG | 0) {
    case /* Queued */0 :
    case /* InProgress */1 :
        return loadSubmissionReport(report, updateSubmissionReportCB);
    case /* Completed */2 :
        return ;
    
  }
}

function CoursesReview__Editor(Props) {
  var overlaySubmission = Props.overlaySubmission;
  var teamSubmission = Props.teamSubmission;
  var evaluationCriteria = Props.evaluationCriteria;
  var reviewChecklist = Props.reviewChecklist;
  var updateSubmissionCB = Props.updateSubmissionCB;
  var updateReviewChecklistCB = Props.updateReviewChecklistCB;
  var targetId = Props.targetId;
  var targetEvaluationCriteriaIds = Props.targetEvaluationCriteriaIds;
  var currentUser = Props.currentUser;
  var number = Props.number;
  var submissionDetails = Props.submissionDetails;
  var submissionId = Props.submissionId;
  var updateReviewerCB = Props.updateReviewerCB;
  var submissionReport = Props.submissionReport;
  var updateSubmissionReportCB = Props.updateSubmissionReportCB;
  var submissionReportPollTime = Props.submissionReportPollTime;
  var match = React.useReducer(reducer, {
        grades: [],
        newFeedback: "",
        saving: false,
        showReport: false,
        checklist: CoursesReview__OverlaySubmission.checklist(overlaySubmission),
        note: undefined,
        editor: ArrayUtils.isEmpty(CoursesReview__OverlaySubmission.grades(overlaySubmission)) ? (
            Belt_Option.mapWithDefault(CoursesReview__SubmissionDetails.reviewer(submissionDetails), false, (function (r) {
                    return UserProxy.userId(CoursesReview__Reviewer.user(r)) === AppRouter__User.id(currentUser);
                  })) || isSubmissionReviewAllowed(submissionDetails) ? /* GradesEditor */1 : /* AssignReviewer */0
          ) : /* ReviewedSubmissionEditor */({
              _0: CoursesReview__OverlaySubmission.grades(overlaySubmission)
            }),
        additonalFeedbackEditorVisible: false,
        feedbackGenerated: false,
        nextSubmission: /* DataUnloaded */0,
        reloadSubmissionReport: false
      });
  var send = match[1];
  var state = match[0];
  var status = computeStatus(overlaySubmission, state.grades, evaluationCriteria, targetEvaluationCriteriaIds);
  var match$1 = CoursesReview__OverlaySubmission.grades(overlaySubmission);
  var updateChecklistCB = match$1.length !== 0 ? undefined : (function (checklist) {
        return Curry._1(send, {
                    TAG: /* UpdateChecklist */3,
                    _0: checklist
                  });
      });
  var pending = ArrayUtils.isEmpty(CoursesReview__OverlaySubmission.grades(overlaySubmission));
  var findEditor = function (pending, overlaySubmission) {
    if (pending) {
      if (Belt_Option.mapWithDefault(CoursesReview__SubmissionDetails.reviewer(submissionDetails), false, (function (r) {
                return UserProxy.userId(CoursesReview__Reviewer.user(r)) === AppRouter__User.id(currentUser);
              })) || isSubmissionReviewAllowed(submissionDetails)) {
        return /* GradesEditor */1;
      } else {
        return /* AssignReviewer */0;
      }
    } else {
      return /* ReviewedSubmissionEditor */{
              _0: CoursesReview__OverlaySubmission.grades(overlaySubmission)
            };
    }
  };
  React.useEffect((function () {
          if (submissionReport === undefined) {
            return ;
          }
          var intervalId = setInterval((function (param) {
                  return reloadSubmissionReport(submissionReport, updateSubmissionReportCB);
                }), Math.imul(submissionReportPollTime, 1000));
          return (function (param) {
                    clearInterval(intervalId);
                    
                  });
        }), []);
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var filter = CoursesReview__Filter.makeFromQueryParams(url.search);
  var tmp;
  if (submissionReport !== undefined) {
    var toggleTestReportIcon = state.showReport ? "i-arrows-collapse-light" : "i-arrows-expand-light";
    var match$2 = state.showReport;
    var match$3 = CoursesReview__SubmissionReport.testReport(submissionReport);
    tmp = React.createElement("div", {
          className: "p-4 md:p-6 space-y-8"
        }, React.createElement("div", {
              className: "bg-gray-300 p-4 rounded-md"
            }, React.createElement("div", {
                  className: "flex items-center justify-between text-sm"
                }, React.createElement("div", {
                      className: "flex items-start space-x-3"
                    }, React.createElement("div", {
                          className: "pt-1"
                        }, React.createElement(Icon.make, {
                              className: reportStatusIconClasses(submissionReport)
                            })), React.createElement("div", undefined, React.createElement("p", {
                              className: "font-semibold"
                            }, reportStatusString(submissionReport)), React.createElement("p", {
                              className: "text-gray-800 text-xs"
                            }, reportConclusionTimeString(submissionReport)))), ReactUtils.nullIf(React.createElement("button", {
                          className: "inline-flex items-center text-primary-500 px-3 py-2 rounded font-semibold hover:text-primary-700 hover:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-focusColor-500 transition",
                          onClick: (function (param) {
                              return Curry._1(send, /* ChangeReportVisibility */9);
                            })
                        }, React.createElement("span", {
                              className: "hidden md:block pr-3"
                            }, state.showReport ? t(undefined, undefined, "hide_test_report_button") : t(undefined, undefined, "show_test_report_button")), React.createElement("span", {
                              className: "inline-block w-5 h-5"
                            }, React.createElement(Icon.make, {
                                  className: "if text-xl " + toggleTestReportIcon
                                }))), Belt_Option.isNone(CoursesReview__SubmissionReport.testReport(submissionReport)))), match$2 && match$3 !== undefined && state.showReport ? React.createElement("div", undefined, React.createElement("p", {
                        className: "text-sm font-semibold mt-4"
                      }, t(undefined, undefined, "test_report")), React.createElement("div", {
                        className: "bg-white p-3 rounded-md border mt-2"
                      }, React.createElement(MarkdownBlock.make, {
                            markdown: match$3,
                            profile: /* Permissive */0
                          }))) : null));
  } else {
    tmp = null;
  }
  var grades = state.editor;
  var tmp$1;
  if (typeof grades === "number") {
    switch (grades) {
      case /* AssignReviewer */0 :
          tmp$1 = React.createElement("div", undefined, React.createElement("div", {
                    className: "flex items-center justify-between px-4 md:px-6 py-3 bg-white border-b sticky top-0 z-50 md:h-16"
                  }, React.createElement("p", {
                        className: "font-semibold"
                      }, "Review")), feedbackGenerator(submissionDetails, reviewChecklist, state, false, send), React.createElement(CoursesReview__ReviewerManager.make, {
                    submissionId: submissionId,
                    submissionDetails: submissionDetails,
                    updateReviewerCB: (function (param) {
                        Curry._1(updateReviewerCB, param);
                        return Curry._1(send, /* ShowGradesEditor */2);
                      })
                  }));
          break;
      case /* GradesEditor */1 :
          var reviewer = CoursesReview__SubmissionDetails.reviewer(submissionDetails);
          var partial_arg = CoursesReview__OverlaySubmission.id(overlaySubmission);
          tmp$1 = React.createElement("div", undefined, React.createElement("div", {
                    className: "flex items-center justify-between px-4 md:px-6 py-3 bg-white border-b sticky top-0 z-50 md:h-16"
                  }, React.createElement("p", {
                        className: "font-semibold"
                      }, "Review")), ReactUtils.nullIf(React.createElement("div", {
                        "aria-label": "Assigned to",
                        className: "px-4 py-4 border-b border-gray-300"
                      }, React.createElement("div", {
                            className: "flex items-center justify-between px-3 py-2 rounded-md bg-gray-50"
                          }, reviewer !== undefined ? React.createElement("div", undefined, React.createElement("div", undefined, React.createElement("p", {
                                          className: "text-xs text-gray-800"
                                        }, t(undefined, undefined, "assigned_to")), React.createElement("p", {
                                          className: "text-xs font-semibold"
                                        }, UserProxy.name(CoursesReview__Reviewer.user(reviewer))))) : null, React.createElement("div", {
                                className: "flex justify-center ml-2 md:ml-4"
                              }, React.createElement("button", {
                                    className: "btn btn-small bg-red-100 text-red-800 hover:bg-red-200 focus:ring-2 focus:ring-offset-2 focus:ring-focusColor-500",
                                    onClick: (function (param) {
                                        return unassignReviewer(submissionId, send, updateReviewerCB);
                                      })
                                  }, React.createElement(Icon.make, {
                                        className: "if i-times-regular"
                                      }), React.createElement("span", {
                                        className: "ml-2"
                                      }, t(undefined, undefined, "remove_assignment")))))), isSubmissionReviewAllowed(submissionDetails)), feedbackGenerator(submissionDetails, reviewChecklist, state, undefined, send), React.createElement("div", {
                    className: "w-full px-4 md:px-6 pt-8 space-y-8"
                  }, noteForm(submissionDetails, overlaySubmission, teamSubmission, state.note, send), React.createElement("div", undefined, React.createElement("h5", {
                            className: "font-medium text-sm flex items-center"
                          }, React.createElement(Icon.make, {
                                className: "if i-tachometer-light text-gray-800 text-base"
                              }), React.createElement("span", {
                                className: "ml-2 md:ml-3 tracking-wide"
                              }, "Grade Card")), React.createElement("div", {
                            className: "flex md:flex-row flex-col md:ml-8 rounded-lg mt-2"
                          }, React.createElement("div", {
                                className: "w-full md:w-9/12"
                              }, React.createElement("div", {
                                    className: "md:pr-8"
                                  }, renderGradePills(evaluationCriteria, targetEvaluationCriteriaIds, submissionDetails, state, send))), submissionStatusIcon(status, overlaySubmission)))), React.createElement("div", {
                    className: "flex justify-end bg-white md:bg-gray-50 border-t px-4 md:px-6 py-2 md:py-4 mt-4 md:ml-8"
                  }, React.createElement("button", {
                        className: "btn btn-success btn-large w-full border border-green-600",
                        disabled: reviewButtonDisabled(status),
                        onClick: (function (param) {
                            return gradeSubmission(partial_arg, state, send, evaluationCriteria, updateSubmissionCB, status, currentUser, overlaySubmission, param);
                          })
                      }, submitButtonText(state.newFeedback, state.grades))), ReactUtils.nullIf(React.createElement("div", {
                        className: "p-4 md:p-6"
                      }, React.createElement("h5", {
                            className: "font-medium text-sm flex items-center"
                          }, React.createElement(PfIcon.make, {
                                className: "if i-comment-alt-light text-gray-800 text-base md:text-lg inline-block"
                              }), React.createElement("span", {
                                className: "ml-2 md:ml-3 tracking-wide"
                              }, t(undefined, undefined, "feedback"))), showFeedback(CoursesReview__OverlaySubmission.feedback(overlaySubmission))), ArrayUtils.isEmpty(CoursesReview__OverlaySubmission.feedback(overlaySubmission))));
          break;
      case /* ChecklistEditor */2 :
          tmp$1 = React.createElement("div", undefined, React.createElement(CoursesReview__Checklist.make, {
                    reviewChecklist: reviewChecklist,
                    updateFeedbackCB: (function (feedback) {
                        return Curry._1(send, {
                                    TAG: /* GenerateFeeback */1,
                                    _0: feedback,
                                    _1: findEditor(pending, overlaySubmission)
                                  });
                      }),
                    feedback: state.newFeedback,
                    updateReviewChecklistCB: (function (param) {
                        return updateReviewChecklist(updateReviewChecklistCB, send, param);
                      }),
                    targetId: targetId,
                    cancelCB: (function (param) {
                        return Curry._1(send, {
                                    TAG: /* UpdateEditor */5,
                                    _0: findEditor(pending, overlaySubmission)
                                  });
                      }),
                    overlaySubmission: overlaySubmission,
                    submissionDetails: submissionDetails
                  }));
          break;
      
    }
  } else {
    var match$4 = CoursesReview__OverlaySubmission.evaluatedAt(overlaySubmission);
    var match$5 = CoursesReview__OverlaySubmission.feedback(overlaySubmission);
    tmp$1 = React.createElement("div", undefined, React.createElement("div", {
              className: "flex items-center justify-between px-4 md:px-6 py-3 bg-white border-b sticky top-0 z-50 md:h-16"
            }, React.createElement("div", undefined, React.createElement("p", {
                      className: "font-semibold"
                    }, t(undefined, undefined, "review")), Belt_Option.mapWithDefault(CoursesReview__OverlaySubmission.evaluatedAt(overlaySubmission), null, (function (date) {
                        return React.createElement("p", {
                                    className: "text-gray-800 text-xs"
                                  }, DateFns.format(date, "MMMM d, yyyy"));
                      }))), submissionReviewStatus(status, overlaySubmission)), React.createElement("div", {
              className: "w-full p-4 md:p-6"
            }, React.createElement("div", {
                  className: "flex items-center justify-between"
                }, React.createElement("h5", {
                      className: "font-medium text-sm flex items-center"
                    }, React.createElement(Icon.make, {
                          className: "if i-tachometer-light text-gray-800 text-base"
                        }), React.createElement("span", {
                          className: "ml-2 md:ml-3 tracking-wide"
                        }, t(undefined, undefined, "grade_card"))), React.createElement("div", undefined, match$4 !== undefined && typeof status !== "number" ? React.createElement("div", undefined, React.createElement("button", {
                                className: "btn btn-small bg-red-100 text-red-800 hover:bg-red-200 focus:ring-2 focus:ring-offset-2 focus:ring-focusColor-500",
                                disabled: isSubmissionReviewAllowed(submissionDetails),
                                onClick: (function (param) {
                                    return WindowUtils.confirm(undefined, t(undefined, undefined, "undo_grade_warning"), (function (param) {
                                                  return undoGrading(CoursesReview__OverlaySubmission.id(overlaySubmission), send);
                                                }));
                                  })
                              }, React.createElement("i", {
                                    className: "fas fa-undo"
                                  }), React.createElement("span", {
                                    className: "ml-2"
                                  }, t(undefined, undefined, "undo_grading")))) : null)), React.createElement("div", {
                  className: "flex md:flex-row flex-col md:ml-8 bg-gray-50 mt-2"
                }, React.createElement("div", {
                      className: "w-full"
                    }, showGrades(grades._0, evaluationCriteria, submissionDetails, state)), React.createElement("div", {
                      className: "block md:hidden"
                    }, submissionStatusIcon(status, overlaySubmission)))), ReactUtils.nullUnless(React.createElement("div", undefined, feedbackGenerator(submissionDetails, reviewChecklist, state, undefined, send), React.createElement("div", {
                      className: "flex justify-end px-4 md:px-6 py-4"
                    }, React.createElement("button", {
                          className: "btn btn-success border border-green-600 w-full md:w-auto",
                          disabled: state.newFeedback === "" || state.saving,
                          onClick: (function (param) {
                              return createFeedback(CoursesReview__OverlaySubmission.id(overlaySubmission), state.newFeedback, send, overlaySubmission, currentUser, updateSubmissionCB);
                            })
                        }, t(undefined, undefined, "share_feedback")))), state.additonalFeedbackEditorVisible), React.createElement("div", {
              className: "p-4 md:p-6"
            }, React.createElement("h5", {
                  className: "font-medium text-sm flex items-center"
                }, React.createElement(PfIcon.make, {
                      className: "if i-comment-alt-light text-gray-800 text-base md:text-lg inline-block"
                    }), React.createElement("span", {
                      className: "ml-2 md:ml-3 tracking-wide"
                    }, t(undefined, undefined, "feedback"))), ReactUtils.nullIf(React.createElement("div", {
                      className: "py-4 md:ml-8 text-center"
                    }, React.createElement("button", {
                          className: "bg-primary-100 flex items-center justify-center px-4 py-3 border border-dashed border-primary-500 rounded-md w-full font-semibold text-sm text-primary-600 hover:bg-white hover:text-primary-500 hover:shadow-lg hover:border-primary-300 focus:outline-none transition cursor-pointer focus:ring-2 focus:ring-offset-2 focus:ring-focusColor-500",
                          disabled: isSubmissionReviewAllowed(submissionDetails),
                          onClick: (function (param) {
                              return Curry._1(send, /* ShowAdditionalFeedbackEditor */4);
                            })
                        }, React.createElement(Icon.make, {
                              className: "if i-plus-regular"
                            }), React.createElement("p", {
                              className: "pl-2"
                            }, match$5.length !== 0 ? t(undefined, undefined, "add_another_feedback") : t(undefined, undefined, "add_feedback")))), state.additonalFeedbackEditorVisible), showFeedback(CoursesReview__OverlaySubmission.feedback(overlaySubmission))));
  }
  return [
          React.createElement(ReactHelmet.Helmet, {
                children: React.createElement("title", undefined, pageTitle(number, submissionDetails)),
                key: "helmet"
              }),
          React.createElement("div", {
                key: "submission-header"
              }, React.createElement("div", undefined, inactiveWarning(submissionDetails)), headerSection(state, state.nextSubmission, send, submissionDetails, filter, submissionId), ReactUtils.nullIf(React.createElement("div", {
                        className: "flex space-x-4 overflow-x-auto px-4 md:px-6 py-2 md:py-3 border-b bg-gray-50"
                      }, CoursesReview__SubmissionDetails.allSubmissions(submissionDetails).map(function (submission, index) {
                            return React.createElement(CoursesReview__SubmissionInfoCard.make, {
                                        submission: submission,
                                        submissionNumber: CoursesReview__SubmissionDetails.allSubmissions(submissionDetails).length - index | 0,
                                        selected: CoursesReview__SubmissionMeta.id(submission) === submissionId,
                                        filterString: url.search,
                                        key: CoursesReview__SubmissionMeta.id(submission)
                                      });
                          })), CoursesReview__SubmissionDetails.allSubmissions(submissionDetails).length === 1)),
          React.createElement(DisablingCover.make, {
                disabled: state.saving,
                containerClasses: "flex flex-col md:flex-row flex-1 space-y-6 md:space-y-0 md:overflow-y-auto",
                children: null,
                key: "submission-editor"
              }, React.createElement("div", {
                    className: "md:w-1/2 w-full bg-white md:border-r relative md:overflow-y-auto"
                  }, React.createElement("div", {
                        className: "flex items-center px-4 md:px-6 py-3 bg-white border-b sticky top-0 z-50 h-16"
                      }, React.createElement("div", {
                            className: "flex flex-1 items-center justify-between"
                          }, React.createElement("div", undefined, React.createElement("p", {
                                    className: "font-semibold"
                                  }, "Submission " + String(number)), React.createElement("p", {
                                    className: "text-gray-800 text-xs",
                                    title: DateFns.formatPreset(CoursesReview__OverlaySubmission.createdAt(overlaySubmission), undefined, true, true, undefined)
                                  }, DateFns.formatPreset(CoursesReview__OverlaySubmission.createdAt(overlaySubmission), undefined, true, undefined, undefined))), React.createElement("div", {
                                className: "text-sm"
                              }, showSubmissionStatus(status)))), React.createElement("div", {
                        className: "p-4 md:p-6"
                      }, React.createElement(SubmissionChecklistShow.make, {
                            checklist: state.checklist,
                            updateChecklistCB: updateChecklistCB,
                            pending: pending
                          })), tmp), React.createElement("div", {
                    className: "md:w-1/2 w-full md:overflow-y-auto"
                  }, tmp$1))
        ];
}

var make$3 = CoursesReview__Editor;

export {
  t ,
  str ,
  reducer ,
  CreateGradingMutation ,
  UndoGradingMutation ,
  CreateFeedbackMutation ,
  NextSubmissionQuery ,
  UnassignReviewerMutation ,
  SubmissionReportQuery ,
  unassignReviewer ,
  getNextSubmission ,
  isSubmissionReviewAllowed ,
  makeFeedback ,
  createFeedback ,
  undoGrading ,
  passed ,
  trimToOption ,
  navigationDisabled ,
  gradeSubmissionQuery ,
  inactiveWarning ,
  closeOverlay ,
  reviewNextButton ,
  headerSection ,
  updateGrading ,
  handleGradePillClick ,
  findEvaluationCriterion ,
  gradePillHeader ,
  gradePillClasses ,
  showGradePill ,
  showGrades ,
  renderGradePills ,
  badgeColorClasses ,
  gradeBadgeClasses ,
  textColor ,
  submissionReviewStatus ,
  submissionStatusIcon ,
  gradeSubmission ,
  reviewButtonDisabled ,
  computeStatus ,
  submitButtonText ,
  noteForm ,
  feedbackGenerator ,
  showFeedback ,
  showSubmissionStatus ,
  updateReviewChecklist ,
  updateReviewer ,
  pageTitle ,
  reportStatusString ,
  reportStatusIconClasses ,
  reportConclusionTimeString ,
  loadSubmissionReport ,
  reloadSubmissionReport ,
  make$3 as make,
  
}
/*  Not a pure module */
