// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as CoursesReport__Overview from "./CoursesReport__Overview.bs.js";
import * as CoursesReport__StudentOverview from "../types/CoursesReport__StudentOverview.bs.js";
import * as CoursesReport__SubmissionsList from "./CoursesReport__SubmissionsList.bs.js";

import "./CoursesReport.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesReport";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function buttonClasses(selected) {
  return "cursor-pointer flex flex-1 justify-center md:flex-auto rounded-md p-1.5 md:border-b-3 md:rounded-b-none md:border-transparent md:px-4 md:hover:bg-gray-50 md:py-2 text-sm font-semibold text-gray-800 hover:text-primary-600 hover:bg-gray-50 focus:outline-none focus:ring-inset focus:ring-2 focus:bg-gray-50 focus:ring-focusColor-500 md:focus:border-b-none md:focus:rounded-t-md " + (
          selected ? "bg-white shadow md:shadow-none rounded-md md:rounded-none md:bg-transparent md:border-b-3 hover:bg-white md:hover:bg-transparent text-primary-500 md:border-primary-500" : ""
        );
}

function reducer(state, action) {
  if (typeof action === "number") {
    if (action === /* SelectOverviewTab */0) {
      return {
              selectedTab: "Overview",
              overviewData: state.overviewData,
              submissionsData: state.submissionsData,
              submissionsFilter: state.submissionsFilter,
              sortDirection: state.sortDirection
            };
    } else {
      return {
              selectedTab: "Submissions",
              overviewData: state.overviewData,
              submissionsData: state.submissionsData,
              submissionsFilter: state.submissionsFilter,
              sortDirection: state.sortDirection
            };
    }
  }
  switch (action.TAG | 0) {
    case /* SaveOverviewData */0 :
        return {
                selectedTab: state.selectedTab,
                overviewData: action._0,
                submissionsData: state.submissionsData,
                submissionsFilter: state.submissionsFilter,
                sortDirection: state.sortDirection
              };
    case /* SaveSubmissions */1 :
        return {
                selectedTab: state.selectedTab,
                overviewData: state.overviewData,
                submissionsData: action._0,
                submissionsFilter: state.submissionsFilter,
                sortDirection: state.sortDirection
              };
    case /* UpdateLevelFilter */2 :
        var init = state.submissionsFilter;
        return {
                selectedTab: state.selectedTab,
                overviewData: state.overviewData,
                submissionsData: state.submissionsData,
                submissionsFilter: {
                  selectedLevel: action._0,
                  selectedStatus: init.selectedStatus
                },
                sortDirection: state.sortDirection
              };
    case /* UpdateStatusFilter */3 :
        var init$1 = state.submissionsFilter;
        return {
                selectedTab: state.selectedTab,
                overviewData: state.overviewData,
                submissionsData: state.submissionsData,
                submissionsFilter: {
                  selectedLevel: init$1.selectedLevel,
                  selectedStatus: action._0
                },
                sortDirection: state.sortDirection
              };
    case /* UpdateSortDirection */4 :
        return {
                selectedTab: state.selectedTab,
                overviewData: state.overviewData,
                submissionsData: state.submissionsData,
                submissionsFilter: state.submissionsFilter,
                sortDirection: action._0
              };
    
  }
}

var Raw = {};

var query = "query StudentReportOverviewQuery($studentId: ID!)  {\nstudentDetails(studentId: $studentId)  {\nevaluationCriteria  {\nid  \nname  \nmaxGrade  \npassGrade  \n}\n\nstudent  {\nlevel  {\nid  \n}\n\n}\n\ntotalTargets  \ntargetsCompleted  \ntargetsPendingReview  \ncompletedLevelIds  \nquizScores  \naverageGrades  {\nevaluationCriterionId  \naverageGrade  \n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = value.studentDetails;
  var value$2 = value$1.evaluationCriteria;
  var value$3 = value$1.student;
  var value$4 = value$3.level;
  var value$5 = value$1.completedLevelIds;
  var value$6 = value$1.quizScores;
  var value$7 = value$1.averageGrades;
  return {
          studentDetails: {
            evaluationCriteria: value$2.map(function (value) {
                  return {
                          id: value.id,
                          name: value.name,
                          maxGrade: value.maxGrade,
                          passGrade: value.passGrade
                        };
                }),
            student: {
              level: {
                id: value$4.id
              }
            },
            totalTargets: value$1.totalTargets,
            targetsCompleted: value$1.targetsCompleted,
            targetsPendingReview: value$1.targetsPendingReview,
            completedLevelIds: value$5.map(function (value) {
                  return value;
                }),
            quizScores: value$6.map(function (value) {
                  return value;
                }),
            averageGrades: value$7.map(function (value) {
                  return {
                          evaluationCriterionId: value.evaluationCriterionId,
                          averageGrade: value.averageGrade
                        };
                })
          }
        };
}

function serialize(value) {
  var value$1 = value.studentDetails;
  var value$2 = value$1.averageGrades;
  var averageGrades = value$2.map(function (value) {
        var value$1 = value.averageGrade;
        var value$2 = value.evaluationCriterionId;
        return {
                evaluationCriterionId: value$2,
                averageGrade: value$1
              };
      });
  var value$3 = value$1.quizScores;
  var quizScores = value$3.map(function (value) {
        return value;
      });
  var value$4 = value$1.completedLevelIds;
  var completedLevelIds = value$4.map(function (value) {
        return value;
      });
  var value$5 = value$1.targetsPendingReview;
  var value$6 = value$1.targetsCompleted;
  var value$7 = value$1.totalTargets;
  var value$8 = value$1.student;
  var value$9 = value$8.level;
  var value$10 = value$9.id;
  var level = {
    id: value$10
  };
  var student = {
    level: level
  };
  var value$11 = value$1.evaluationCriteria;
  var evaluationCriteria = value$11.map(function (value) {
        var value$1 = value.passGrade;
        var value$2 = value.maxGrade;
        var value$3 = value.name;
        var value$4 = value.id;
        return {
                id: value$4,
                name: value$3,
                maxGrade: value$2,
                passGrade: value$1
              };
      });
  var studentDetails = {
    evaluationCriteria: evaluationCriteria,
    student: student,
    totalTargets: value$7,
    targetsCompleted: value$6,
    targetsPendingReview: value$5,
    completedLevelIds: completedLevelIds,
    quizScores: quizScores,
    averageGrades: averageGrades
  };
  return {
          studentDetails: studentDetails
        };
}

function serializeVariables(inp) {
  return {
          studentId: inp.studentId
        };
}

function makeVariables(studentId, param) {
  return {
          studentId: studentId
        };
}

var StudentReportOverviewQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var StudentReportOverviewQuery_Graphql_error = include.Graphql_error;

var StudentReportOverviewQuery_decodeNotification = include.decodeNotification;

var StudentReportOverviewQuery_decodeObject = include.decodeObject;

var StudentReportOverviewQuery_decodeNotifications = include.decodeNotifications;

var StudentReportOverviewQuery_decodeErrors = include.decodeErrors;

var StudentReportOverviewQuery_flashNotifications = include.flashNotifications;

var StudentReportOverviewQuery_sendQuery = include.sendQuery;

var StudentReportOverviewQuery_query = include.query;

var StudentReportOverviewQuery_fetch = include.$$fetch;

var StudentReportOverviewQuery = {
  StudentReportOverviewQuery_inner: StudentReportOverviewQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: StudentReportOverviewQuery_Graphql_error,
  decodeNotification: StudentReportOverviewQuery_decodeNotification,
  decodeObject: StudentReportOverviewQuery_decodeObject,
  decodeNotifications: StudentReportOverviewQuery_decodeNotifications,
  decodeErrors: StudentReportOverviewQuery_decodeErrors,
  flashNotifications: StudentReportOverviewQuery_flashNotifications,
  sendQuery: StudentReportOverviewQuery_sendQuery,
  query: StudentReportOverviewQuery_query,
  $$fetch: StudentReportOverviewQuery_fetch,
  make: make
};

function saveOverviewData(studentId, send, data) {
  return Curry._1(send, {
              TAG: /* SaveOverviewData */0,
              _0: /* Loaded */{
                _0: CoursesReport__StudentOverview.makeFromJs(studentId, data)
              }
            });
}

function getOverviewData(studentId, send, param) {
  Curry._3(make, undefined, undefined, {
            studentId: studentId
          }).then(function (response) {
          saveOverviewData(studentId, send, response.studentDetails);
          return Promise.resolve(undefined);
        }).catch(function (param) {
        return Promise.resolve(undefined);
      });
  
}

function updateSubmissions(send, submissions) {
  return Curry._1(send, {
              TAG: /* SaveSubmissions */1,
              _0: submissions
            });
}

function CoursesReport__Root(Props) {
  var studentId = Props.studentId;
  var levels = Props.levels;
  var coaches = Props.coaches;
  var teamStudentIds = Props.teamStudentIds;
  var match = React.useReducer(reducer, {
        selectedTab: "Overview",
        overviewData: /* Unloaded */0,
        submissionsData: /* Unloaded */0,
        submissionsFilter: {
          selectedLevel: undefined,
          selectedStatus: undefined
        },
        sortDirection: "Descending"
      });
  var send = match[1];
  var state = match[0];
  React.useEffect((function () {
          return getOverviewData(studentId, send, undefined);
        }), [studentId]);
  var match$1 = state.selectedTab;
  return React.createElement("div", {
              "aria-label": "Report",
              className: "bg-gray-50 pt-9 pb-8 px-3 -mt-7 border border-transparent shadow rounded-lg",
              role: "main"
            }, React.createElement("div", {
                  className: "bg-gray-50 static"
                }, React.createElement("div", {
                      className: "max-w-3xl mx-auto"
                    }, React.createElement("div", {
                          className: "flex pt-3 mb-4 md:border-b border-gray-300"
                        }, React.createElement("div", {
                              "aria-label": "Status tabs",
                              className: "flex flex-1 md:flex-none p-1 md:p-0 space-x-1 md:space-x-0 text-center rounded-lg justify-between md:justify-start bg-gray-300 md:bg-transparent",
                              role: "tablist"
                            }, React.createElement("button", {
                                  "aria-selected": state.selectedTab === "Overview",
                                  className: buttonClasses(state.selectedTab === "Overview"),
                                  role: "tab",
                                  onClick: (function (param) {
                                      return Curry._1(send, /* SelectOverviewTab */0);
                                    })
                                }, tr(undefined, undefined, "button_overview_text")), React.createElement("button", {
                                  "aria-selected": state.selectedTab === "Submissions",
                                  className: buttonClasses(state.selectedTab === "Submissions"),
                                  role: "tab",
                                  onClick: (function (param) {
                                      return Curry._1(send, /* SelectSubmissionsTab */1);
                                    })
                                }, tr(undefined, undefined, "button_submissions_text")))))), React.createElement("div", {
                  className: ""
                }, match$1 === "Overview" ? React.createElement(CoursesReport__Overview.make, {
                        overviewData: state.overviewData,
                        levels: levels,
                        coaches: coaches
                      }) : React.createElement(CoursesReport__SubmissionsList.make, {
                        studentId: studentId,
                        levels: levels,
                        submissions: state.submissionsData,
                        updateSubmissionsCB: (function (param) {
                            return Curry._1(send, {
                                        TAG: /* SaveSubmissions */1,
                                        _0: param
                                      });
                          }),
                        teamStudentIds: teamStudentIds,
                        selectedLevel: state.submissionsFilter.selectedLevel,
                        selectedStatus: state.submissionsFilter.selectedStatus,
                        sortDirection: state.sortDirection,
                        updateSelectedLevelCB: (function (level) {
                            return Curry._1(send, {
                                        TAG: /* UpdateLevelFilter */2,
                                        _0: level
                                      });
                          }),
                        updateSelectedStatusCB: (function (status) {
                            return Curry._1(send, {
                                        TAG: /* UpdateStatusFilter */3,
                                        _0: status
                                      });
                          }),
                        updateSortDirectionCB: (function (direction) {
                            return Curry._1(send, {
                                        TAG: /* UpdateSortDirection */4,
                                        _0: direction
                                      });
                          })
                      })));
}

var make$1 = CoursesReport__Root;

export {
  str ,
  tr ,
  buttonClasses ,
  reducer ,
  StudentReportOverviewQuery ,
  saveOverviewData ,
  getOverviewData ,
  updateSubmissions ,
  make$1 as make,
  
}
/*  Not a pure module */
