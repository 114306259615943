// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../shared/utils/I18n.bs.js";
import * as React from "react";
import * as Avatar from "../../shared/Avatar.bs.js";
import * as FaIcon from "../../shared/components/FaIcon.bs.js";
import * as Dropdown from "../../shared/components/Dropdown.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as AppRouter__User from "../app_router/types/AppRouter__User.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.Layout__UserControls";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function showLink(icon, text, href) {
  return React.createElement("div", {
              key: href,
              className: "whitespace-nowrap"
            }, React.createElement("a", {
                  className: "cursor-pointer block p-3 text-xs font-semibold text-gray-900 border-b border-gray-50 bg-white hover:text-primary-500 hover:bg-gray-50",
                  href: href,
                  rel: "nofollow"
                }, React.createElement(FaIcon.make, {
                      classes: "fas fw fa-" + icon
                    }), React.createElement("span", {
                      className: "pl-2"
                    }, text)));
}

function links(param) {
  return [
          showLink("edit", t(undefined, undefined, "edit_profile"), "/user/edit"),
          showLink("power-off", t(undefined, undefined, "sign_out"), "/users/sign_out")
        ];
}

function selected(user) {
  return React.createElement("button", {
              className: "md:ml-2 h-10 w-10 rounded-full border-2 border-gray-300 hover:border-primary-500 focus:outline-none focus:border-primary-500 ",
              title: t(undefined, undefined, "user_controls")
            }, Belt_Option.mapWithDefault(AppRouter__User.avatarUrl(user), React.createElement(Avatar.make, {
                      name: AppRouter__User.name(user),
                      className: "inline-block object-contain rounded-full text-tiny"
                    }), (function (src) {
                    return React.createElement("img", {
                                className: "inline-block object-contain rounded-full text-tiny",
                                alt: AppRouter__User.name(user),
                                src: src
                              });
                  })));
}

function Layout__UserControls(Props) {
  var user = Props.user;
  var rightOpt = Props.right;
  var right = rightOpt !== undefined ? rightOpt : true;
  if (user !== undefined) {
    return React.createElement(Dropdown.make, {
                selected: selected(user),
                contents: links(undefined),
                right: right
              });
  } else {
    return null;
  }
}

var make = Layout__UserControls;

export {
  str ,
  t ,
  showLink ,
  links ,
  selected ,
  make ,
  
}
/* I18n Not a pure module */
