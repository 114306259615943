// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Dropdown2 from "../../../shared/components/Dropdown2.bs.js";
import * as AppRouter__School from "../types/AppRouter__School.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.AppRouter__Dropdown";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function handleToggle(setLinksVisible, param) {
  return Curry._1(setLinksVisible, (function (linksVisible) {
                return !linksVisible;
              }));
}

function contents(moreLinks) {
  return moreLinks.map(function (link, index) {
              return React.createElement("div", {
                          key: String(index),
                          className: ""
                        }, React.createElement("a", {
                              className: "cursor-pointer block p-3 text-xs font-semibold text-gray-900 border-b border-gray-50 bg-white hover:text-primary-500 hover:bg-gray-50 focus:outline-none focus:text-primary-500 focus:bg-gray-50",
                              href: AppRouter__School.linkUrl(link),
                              rel: "noopener",
                              target: "_blank"
                            }, AppRouter__School.linkTitle(link)));
            });
}

function selected(param) {
  return React.createElement("div", {
              key: "more-links",
              className: "ml-2 font-semibold text-sm p-4 md:px-3 md:py-2 cursor-pointer relative rounded-lg text-gray-900 hover:bg-gray-50 hover:text-primary-500",
              title: t(undefined, undefined, "show_more_links")
            }, React.createElement("span", undefined, t(undefined, undefined, "more")), React.createElement("i", {
                  className: "fas fa-caret-down ml-2"
                }));
}

function AppRouter__Dropdown(Props) {
  var links = Props.links;
  if (links.length !== 0) {
    return React.createElement(Dropdown2.make, {
                selected: selected(undefined),
                contents: contents(links),
                right: true,
                key: "links-dropdown"
              });
  } else {
    return null;
  }
}

var make = AppRouter__Dropdown;

export {
  str ,
  t ,
  handleToggle ,
  contents ,
  selected ,
  make ,
  
}
/* I18n Not a pure module */
