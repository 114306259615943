// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          levelId: Json_decode.field("levelId", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          sortIndex: Json_decode.field("sortIndex", Json_decode.$$int, json),
          milestone: Json_decode.field("milestone", Json_decode.bool, json)
        };
}

function sort(targetGroups) {
  return ArrayUtils.copyAndSort((function (x, y) {
                return x.sortIndex - y.sortIndex | 0;
              }), targetGroups);
}

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function levelId(t) {
  return t.levelId;
}

function milestone(t) {
  return t.milestone;
}

function description(t) {
  return t.description;
}

export {
  decode ,
  sort ,
  id ,
  name ,
  levelId ,
  milestone ,
  description ,
  
}
/* ArrayUtils Not a pure module */
