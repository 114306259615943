// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spread from "../../../shared/components/Spread.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as ReactUtils from "../../../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as DisablingCover from "../../../shared/components/DisablingCover.bs.js";
import * as School__InputGroupError from "../../../schools/shared/components/School__InputGroupError.bs.js";
import * as CoursesReview__ReviewChecklistItem from "../types/CoursesReview__ReviewChecklistItem.bs.js";
import * as CoursesReview__ReviewChecklistResult from "../types/CoursesReview__ReviewChecklistResult.bs.js";

import "./CoursesReview__ChecklistEditor.css"
;

var partial_arg = "components.CoursesReview__ChecklistEditor";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function str(prim) {
  return prim;
}

var Raw = {};

var query = "mutation UpdateReviewChecklistMutation($targetId: ID!, $reviewChecklist: JSON!)  {\nupdateReviewChecklist(targetId: $targetId, reviewChecklist: $reviewChecklist)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.updateReviewChecklist;
  return {
          updateReviewChecklist: {
            success: value$1.success
          }
        };
}

function serialize(value) {
  var value$1 = value.updateReviewChecklist;
  var value$2 = value$1.success;
  var updateReviewChecklist = {
    success: value$2
  };
  return {
          updateReviewChecklist: updateReviewChecklist
        };
}

function serializeVariables(inp) {
  return {
          targetId: inp.targetId,
          reviewChecklist: inp.reviewChecklist
        };
}

function makeVariables(targetId, reviewChecklist, param) {
  return {
          targetId: targetId,
          reviewChecklist: reviewChecklist
        };
}

var UpdateReviewChecklistMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include.$$fetch;

var UpdateReviewChecklistMutation_Graphql_error = include.Graphql_error;

var UpdateReviewChecklistMutation_decodeNotification = include.decodeNotification;

var UpdateReviewChecklistMutation_decodeObject = include.decodeObject;

var UpdateReviewChecklistMutation_decodeNotifications = include.decodeNotifications;

var UpdateReviewChecklistMutation_decodeErrors = include.decodeErrors;

var UpdateReviewChecklistMutation_flashNotifications = include.flashNotifications;

var UpdateReviewChecklistMutation_sendQuery = include.sendQuery;

var UpdateReviewChecklistMutation_query = include.query;

var UpdateReviewChecklistMutation_make = include.make;

var UpdateReviewChecklistMutation = {
  UpdateReviewChecklistMutation_inner: UpdateReviewChecklistMutation_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: UpdateReviewChecklistMutation_Graphql_error,
  decodeNotification: UpdateReviewChecklistMutation_decodeNotification,
  decodeObject: UpdateReviewChecklistMutation_decodeObject,
  decodeNotifications: UpdateReviewChecklistMutation_decodeNotifications,
  decodeErrors: UpdateReviewChecklistMutation_decodeErrors,
  flashNotifications: UpdateReviewChecklistMutation_flashNotifications,
  sendQuery: UpdateReviewChecklistMutation_sendQuery,
  query: UpdateReviewChecklistMutation_query,
  $$fetch: $$fetch,
  make: UpdateReviewChecklistMutation_make
};

function updateReviewChecklist(targetId, reviewChecklist, send, updateReviewChecklistCB) {
  Curry._1(send, /* UpdateSaving */1);
  var trimmedChecklist = reviewChecklist.map(CoursesReview__ReviewChecklistItem.trim);
  Curry._3($$fetch, undefined, undefined, {
          targetId: targetId,
          reviewChecklist: CoursesReview__ReviewChecklistItem.encodeArray(trimmedChecklist)
        }).then(function (response) {
        if (response.updateReviewChecklist.success) {
          Curry._1(updateReviewChecklistCB, trimmedChecklist);
        }
        Curry._1(send, /* UpdateSaving */1);
        return Promise.resolve(undefined);
      });
  
}

function updateChecklistItemTitle(itemIndex, title, checklistItem, send) {
  return Curry._1(send, {
              TAG: /* UpdateChecklistItem */2,
              _0: CoursesReview__ReviewChecklistItem.updateTitle(title, checklistItem),
              _1: itemIndex
            });
}

function updateChecklistResultTitle(itemIndex, resultIndex, title, reviewChecklistItem, resultItem, send) {
  var newReviewChecklistItem = CoursesReview__ReviewChecklistItem.updateChecklist(CoursesReview__ReviewChecklistResult.updateTitle(title, resultItem, resultIndex, CoursesReview__ReviewChecklistItem.result(reviewChecklistItem)), reviewChecklistItem);
  return Curry._1(send, {
              TAG: /* UpdateChecklistItem */2,
              _0: newReviewChecklistItem,
              _1: itemIndex
            });
}

function updateChecklistResultFeedback(itemIndex, resultIndex, feedback, reviewChecklistItem, resultItem, send) {
  var newReviewChecklistItem = CoursesReview__ReviewChecklistItem.updateChecklist(CoursesReview__ReviewChecklistResult.updateFeedback(feedback, resultItem, resultIndex, CoursesReview__ReviewChecklistItem.result(reviewChecklistItem)), reviewChecklistItem);
  return Curry._1(send, {
              TAG: /* UpdateChecklistItem */2,
              _0: newReviewChecklistItem,
              _1: itemIndex
            });
}

function addEmptyResultItem(send, reviewChecklistItem, itemIndex) {
  return Curry._1(send, {
              TAG: /* UpdateChecklistItem */2,
              _0: CoursesReview__ReviewChecklistItem.appendEmptyChecklistItem(reviewChecklistItem),
              _1: itemIndex
            });
}

function removeChecklistResult(itemIndex, resultIndex, reviewChecklistItem, send) {
  return Curry._1(send, {
              TAG: /* UpdateChecklistItem */2,
              _0: CoursesReview__ReviewChecklistItem.deleteResultItem(resultIndex, reviewChecklistItem),
              _1: itemIndex
            });
}

function initialStateForReviewChecklist(reviewChecklist) {
  if (ArrayUtils.isEmpty(reviewChecklist)) {
    return CoursesReview__ReviewChecklistItem.emptyTemplate(undefined);
  } else {
    return reviewChecklist;
  }
}

function invalidTitle(title) {
  return title.trim() === "";
}

function invalidChecklist(reviewChecklist) {
  return ArrayUtils.isNotEmpty(reviewChecklist.map(function (reviewChecklistItem) {
                    var title = CoursesReview__ReviewChecklistItem.title(reviewChecklistItem);
                    if (title.trim() === "") {
                      return true;
                    } else {
                      return ArrayUtils.isNotEmpty(CoursesReview__ReviewChecklistItem.result(reviewChecklistItem).filter(function (resultItem) {
                                      var title = CoursesReview__ReviewChecklistResult.title(resultItem);
                                      return title.trim() === "";
                                    }));
                    }
                  }).filter(function (valid) {
                  return valid;
                }));
}

function reducer(state, action) {
  if (typeof action === "number") {
    if (action === /* AddEmptyChecklistItem */0) {
      return {
              reviewChecklist: state.reviewChecklist.concat(CoursesReview__ReviewChecklistItem.empty(undefined)),
              saving: state.saving
            };
    } else {
      return {
              reviewChecklist: state.reviewChecklist,
              saving: !state.saving
            };
    }
  }
  switch (action.TAG | 0) {
    case /* SwapUpReviewChecklistItem */0 :
        return {
                reviewChecklist: ArrayUtils.swapUp(action._0, state.reviewChecklist),
                saving: state.saving
              };
    case /* SwapDownReviewChecklistItem */1 :
        return {
                reviewChecklist: ArrayUtils.swapDown(action._0, state.reviewChecklist),
                saving: state.saving
              };
    case /* UpdateChecklistItem */2 :
        return {
                reviewChecklist: CoursesReview__ReviewChecklistItem.replace(action._0, action._1, state.reviewChecklist),
                saving: state.saving
              };
    case /* RemoveChecklistItem */3 :
        var itemIndex = action._0;
        return {
                reviewChecklist: state.reviewChecklist.filter(function (_el, i) {
                      return i !== itemIndex;
                    }),
                saving: state.saving
              };
    
  }
}

function controlIcon(icon, title, hiddenOpt, handler) {
  var hidden = hiddenOpt !== undefined ? hiddenOpt : false;
  return ReactUtils.nullIf(React.createElement("button", {
                  "aria-label": title,
                  className: "px-2 py-1 rounded text-sm bg-gray-100 text-gray-500 hover:bg-gray-300 hover:text-gray-900 overflow-hidden focus:outline-none focus:bg-gray-300 focus:text-gray-900",
                  title: title,
                  disabled: hidden,
                  onClick: handler
                }, React.createElement("i", {
                      className: "fas fa-fw " + icon
                    })), hidden);
}

function CoursesReview__ChecklistEditor(Props) {
  var reviewChecklist = Props.reviewChecklist;
  var updateReviewChecklistCB = Props.updateReviewChecklistCB;
  var closeEditModeCB = Props.closeEditModeCB;
  var targetId = Props.targetId;
  var match = React.useReducer(reducer, {
        reviewChecklist: initialStateForReviewChecklist(reviewChecklist),
        saving: false
      });
  var send = match[1];
  var state = match[0];
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex items-center px-4 md:px-6 py-3 bg-white border-b sticky top-0 z-50 h-16"
                }, React.createElement("div", {
                      className: "flex flex-1 items-center justify-between"
                    }, React.createElement("h5", {
                          className: "font-medium flex items-center tracking-wide"
                        }, ArrayUtils.isEmpty(reviewChecklist) ? t(undefined, undefined, "create_review_checklist") : t(undefined, undefined, "edit_review_checklist")))), React.createElement(DisablingCover.make, {
                  disabled: state.saving,
                  children: null
                }, React.createElement("div", {
                      className: "p-4 md:p-6 relative"
                    }, React.createElement("div", undefined, state.reviewChecklist.map(function (reviewChecklistItem, itemIndex) {
                              var title = CoursesReview__ReviewChecklistItem.title(reviewChecklistItem);
                              return React.createElement(Spread.make, {
                                          props: {
                                            "data-checklist-item": String(itemIndex)
                                          },
                                          children: React.createElement("div", {
                                                className: "flex items-start gap-1"
                                              }, React.createElement("div", {
                                                    key: String(itemIndex),
                                                    "aria-label": "checklist-item-" + String(itemIndex),
                                                    className: "p-3 md:p-5 mb-5 flex flex-col flex-1 bg-gray-100 rounded-lg"
                                                  }, React.createElement("div", {
                                                        className: "flex"
                                                      }, React.createElement("div", {
                                                            className: "w-full"
                                                          }, React.createElement("input", {
                                                                className: "checklist-editor__checklist-item-title h-11 text-sm focus:outline-none focus:bg-white focus:border-primary-300",
                                                                id: "checklist_" + String(itemIndex) + "_title",
                                                                placeholder: t(undefined, undefined, "checklist_title.placeholder"),
                                                                type: "text",
                                                                value: CoursesReview__ReviewChecklistItem.title(reviewChecklistItem),
                                                                onChange: (function ($$event) {
                                                                    return updateChecklistItemTitle(itemIndex, $$event.target.value, reviewChecklistItem, send);
                                                                  })
                                                              }), React.createElement(School__InputGroupError.make, {
                                                                message: t(undefined, undefined, "checklist_title.error_message"),
                                                                active: title.trim() === ""
                                                              }))), React.createElement("div", undefined, CoursesReview__ReviewChecklistItem.result(reviewChecklistItem).map(function (resultItem, resultIndex) {
                                                            var feedback = Belt_Option.getWithDefault(CoursesReview__ReviewChecklistResult.feedback(resultItem), "");
                                                            var title = CoursesReview__ReviewChecklistResult.title(resultItem);
                                                            return React.createElement(Spread.make, {
                                                                        props: {
                                                                          "data-result-item": String(resultIndex)
                                                                        },
                                                                        children: React.createElement("div", {
                                                                              className: "pl-2 md:pl-4 mt-2"
                                                                            }, React.createElement("div", {
                                                                                  className: "flex"
                                                                                }, React.createElement("label", {
                                                                                      className: "shrink-0 rounded border border-gray-300 bg-gray-50 w-4 h-4 mr-2 mt-3 cursor-not-allowed",
                                                                                      title: t(undefined, undefined, "disabled")
                                                                                    }), React.createElement("div", {
                                                                                      className: "w-full bg-gray-50 relative"
                                                                                    }, React.createElement("div", {
                                                                                          className: "flex justify-between gap-2 bg-white border border-gray-300 border-b-transparent rounded-t focus-within:outline-none focus-within:bg-white focus-within:border-primary-300"
                                                                                        }, React.createElement("input", {
                                                                                              className: "checklist-editor__checklist-result-item-title border-none h-10 pr-0 focus:outline-none",
                                                                                              id: "result_" + String(itemIndex) + String(resultIndex) + "_title",
                                                                                              placeholder: t(undefined, undefined, "checklist_item_title.placeholder"),
                                                                                              type: "text",
                                                                                              value: CoursesReview__ReviewChecklistResult.title(resultItem),
                                                                                              onChange: (function ($$event) {
                                                                                                  return updateChecklistResultTitle(itemIndex, resultIndex, $$event.target.value, reviewChecklistItem, resultItem, send);
                                                                                                })
                                                                                            }), React.createElement("div", {
                                                                                              className: "flex h-10 mr-1 space-x-1 items-center justify-center"
                                                                                            }, controlIcon("fa-arrow-up", t(undefined, undefined, "checklist_item_title.move_up_button_title"), resultIndex <= 0, (function (param) {
                                                                                                    return Curry._1(send, {
                                                                                                                TAG: /* UpdateChecklistItem */2,
                                                                                                                _0: CoursesReview__ReviewChecklistItem.moveResultItemUp(resultIndex, reviewChecklistItem),
                                                                                                                _1: itemIndex
                                                                                                              });
                                                                                                  })), controlIcon("fa-arrow-down", t(undefined, undefined, "checklist_item_title.move_down_button_title"), resultIndex === (CoursesReview__ReviewChecklistItem.result(reviewChecklistItem).length - 1 | 0), (function (param) {
                                                                                                    return Curry._1(send, {
                                                                                                                TAG: /* UpdateChecklistItem */2,
                                                                                                                _0: CoursesReview__ReviewChecklistItem.moveResultItemDown(resultIndex, reviewChecklistItem),
                                                                                                                _1: itemIndex
                                                                                                              });
                                                                                                  })), controlIcon("fa-trash-alt", t(undefined, undefined, "checklist_item_title.remove_button_title"), undefined, (function (param) {
                                                                                                    return removeChecklistResult(itemIndex, resultIndex, reviewChecklistItem, send);
                                                                                                  })))), React.createElement("textarea", {
                                                                                          className: "appearance-none border border-gray-300 bg-transparent rounded-b text-sm align-top py-2 px-4 leading-relaxed w-full focus:outline-none focus:bg-white focus:border-primary-300",
                                                                                          id: "result_" + String(itemIndex) + String(resultIndex) + "_feedback",
                                                                                          cols: 33,
                                                                                          placeholder: t(undefined, undefined, "checklist_item_description.placeholder"),
                                                                                          rows: 2,
                                                                                          type: "text",
                                                                                          value: feedback,
                                                                                          onChange: (function ($$event) {
                                                                                              return updateChecklistResultFeedback(itemIndex, resultIndex, $$event.target.value, reviewChecklistItem, resultItem, send);
                                                                                            })
                                                                                        }), React.createElement(School__InputGroupError.make, {
                                                                                          message: t(undefined, undefined, "checklist_item_description.error_message"),
                                                                                          active: title.trim() === ""
                                                                                        })))),
                                                                        key: String(itemIndex) + String(resultIndex)
                                                                      });
                                                          }), React.createElement("button", {
                                                            className: "checklist-editor__add-result-btn ml-2 md:ml-4 mt-3 flex items-center focus:outline-none",
                                                            onClick: (function (param) {
                                                                return addEmptyResultItem(send, reviewChecklistItem, itemIndex);
                                                              })
                                                          }, React.createElement("span", {
                                                                className: "checklist-editor__add-result-btn-check shrink-0 rounded border border-gray-300 bg-gray-50 w-4 h-4 mr-2",
                                                                title: t(undefined, undefined, "add_result")
                                                              }), React.createElement("span", {
                                                                className: "checklist-editor__add-result-btn-text flex items-center text-sm font-semibold bg-gray-50 px-3 py-1 rounded border border-dashed border-gray-600"
                                                              }, React.createElement("i", {
                                                                    className: "fas fa-plus text-xs mr-2"
                                                                  }), t(undefined, undefined, "add_result"))))), React.createElement("div", {
                                                    className: "border border-gray-300 bg-white divide-y divide-gray-300 rounded flex flex-col text-xs sticky top-0 overflow-hidden"
                                                  }, controlIcon("fa-arrow-up", t(undefined, undefined, "checklist_title.move_up_button_title"), itemIndex <= 0, (function (param) {
                                                          return Curry._1(send, {
                                                                      TAG: /* SwapUpReviewChecklistItem */0,
                                                                      _0: itemIndex
                                                                    });
                                                        })), controlIcon("fa-arrow-down", t(undefined, undefined, "checklist_title.move_down_button_title"), itemIndex === (state.reviewChecklist.length - 1 | 0), (function (param) {
                                                          return Curry._1(send, {
                                                                      TAG: /* SwapDownReviewChecklistItem */1,
                                                                      _0: itemIndex
                                                                    });
                                                        })), controlIcon("fa-trash-alt", t(undefined, undefined, "checklist_title.remove_button_title"), undefined, (function (param) {
                                                          return Curry._1(send, {
                                                                      TAG: /* RemoveChecklistItem */3,
                                                                      _0: itemIndex
                                                                    });
                                                        })))),
                                          key: String(itemIndex)
                                        });
                            }), React.createElement("div", undefined, React.createElement("button", {
                                  "aria-label": t(undefined, undefined, "add_checklist_item"),
                                  className: "flex items-center text-sm font-semibold bg-white rounded-md border border-dashed border-gray-600 w-full hover:text-primary-500 hover:bg-white hover:border-primary-500 hover:shadow-md focus:outline-none focus:text-primary-500 focus:bg-white focus:border-primary-500 focus:shadow-md",
                                  onClick: (function (param) {
                                      return Curry._1(send, /* AddEmptyChecklistItem */0);
                                    })
                                }, React.createElement("span", {
                                      className: "bg-gray-100 py-2 w-10"
                                    }, React.createElement("i", {
                                          className: "fas fa-plus text-sm"
                                        })), React.createElement("span", {
                                      className: "px-3 py-2"
                                    }, t(undefined, undefined, "add_checklist_item")))))), React.createElement("div", {
                      className: "flex bg-gray-50 border-t flex-row-reverse sticky bottom-0 px-4 py-2 md:py-4"
                    }, React.createElement("button", {
                          className: "btn btn-success",
                          disabled: state.saving || invalidChecklist(state.reviewChecklist),
                          onClick: (function (param) {
                              return updateReviewChecklist(targetId, state.reviewChecklist, send, updateReviewChecklistCB);
                            })
                        }, t(undefined, undefined, "save_checklist")), React.createElement("button", {
                          className: "btn btn-subtle mr-4",
                          onClick: (function (param) {
                              return Curry._1(closeEditModeCB, undefined);
                            })
                        }, t(undefined, undefined, "cancel")))));
}

var make = CoursesReview__ChecklistEditor;

export {
  t ,
  str ,
  UpdateReviewChecklistMutation ,
  updateReviewChecklist ,
  updateChecklistItemTitle ,
  updateChecklistResultTitle ,
  updateChecklistResultFeedback ,
  addEmptyResultItem ,
  removeChecklistResult ,
  initialStateForReviewChecklist ,
  invalidTitle ,
  invalidChecklist ,
  reducer ,
  controlIcon ,
  make ,
  
}
/*  Not a pure module */
