// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../utils/I18n.bs.js";
import * as Icon from "../Icon.bs.js";
import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Dropdown from "./Dropdown.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.Sorter";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function Make(Sortable) {
  var dropdown = function (criteria, selectedCriterion, onCriterionChange) {
    var selectedForDropdown = React.createElement("button", {
          "aria-label": t(undefined, undefined, "order_by") + " " + Curry._1(Sortable.criterion, selectedCriterion),
          className: "flex w-full items-center justify-between leading-relaxed font-semibold bg-white border border-gray-300 rounded focus:outline-none px-2 md:px-3 py-1 md:py-2 focus:ring-2 focus:ring-inset focus:ring-focusColor-500 ",
          title: t(undefined, undefined, "order_by") + " " + Curry._1(Sortable.criterion, selectedCriterion)
        }, React.createElement("span", undefined, Curry._1(Sortable.criterion, selectedCriterion)), React.createElement("i", {
              className: "fas fa-caret-down ml-3"
            }));
    var dropDownContents = $$Array.map((function (criterion) {
            return React.createElement("button", {
                        key: Curry._1(Sortable.criterion, criterion),
                        "aria-label": t(undefined, undefined, "order_by") + " " + Curry._1(Sortable.criterion, criterion),
                        className: "inline-flex items-center w-full font-semibold whitespace-nowrap text-xs p-3 text-left focus:outline-none focus:ring-2 focus:ring-inset focus:ring-focusColor-500 ",
                        title: t(undefined, undefined, "order_by") + " " + Curry._1(Sortable.criterion, criterion),
                        onClick: (function (param) {
                            return Curry._1(onCriterionChange, criterion);
                          })
                      }, React.createElement(Icon.make, {
                            className: "if i-clock-regular text-sm if-fw text-gray-600"
                          }), React.createElement("span", {
                            className: "ml-2"
                          }, Curry._1(Sortable.criterion, criterion)));
          }), criteria.filter(function (criterion) {
              return Curry._1(Sortable.criterion, criterion) !== Curry._1(Sortable.criterion, selectedCriterion);
            }));
    return React.createElement(Dropdown.make, {
                selected: selectedForDropdown,
                contents: dropDownContents
              });
  };
  var directionIconClasses = function (criterionType, direction) {
    if (criterionType === "Number") {
      if (direction === "Descending") {
        return "if i-sort-numeric-descending-regular w-4 if-fw";
      } else {
        return "if i-sort-numeric-ascending-regular w-4 if-fw";
      }
    } else if (direction === "Descending") {
      return "if i-sort-alpha-descending-regular w-4 if-fw";
    } else {
      return "if i-sort-alpha-ascending-regular w-4 if-fw";
    }
  };
  var Sorter$Make = function (Props) {
    var criteria = Props.criteria;
    var selectedCriterion = Props.selectedCriterion;
    var direction = Props.direction;
    var onDirectionChange = Props.onDirectionChange;
    var onCriterionChange = Props.onCriterionChange;
    return React.createElement("div", {
                className: "flex"
              }, criteria.length > 1 ? dropdown(criteria, selectedCriterion, onCriterionChange) : React.createElement("div", {
                      className: "inline-flex flex-1 md:flex-auto items-center bg-gray-50 leading-relaxed font-semibold text-gray-600 border border-gray-300 rounded px-3 py-1 md:py-2 text-sm ",
                      title: t(undefined, undefined, "order_by") + " " + Curry._1(Sortable.criterion, selectedCriterion)
                    }, React.createElement("div", undefined, Curry._1(Sortable.criterion, selectedCriterion))), React.createElement("span", {
                    className: "flex ml-1"
                  }, React.createElement("button", {
                        "aria-label": t(undefined, undefined, "toggle_sort"),
                        className: "bg-white w-10 px-2 py-1 rounded border border-gray-300 text-gray-800 hover:bg-gray-50 hover:text-primary-500 focus:ring-2 focus:ring-inset focus:ring-focusColor-500 ",
                        title: "toggle-sort-order",
                        onClick: (function (param) {
                            return Curry._1(onDirectionChange, direction === "Descending" ? "Ascending" : "Descending");
                          })
                      }, React.createElement(Icon.make, {
                            className: directionIconClasses(Curry._1(Sortable.criterionType, selectedCriterion), direction)
                          }))));
  };
  return {
          dropdown: dropdown,
          directionIconClasses: directionIconClasses,
          make: Sorter$Make
        };
}

export {
  str ,
  t ,
  Make ,
  
}
/* I18n Not a pure module */
