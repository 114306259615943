// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Link from "../../../shared/components/Link.bs.js";
import * as React from "react";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as ReactUtils from "../../../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import PendingEmptySvg from "../images/pending-empty.svg";
import * as CoursesReview__IndexSubmission from "../types/CoursesReview__IndexSubmission.bs.js";
import ReviewedEmptySvg from "../../shared/images/reviewed-empty.svg";

var reviewedEmptyImage = ReviewedEmptySvg;

var pendingEmptyImage = PendingEmptySvg;

var partial_arg = "components.CoursesReview__SubmissionCard";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function str(prim) {
  return prim;
}

function submissionStatus(submission) {
  var match = CoursesReview__IndexSubmission.pendingReview(submission) ? [
      "shrink-0 leading-normal font-semibold px-2 py-px rounded bg-orange-100 text-orange-800",
      CoursesReview__IndexSubmission.timeDistance(submission)
    ] : (
      CoursesReview__IndexSubmission.failed(submission) ? [
          "shrink-0 leading-normal font-semibold px-2 py-px rounded bg-red-100 text-red-800",
          t(undefined, undefined, "rejected")
        ] : [
          "shrink-0 leading-normal font-semibold px-2 py-px rounded bg-green-100 text-green-800",
          t(undefined, undefined, "completed")
        ]
    );
  return React.createElement("div", {
              className: match[0]
            }, match[1]);
}

function feedbackSentNotice(feedbackSent) {
  return ReactUtils.nullUnless(React.createElement("div", {
                  className: "bg-primary-100 text-primary-600 border border-transparent shrink-0 leading-normal font-semibold px-2 py-px rounded mr-3"
                }, t(undefined, undefined, "feedback_sent")), feedbackSent);
}

function submissionCardClasses(submission) {
  return "flex flex-col lg:flex-row items-start lg:items-center justify-between bg-white border-l-3 p-3 lg:py-6 lg:px-5 mb-4 cursor-pointer rounded-lg shadow hover:border-primary-500 hover:text-primary-500 hover:shadow-md focus:outline-none focus:border-primary-500 focus:ring-2 focus:ring-inset focus:ring-focusColor-500 " + (
          CoursesReview__IndexSubmission.pendingReview(submission) ? "border-orange-400" : (
              CoursesReview__IndexSubmission.failed(submission) ? "border-red-500" : "border-green-500"
            )
        );
}

function showSubmission(submissions, filterString) {
  return React.createElement("div", {
              id: "submissions"
            }, submissions.map(function (submission) {
                  var name = CoursesReview__IndexSubmission.teamName(submission);
                  var reviewer = CoursesReview__IndexSubmission.reviewer(submission);
                  return React.createElement(Link.make, {
                              href: "/submissions/" + CoursesReview__IndexSubmission.id(submission) + "/review?" + filterString,
                              ariaLabel: "Submission " + CoursesReview__IndexSubmission.id(submission) + ", Submitted by: " + CoursesReview__IndexSubmission.userNames(submission),
                              className: submissionCardClasses(submission),
                              children: null,
                              props: {
                                "data-submission-id": CoursesReview__IndexSubmission.id(submission)
                              },
                              key: CoursesReview__IndexSubmission.id(submission)
                            }, React.createElement("div", {
                                  className: "w-full lg:w-8/12"
                                }, React.createElement("div", {
                                      className: "block text-sm lg:pr-4"
                                    }, React.createElement("span", {
                                          className: "bg-gray-300 text-xs font-semibold px-2 py-px rounded"
                                        }, t(undefined, undefined, "level") + String(CoursesReview__IndexSubmission.levelNumber(submission))), React.createElement("span", {
                                          className: "ml-2 font-semibold text-sm md:text-base"
                                        }, CoursesReview__IndexSubmission.title(submission))), React.createElement("div", {
                                      className: "mt-1 ml-px text-xs text-gray-900"
                                    }, name !== undefined ? React.createElement("span", undefined, t(undefined, undefined, "submitted_by_team"), React.createElement("span", {
                                                className: "font-semibold"
                                              }, name)) : React.createElement("span", undefined, t(undefined, undefined, "submitted_by"), React.createElement("span", {
                                                className: "font-semibold"
                                              }, CoursesReview__IndexSubmission.userNames(submission))), React.createElement("span", {
                                          className: "ml-1",
                                          title: DateFns.formatPreset(CoursesReview__IndexSubmission.createdAt(submission), undefined, true, true, undefined)
                                        }, t([[
                                                "created_at",
                                                CoursesReview__IndexSubmission.createdAtPretty(submission)
                                              ]], undefined, "created_at")), reviewer !== undefined ? React.createElement("span", {
                                            className: "ml-1"
                                          }, t(undefined, undefined, "assigned_to"), React.createElement("span", {
                                                className: "ml-1 font-semibold"
                                              }, CoursesReview__IndexSubmission.reviewerName(reviewer)), React.createElement("span", {
                                                className: "text-xs text-gray-800 ml-1",
                                                title: DateFns.formatPreset(CoursesReview__IndexSubmission.reviewerAssignedAt(reviewer), undefined, true, true, undefined)
                                              }, DateFns.formatDistanceToNow(CoursesReview__IndexSubmission.reviewerAssignedAt(reviewer), undefined, true, undefined), ".")) : null)), React.createElement("div", {
                                  className: "w-auto lg:w-4/12 text-xs flex justify-end mt-2 lg:mt-0"
                                }, feedbackSentNotice(CoursesReview__IndexSubmission.feedbackSent(submission)), submissionStatus(submission)));
                }));
}

function CoursesReview__SubmissionCard(Props) {
  var submissions = Props.submissions;
  var selectedTab = Props.selectedTab;
  var filterString = Props.filterString;
  var imageSrc = Belt_Option.mapWithDefault(selectedTab, pendingEmptyImage, (function (t) {
          if (t === "Reviewed") {
            return reviewedEmptyImage;
          } else {
            return pendingEmptyImage;
          }
        }));
  if (ArrayUtils.isEmpty(submissions)) {
    return React.createElement("div", {
                className: "course-review__submissions-empty text-lg font-semibold text-center py-4"
              }, React.createElement("h5", {
                    className: "py-4 mt-4 bg-gray-50 text-gray-800 font-semibold"
                  }, t(undefined, undefined, "no_submissions_found")), React.createElement("img", {
                    className: "w-3/4 md:w-1/2 mx-auto mt-2",
                    src: imageSrc
                  }));
  } else {
    return showSubmission(submissions, filterString);
  }
}

var make = CoursesReview__SubmissionCard;

export {
  reviewedEmptyImage ,
  pendingEmptyImage ,
  t ,
  str ,
  submissionStatus ,
  feedbackSentNotice ,
  submissionCardClasses ,
  showSubmission ,
  make ,
  
}
/* reviewedEmptyImage Not a pure module */
