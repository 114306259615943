// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../shared/utils/I18n.bs.js";
import * as Link from "../shared/components/Link.bs.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Avatar from "../shared/Avatar.bs.js";
import * as PfIcon from "../packages/pf-icon/src/PfIcon.bs.js";
import * as ArrayUtils from "../shared/utils/ArrayUtils.bs.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as StringUtils from "../shared/utils/StringUtils.bs.js";
import * as Belt_SetString from "../../../node_modules/rescript/lib/es6/belt_SetString.js";
import * as CoachesIndex__Coach from "./types/CoachesIndex__Coach.bs.js";
import * as MultiselectDropdown from "../packages/multiselect-dropdown/src/components/MultiselectDropdown.bs.js";
import * as RescriptReactRouter from "../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as CoachesIndex__Course from "./types/CoachesIndex__Course.bs.js";

import "./CoachesIndex__Root.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoachesIndex__Root";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function label(t) {
  var tmp;
  tmp = t.TAG === /* Course */0 ? tr(undefined, undefined, "filter_label_teaches_course") : tr(undefined, undefined, "filter_label_name_like");
  return tmp;
}

function value(t) {
  if (t.TAG === /* Course */0) {
    return CoachesIndex__Course.name(t._0);
  } else {
    return t._0;
  }
}

function searchString(t) {
  if (t.TAG === /* Course */0) {
    return tr([[
                  "name",
                  CoachesIndex__Course.name(t._0)
                ]], undefined, "filter_search_string_course");
  } else {
    return t._0;
  }
}

function color(_t) {
  return "gray";
}

function makeCourse(course) {
  return {
          TAG: /* Course */0,
          _0: course
        };
}

function makeSearch(input) {
  return {
          TAG: /* Search */1,
          _0: input
        };
}

var Selectable = {
  label: label,
  value: value,
  searchString: searchString,
  color: color,
  makeCourse: makeCourse,
  makeSearch: makeSearch
};

var Multiselect = MultiselectDropdown.Make(Selectable);

function computeInitialState(courses, studentInCourseIds) {
  var filterCourseIds = courses.filter(function (course) {
          return studentInCourseIds.includes(CoachesIndex__Course.id(course));
        }).map(CoachesIndex__Course.id);
  return {
          filterInput: "",
          filterSearch: "",
          filterCourseIds: Belt_SetString.fromArray(filterCourseIds)
        };
}

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* SelectFilter */0 :
        var selectable = action._0;
        if (selectable.TAG === /* Course */0) {
          return {
                  filterInput: "",
                  filterSearch: state.filterSearch,
                  filterCourseIds: Belt_SetString.add(state.filterCourseIds, CoachesIndex__Course.id(selectable._0))
                };
        } else {
          return {
                  filterInput: "",
                  filterSearch: selectable._0.trim(),
                  filterCourseIds: state.filterCourseIds
                };
        }
    case /* DeselectFilter */1 :
        var selectable$1 = action._0;
        if (selectable$1.TAG === /* Course */0) {
          return {
                  filterInput: state.filterInput,
                  filterSearch: state.filterSearch,
                  filterCourseIds: Belt_SetString.remove(state.filterCourseIds, CoachesIndex__Course.id(selectable$1._0))
                };
        } else {
          return {
                  filterInput: state.filterInput,
                  filterSearch: "",
                  filterCourseIds: state.filterCourseIds
                };
        }
    case /* UpdateFilterInput */2 :
        return {
                filterInput: action._0,
                filterSearch: state.filterSearch,
                filterCourseIds: state.filterCourseIds
              };
    
  }
}

function unselected(input, filterCourseIds, courses) {
  var unselectedCourses = courses.filter(function (course) {
          return !Belt_SetString.has(filterCourseIds, CoachesIndex__Course.id(course));
        }).map(makeCourse);
  var trimmedInput = input.trim();
  var search = trimmedInput.length > 0 ? [{
        TAG: /* Search */1,
        _0: trimmedInput
      }] : [];
  return unselectedCourses.concat(search);
}

function connectLink(href) {
  return React.createElement("a", {
              className: "block flex-1 px-3 py-2 text-center text-sm font-semibold hover:bg-gray-50 hover:text-primary-500",
              href: href,
              target: "_blank"
            }, tr(undefined, undefined, "button_connect"));
}

function overlay(coach, about) {
  var src = CoachesIndex__Coach.avatarUrl(coach);
  var href = CoachesIndex__Coach.connectLink(coach);
  return React.createElement("div", {
              className: "fixed z-30 inset-0 overflow-y-auto"
            }, React.createElement("div", {
                  className: "flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
                }, React.createElement("div", {
                      className: "fixed inset-0"
                    }, React.createElement("div", {
                          className: "absolute inset-0 bg-gray-900 opacity-75"
                        })), React.createElement("span", {
                      className: "hidden sm:inline-block sm:align-middle sm:h-screen"
                    }), React.createElement("div", {
                      "aria-modal": true,
                      "aria-labelledby": "modal-headline",
                      className: "inline-block relative bg-white rounded-lg shadow-lg align-bottom mt-16 sm:mb-8  sm:align-middle sm:max-w-lg sm:w-full",
                      role: "dialog"
                    }, React.createElement("div", {
                          className: "block absolute top-0 left-0 -mt-12"
                        }, React.createElement(Link.make, {
                              href: "/coaches",
                              ariaLabel: "Close",
                              className: "flex justify-center items-center bg-gray-900 rounded-full p-2 w-10 h-10 text-gray-400 hover:opacity-75 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150",
                              children: React.createElement(PfIcon.make, {
                                    className: "if i-times-regular if-fw text-2xl"
                                  })
                            })), React.createElement("div", {
                          className: "pb-5"
                        }, React.createElement("div", {
                              className: "faculty-card__avatar-container bg-gray-50 px-2 py-10 rounded-t-lg"
                            }, src !== undefined ? React.createElement("img", {
                                    className: "mx-auto w-40 h-40 -mb-18 border-4 border-gray-300 rounded-full object-cover",
                                    alt: "Avatar of " + CoachesIndex__Coach.name(coach),
                                    src: src
                                  }) : React.createElement(Avatar.make, {
                                    name: CoachesIndex__Coach.name(coach),
                                    className: "mx-auto w-40 h-40 -mb-18 border-4 border-gray-300 rounded-full object-cover"
                                  })), React.createElement("div", {
                              className: "py-3 mt-8"
                            }, React.createElement("p", {
                                  className: "text-sm text-center font-semibold"
                                }, CoachesIndex__Coach.name(coach)), React.createElement("p", {
                                  className: "text-center text-xs text-gray-800 pt-1"
                                }, CoachesIndex__Coach.fullTitle(coach))), React.createElement("p", {
                              className: "text-center text-sm px-6"
                            }, about), href !== undefined ? React.createElement("div", {
                                className: "mt-3 text-center px-4 pb-4 sm:px-6 sm:pb-6"
                              }, React.createElement("div", {
                                    className: "inline-flex overflow-hidden border rounded border-primary-500 text-primary-500"
                                  }, connectLink(href))) : null))));
}

function card(coach) {
  var src = CoachesIndex__Coach.avatarUrl(coach);
  var _about = CoachesIndex__Coach.about(coach);
  var href = CoachesIndex__Coach.connectLink(coach);
  return React.createElement("div", {
              key: CoachesIndex__Coach.id(coach),
              "aria-label": "Coach " + CoachesIndex__Coach.name(coach),
              className: "flex flex-col justify-between bg-white rounded-lg shadow-md pt-8"
            }, React.createElement("div", {
                  className: "px-6"
                }, src !== undefined ? React.createElement("img", {
                        className: "mx-auto w-40 h-40 border-4 border-gray-50 rounded-full object-cover",
                        alt: "Coach's Avatar",
                        src: src
                      }) : React.createElement(Avatar.make, {
                        name: CoachesIndex__Coach.name(coach),
                        className: "mx-auto w-40 h-40 border-4 border-gray-50 rounded-full object-cover"
                      }), React.createElement("div", {
                      className: "py-3"
                    }, React.createElement("p", {
                          className: "text-sm text-center font-semibold"
                        }, CoachesIndex__Coach.name(coach)), React.createElement("p", {
                          className: "text-center text-xs text-gray-800 pt-1"
                        }, CoachesIndex__Coach.fullTitle(coach)))), React.createElement("div", {
                  className: "flex justify-between divide-x border-t divide-gray-400 border-gray-300 rounded-b-lg overflow-hidden"
                }, _about !== undefined ? React.createElement("div", {
                        className: "block flex-1"
                      }, React.createElement(Link.make, {
                            href: "/coaches/" + (CoachesIndex__Coach.id(coach) + ("/" + StringUtils.parameterize(CoachesIndex__Coach.name(coach)))),
                            className: "block w-full px-3 py-2 text-center text-sm font-semibold hover:bg-gray-50 hover:text-primary-500",
                            children: tr(undefined, undefined, "button_about")
                          })) : null, href !== undefined ? React.createElement("div", {
                        className: "flex-1"
                      }, connectLink(href)) : null));
}

function applyFilter(coaches, filterSearch, filterCourseIds) {
  var coaches$1 = Belt_SetString.isEmpty(filterCourseIds) ? coaches : coaches.filter(function (coach) {
          return !Belt_SetString.isEmpty(Belt_SetString.intersect(Belt_SetString.fromArray(CoachesIndex__Coach.courseIds(coach)), filterCourseIds));
        });
  if (filterSearch === "") {
    return coaches$1;
  } else {
    return coaches$1.filter(function (coach) {
                return StringUtils.includes(undefined, filterSearch, CoachesIndex__Coach.name(coach));
              });
  }
}

function selected(courses, filterSearch, filterCourseIds) {
  var search = filterSearch === "" ? [] : [{
        TAG: /* Search */1,
        _0: filterSearch
      }];
  var selectedCourses = courses.filter(function (course) {
          return Belt_SetString.has(filterCourseIds, CoachesIndex__Course.id(course));
        }).map(makeCourse);
  return search.concat(selectedCourses);
}

function CoachesIndex__Root(Props) {
  var subheading = Props.subheading;
  var coaches = Props.coaches;
  var courses = Props.courses;
  var studentInCourseIds = Props.studentInCourseIds;
  var match = React.useReducer(reducer, studentInCourseIds, (function (param) {
          return computeInitialState(courses, param);
        }));
  var send = match[1];
  var state = match[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var _otherPaths = url.path;
  var selectedCoachOverlay;
  if (_otherPaths && _otherPaths.hd === "coaches") {
    var match$1 = _otherPaths.tl;
    selectedCoachOverlay = match$1 ? Belt_Option.mapWithDefault(Belt_Option.flatMap(StringUtils.paramToId(match$1.hd), (function (coachId) {
                  return Caml_option.undefined_to_opt(coaches.find(function (coach) {
                                  return CoachesIndex__Coach.id(coach) === coachId;
                                }));
                })), null, (function (coach) {
              var about = CoachesIndex__Coach.about(coach);
              if (about !== undefined) {
                return overlay(coach, about);
              } else {
                return null;
              }
            })) : null;
  } else {
    selectedCoachOverlay = null;
  }
  var filteredCoaches = applyFilter(coaches, state.filterSearch, state.filterCourseIds);
  var coachesToShow = ArrayUtils.isEmpty(filteredCoaches) ? coaches : filteredCoaches;
  return React.createElement("div", undefined, selectedCoachOverlay, React.createElement("div", {
                  className: "max-w-5xl mx-auto px-4"
                }, React.createElement("h1", {
                      className: "text-4xl text-center mt-3"
                    }, tr(undefined, undefined, "heading")), subheading !== undefined ? React.createElement("p", {
                        className: "text-center"
                      }, subheading) : null, React.createElement("label", {
                      className: "block text-xs font-semibold uppercase mt-4",
                      htmlFor: "filter"
                    }, tr(undefined, undefined, "filter_input_label")), React.createElement(Multiselect.make, {
                      id: "filter",
                      placeholder: tr(undefined, undefined, "filter_input_placeholder"),
                      onChange: (function (filterInput) {
                          return Curry._1(send, {
                                      TAG: /* UpdateFilterInput */2,
                                      _0: filterInput
                                    });
                        }),
                      value: state.filterInput,
                      unselected: unselected(state.filterInput, state.filterCourseIds, courses),
                      selected: selected(courses, state.filterSearch, state.filterCourseIds),
                      onSelect: (function (selectable) {
                          return Curry._1(send, {
                                      TAG: /* SelectFilter */0,
                                      _0: selectable
                                    });
                        }),
                      onDeselect: (function (selectable) {
                          return Curry._1(send, {
                                      TAG: /* DeselectFilter */1,
                                      _0: selectable
                                    });
                        })
                    }), ArrayUtils.isEmpty(filteredCoaches) ? React.createElement("div", {
                        className: "text-xs border rounded bg-blue-100 p-2 mt-2"
                      }, tr(undefined, undefined, "filter_result_empty")) : null, React.createElement("div", {
                      className: "grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 pt-6 pb-8"
                    }, coachesToShow.map(card))));
}

var make = CoachesIndex__Root;

export {
  str ,
  tr ,
  Selectable ,
  Multiselect ,
  computeInitialState ,
  reducer ,
  unselected ,
  connectLink ,
  overlay ,
  card ,
  applyFilter ,
  selected ,
  make ,
  
}
/*  Not a pure module */
