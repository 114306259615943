// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as Caml_array from "../../../../node_modules/rescript/lib/es6/caml_array.js";

import "./SkeletonLoading.css"
;

function card(classNameOpt, param) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: "skeleton-body-container pt-4 w-full mx-auto" + className
            }, React.createElement("div", {
                  className: "skeleton-body-wrapper px-3 lg:px-0"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__card px-5 py-6 bg-white rounded-lg shadow"
                    }, React.createElement("div", {
                          className: "flex items-center"
                        }, React.createElement("div", {
                              className: "flex-1"
                            }, React.createElement("div", {
                                  className: "skeleton-placeholder__line-sm w-5/6 skeleton-animate"
                                }), React.createElement("div", {
                                  className: "skeleton-placeholder__line-sm mt-4 w-2/3 skeleton-animate"
                                })), React.createElement("div", {
                              className: "skeleton-placeholder__line-sm w-1/6 skeleton-animate"
                            })))));
}

function userCard(param) {
  return React.createElement("div", {
              className: "skeleton-body-container pt-4 w-full mx-auto"
            }, React.createElement("div", {
                  className: "skeleton-body-wrapper px-2 lg:px-0"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__card px-5 py-6 bg-white rounded-lg shadow"
                    }, React.createElement("div", {
                          className: "flex items-center"
                        }, React.createElement("div", {
                              className: "w-14 h-14 bg-gray-50 rounded-full mr-4 skeleton-animate"
                            }), React.createElement("div", {
                              className: "flex-1"
                            }, React.createElement("div", {
                                  className: "skeleton-placeholder__line-sm w-1/3 skeleton-animate"
                                }), React.createElement("div", {
                                  className: "skeleton-placeholder__line-sm mt-4 w-1/2 skeleton-animate"
                                })), React.createElement("div", {
                              className: "skeleton-placeholder__line-sm w-1/6 skeleton-animate"
                            })))));
}

function userDetails(param) {
  return React.createElement("div", {
              className: "skeleton-body-container w-full pb-4 mx-auto"
            }, React.createElement("div", {
                  className: "skeleton-body-wrapper max-w-sm mt-8 px-3 lg:px-0"
                }, React.createElement("div", {
                      className: "flex items-center"
                    }, React.createElement("div", {
                          className: "w-14 h-14 bg-gray-50 rounded-full mr-4 skeleton-animate"
                        }), React.createElement("div", {
                          className: "flex-1"
                        }, React.createElement("div", {
                              className: "skeleton-placeholder__line-sm w-1/2 skeleton-animate"
                            }), React.createElement("div", {
                              className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                            })))));
}

function heading(param) {
  return React.createElement("div", {
              className: "skeleton-body-container pt-8 w-full pb-4 mx-auto"
            }, React.createElement("div", {
                  className: "skeleton-body-wrapper space-y-4 px-3 lg:px-0"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__line-md w-1/2 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm w-2/3 skeleton-animate"
                    })));
}

function codeBlock(param) {
  return React.createElement("div", {
              className: "skeleton-body-container w-full pb-4 mx-auto"
            }, React.createElement("div", {
                  className: "skeleton-body-wrapper max-w-xs mt-8 px-3 lg:px-0"
                }, React.createElement("div", {
                      className: "flex"
                    }, React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mr-3 w-1/3 skeleton-animate"
                        }), React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mr-3 w-1/3 skeleton-animate"
                        }), React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mr-3 w-4 skeleton-animate"
                        })), React.createElement("div", {
                      className: "flex ml-6"
                    }, React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mt-4 mr-3 w-1/2 skeleton-animate"
                        }), React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mt-4 mr-3 w-1/2 skeleton-animate"
                        })), React.createElement("div", {
                      className: "flex ml-6"
                    }, React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mt-4 mr-3 w-1/3 skeleton-animate"
                        }), React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mt-4 mr-3 w-1/6 skeleton-animate"
                        }), React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mt-4 mr-3 w-1/3 skeleton-animate"
                        })), React.createElement("div", {
                      className: "flex"
                    }, React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mt-4 mr-3 w-16 skeleton-animate"
                        }), React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mt-4 mr-3 w-6 skeleton-animate"
                        }))));
}

function image(param) {
  return React.createElement("div", {
              className: "skeleton-body-container w-full pb-4 mx-auto"
            }, React.createElement("div", {
                  className: "skeleton-body-wrapper mt-8 px-3 lg:px-0"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__image mt-5 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mx-auto mt-4 w-1/2 skeleton-animate"
                    })));
}

function imageCard(param) {
  return React.createElement("div", {
              className: "skeleton-body-container pt-4 w-full mx-auto"
            }, React.createElement("div", {
                  className: "skeleton-body-wrapper px-3 lg:px-0"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__card bg-white rounded-lg shadow"
                    }, React.createElement("div", {
                          className: "px-4 pt-1"
                        }, React.createElement("div", {
                              className: "skeleton-placeholder__image skeleton-animate"
                            })), React.createElement("div", {
                          className: "px-4 pb-4"
                        }, React.createElement("div", {
                              className: "skeleton-placeholder__line-sm mt-4 w-1/2 skeleton-animate"
                            }), React.createElement("div", {
                              className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                            }), React.createElement("div", {
                              className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                            }), React.createElement("div", {
                              className: "skeleton-placeholder__line-sm mt-4 w-2/3 skeleton-animate"
                            })))));
}

function paragraph(param) {
  return React.createElement("div", {
              className: "skeleton-body-container w-full pb-4 mx-auto"
            }, React.createElement("div", {
                  className: "skeleton-body-wrapper mt-8 px-3 lg:px-0"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 w-2/3 skeleton-animate"
                    })));
}

function input(param) {
  return React.createElement("div", {
              className: "skeleton-body-container w-full pb-4 mx-auto"
            }, React.createElement("div", {
                  className: "skeleton-placeholder__line-sm mt-4 w-1/4 skeleton-animate"
                }), React.createElement("div", {
                  className: "p-4 mt-2 bg-white border border-gray-300 rounded-md"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__line-sm skeleton-animate"
                    })));
}

function button(param) {
  return React.createElement("div", {
              className: "skeleton-body-container w-full pb-4 mx-auto"
            }, React.createElement("div", {
                  className: "p-4 mt-2 bg-gray-200 border border-gray-300 rounded-md flex justify-center"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__line-sm w-1/4 skeleton-animate"
                    })));
}

function singleLink(param) {
  return React.createElement("div", {
              className: "skeleton-body-container w-full pb-4 mx-auto"
            }, React.createElement("div", {
                  className: "skeleton-placeholder__line-md mt-4 skeleton-animate"
                }));
}

function secondaryLink(param) {
  return React.createElement("div", {
              className: "skeleton-body-container w-full pb-4 mx-auto"
            }, React.createElement("div", {
                  className: "skeleton-placeholder__line-lg mt-2 skeleton-animate"
                }));
}

function contents(param) {
  return React.createElement("div", {
              className: "skeleton-body-container w-full pb-4 mx-auto"
            }, React.createElement("div", {
                  className: "skeleton-body-wrapper mt-8 px-3 lg:px-0"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__line-md mt-4 w-1/2 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 w-2/3 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__image mt-5 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mx-auto mt-4 w-1/2 skeleton-animate"
                    })), React.createElement("div", {
                  className: "skeleton-body-wrapper mt-8 px-3 lg:px-0"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 w-2/3 skeleton-animate"
                    })));
}

function calendar(param) {
  return React.createElement("div", {
              className: "grid grid-cols-7 relative z-30 grid-rows-5 gap-1 mt-2"
            }, React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate col-start-4"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }), React.createElement("div", {
                  className: "skeleton-placeholder__date skeleton-animate"
                }));
}

function coursePage(param) {
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "bg-gray-50 pb-8"
                }, React.createElement("div", {
                      className: "max-w-4xl 2xl:max-w-5xl mx-auto px-4"
                    }, React.createElement("div", {
                          className: "skeleton-body-wrapper pt-10 px-3 lg:px-0"
                        }, React.createElement("div", {
                              className: "skeleton-placeholder__line-md w-1/12 skeleton-animate"
                            })), heading(undefined))), React.createElement("div", {
                  className: "max-w-4xl 2xl:max-w-5xl mx-auto px-4 pt-4"
                }, React.createElement("div", undefined, input(undefined)), React.createElement("div", undefined, input(undefined)), React.createElement("div", undefined, input(undefined)), React.createElement("div", undefined, button(undefined))));
}

function multiple(count, element) {
  return $$Array.mapi((function (key, element) {
                return React.createElement("div", {
                            key: String(key)
                          }, element);
              }), Caml_array.make(count, element));
}

export {
  card ,
  userCard ,
  userDetails ,
  heading ,
  codeBlock ,
  image ,
  imageCard ,
  paragraph ,
  input ,
  button ,
  singleLink ,
  secondaryLink ,
  contents ,
  calendar ,
  coursePage ,
  multiple ,
  
}
/*  Not a pure module */
