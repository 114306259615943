// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Link from "../../../shared/components/Link.bs.js";
import * as React from "react";
import * as Dropdown from "../../../shared/components/Dropdown.bs.js";
import * as AppRouter__Page from "../types/AppRouter__Page.bs.js";
import * as AppRouter__Course from "../types/AppRouter__Course.bs.js";

function str(prim) {
  return prim;
}

function selected(currentCourse) {
  return React.createElement("button", {
              className: "text-white md:text-gray-900 bg-gray-900 md:bg-gray-100 appearance-none flex items-center justify-between hover:bg-gray-800 md:hover:bg-gray-50 hover:text-gray-50 focus:bg-gray-50 md:hover:text-primary-500 focus:outline-none focus:bg-white focus:text-primary-500 font-semibold relative px-3 py-2 rounded-md w-full focus:ring-2 focus:ring-offset-2 focus:ring-focusColor-500 ",
              title: AppRouter__Course.name(currentCourse)
            }, React.createElement("span", {
                  className: "w-5/6 flex items-center"
                }, React.createElement("i", {
                      className: "fas fa-book"
                    }), React.createElement("span", {
                      className: "truncate ml-2 text-left"
                    }, AppRouter__Course.name(currentCourse))), React.createElement("span", {
                  className: "w-1/6 text-right"
                }, React.createElement("i", {
                      className: "fas fa-chevron-down text-sm"
                    })));
}

function contents(courses, currentCourse, coursePage, selectedPage) {
  return courses.filter(function (course) {
                if (AppRouter__Course.id(course) !== AppRouter__Course.id(currentCourse)) {
                  return !AppRouter__Course.ended(course);
                } else {
                  return false;
                }
              }).map(function (course) {
              var nextPage = AppRouter__Page.canAccessPage(coursePage, course) ? coursePage : /* Curriculum */0;
              var href = AppRouter__Page.coursePath(AppRouter__Course.id(course), nextPage);
              var classes = "block px-4 py-3 text-xs font-semibold text-gray-900 bg-white hover:text-primary-500 hover:bg-gray-50 whitespace-normal focus:ring-2 focus:ring-inset focus:ring-focusColor-500";
              if (AppRouter__Page.useSPA(selectedPage, {
                      TAG: /* SelectedCourse */0,
                      _0: AppRouter__Course.id(course),
                      _1: nextPage
                    })) {
                return React.createElement(Link.make, {
                            href: href,
                            className: classes,
                            children: AppRouter__Course.name(course),
                            key: AppRouter__Course.id(course)
                          });
              } else {
                return React.createElement("a", {
                            key: AppRouter__Course.id(course),
                            className: classes,
                            href: href
                          }, AppRouter__Course.name(course));
              }
            });
}

function AppRouter__CourseSelector(Props) {
  var courses = Props.courses;
  var selectedPage = Props.selectedPage;
  var coursePage = Props.coursePage;
  var currentCourse = Props.currentCourse;
  return React.createElement(Dropdown.make, {
              selected: selected(currentCourse),
              contents: contents(courses, currentCourse, coursePage, selectedPage),
              className: "w-full md:text-base"
            });
}

var make = AppRouter__CourseSelector;

export {
  str ,
  selected ,
  contents ,
  make ,
  
}
/* Link Not a pure module */
