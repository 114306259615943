// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Link from "../../../shared/components/Link.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Caml_obj from "../../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as ReactUtils from "../../../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as StringUtils from "../../../shared/utils/StringUtils.bs.js";
import * as MarkdownBlock from "../../../shared/components/MarkdownBlock.bs.js";
import * as RescriptReactRouter from "../../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import LevelLockSvg from "../images/level-lock.svg";
import * as CoursesCurriculum__Level from "../types/CoursesCurriculum__Level.bs.js";
import LevelEmptySvg from "../images/level-empty.svg";
import * as CoursesCurriculum__Course from "../types/CoursesCurriculum__Course.bs.js";
import * as CoursesCurriculum__Target from "../types/CoursesCurriculum__Target.bs.js";
import * as CoursesCurriculum__Overlay from "./CoursesCurriculum__Overlay.bs.js";
import * as CoursesCurriculum__Student from "../types/CoursesCurriculum__Student.bs.js";
import * as CoursesCurriculum__TargetGroup from "../types/CoursesCurriculum__TargetGroup.bs.js";
import * as CoursesCurriculum__TargetStatus from "../types/CoursesCurriculum__TargetStatus.bs.js";
import * as CoursesCurriculum__LevelSelector from "./CoursesCurriculum__LevelSelector.bs.js";
import * as CoursesCurriculum__NoticeManager from "./CoursesCurriculum__NoticeManager.bs.js";
import * as CoursesCurriculum__LatestSubmission from "../types/CoursesCurriculum__LatestSubmission.bs.js";

import "./CoursesCurriculum.css"
;

var levelLockedImage = LevelLockSvg;

var levelEmptyImage = LevelEmptySvg;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function targetStatusClasses(targetStatus) {
  var statusClasses = "curriculum__target-status--" + CoursesCurriculum__TargetStatus.statusClassesSufix(targetStatus);
  return "curriculum__target-status px-3 py-px ml-4 h-6 " + statusClasses;
}

function rendertarget(target, statusOfTargets, author, courseId) {
  var targetId = CoursesCurriculum__Target.id(target);
  var targetStatus = ArrayUtils.unsafeFind((function (ts) {
          return CoursesCurriculum__TargetStatus.targetId(ts) === targetId;
        }), "Could not find targetStatus for listed target with ID " + targetId, statusOfTargets);
  return React.createElement("div", {
              key: "target-" + targetId,
              className: "courses-curriculum__target-container flex border-t bg-white hover:bg-gray-50"
            }, React.createElement(Link.make, {
                  href: "/targets/" + targetId,
                  ariaLabel: "Select Target: " + (CoursesCurriculum__Target.title(target) + ", Status: " + CoursesCurriculum__TargetStatus.statusToString(targetStatus)),
                  className: "p-6 flex grow items-center justify-between hover:text-primary-500 cursor-pointer focus:outline-none focus:ring-2 focus:ring-inset focus:ring-focusColor-500 focus:text-primary-500 focus:bg-gray-50 focus:rounded-lg",
                  children: null,
                  props: {
                    "data-target-id": targetId
                  }
                }, React.createElement("span", {
                      className: "font-medium text-left leading-snug"
                    }, CoursesCurriculum__Target.title(target)), ReactUtils.nullIf(React.createElement("span", {
                          className: targetStatusClasses(targetStatus)
                        }, CoursesCurriculum__TargetStatus.statusToString(targetStatus)), CoursesCurriculum__TargetStatus.isAccessEnded(targetStatus) || CoursesCurriculum__TargetStatus.isPending(targetStatus))), ReactUtils.nullUnless(React.createElement("a", {
                      "aria-label": t([[
                              "title",
                              CoursesCurriculum__Target.title(target)
                            ]], undefined, "edit_target_button_title"),
                      className: "hidden lg:block courses-curriculum__target-quick-link text-gray-400 border-l border-transparent py-6 px-3 hover:text-primary-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-focusColor-500 focus:bg-gray-50 focus:text-primary-500 focus:rounded-lg",
                      title: t([[
                              "title",
                              CoursesCurriculum__Target.title(target)
                            ]], undefined, "edit_target_button_title"),
                      href: "/school/courses/" + courseId + "/targets/" + targetId + "/content"
                    }, React.createElement("i", {
                          className: "fas fa-pencil-alt"
                        })), author));
}

function renderTargetGroup(targetGroup, targets, statusOfTargets, author, courseId) {
  var targetGroupId = CoursesCurriculum__TargetGroup.id(targetGroup);
  var targets$1 = targets.filter(function (t) {
        return CoursesCurriculum__Target.targetGroupId(t) === targetGroupId;
      });
  return React.createElement("div", {
              key: "target-group-" + targetGroupId,
              className: "curriculum__target-group-container relative mt-5 px-3"
            }, React.createElement("div", {
                  className: "curriculum__target-group max-w-3xl mx-auto bg-white text-center rounded-lg shadow-md relative z-10 overflow-hidden "
                }, CoursesCurriculum__TargetGroup.milestone(targetGroup) ? React.createElement("div", {
                        className: "inline-block px-3 py-2 bg-orange-400 font-bold text-xs rounded-b-lg leading-tight text-white uppercase"
                      }, t(undefined, undefined, "milestone_targets")) : null, React.createElement("div", {
                      className: "p-6 pt-5"
                    }, React.createElement("div", {
                          className: "text-2xl font-bold leading-snug"
                        }, CoursesCurriculum__TargetGroup.name(targetGroup)), React.createElement(MarkdownBlock.make, {
                          markdown: CoursesCurriculum__TargetGroup.description(targetGroup),
                          className: "text-sm max-w-md mx-auto leading-snug",
                          profile: /* AreaOfText */1
                        })), ArrayUtils.copyAndSort((function (t1, t2) {
                          return CoursesCurriculum__Target.sortIndex(t1) - CoursesCurriculum__Target.sortIndex(t2) | 0;
                        }), targets$1).map(function (target) {
                      return rendertarget(target, statusOfTargets, author, courseId);
                    })));
}

function addSubmission(setState, latestSubmission, levelUpEligibility) {
  return Curry._1(setState, (function (state) {
                var withoutSubmissionForThisTarget = state.latestSubmissions.filter(function (s) {
                      return CoursesCurriculum__LatestSubmission.targetId(s) !== CoursesCurriculum__LatestSubmission.targetId(latestSubmission);
                    });
                var eligibility = Belt_Option.getWithDefault(levelUpEligibility, state.levelUpEligibility);
                return {
                        selectedLevelId: state.selectedLevelId,
                        showLevelZero: state.showLevelZero,
                        latestSubmissions: withoutSubmissionForThisTarget.concat([latestSubmission]),
                        statusOfTargets: state.statusOfTargets,
                        notice: state.notice,
                        levelUpEligibility: eligibility
                      };
              }));
}

function handleLockedLevel(level) {
  var date = CoursesCurriculum__Level.unlockAt(level);
  var tmp;
  if (date !== undefined) {
    var dateString = DateFns.format(Caml_option.valFromOption(date), "MMMM d, yyyy");
    tmp = React.createElement("div", {
          className: "font-semibold text-md px-3"
        }, React.createElement("p", undefined, t(undefined, undefined, "level_locked_notice")), React.createElement("p", undefined, t([[
                    "date",
                    dateString
                  ]], undefined, "level_locked_explanation")));
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: "max-w-xl mx-auto text-center mt-4"
            }, React.createElement("div", {
                  className: "text-2xl font-bold px-3"
                }, t(undefined, undefined, "level_locked")), React.createElement("img", {
                  className: "max-w-sm mx-auto",
                  src: levelLockedImage
                }), tmp);
}

function statusOfMilestoneTargets(targetGroups, targets, level, statusOfTargets) {
  var targetGroupsInLevel = targetGroups.filter(function (tg) {
        return CoursesCurriculum__TargetGroup.levelId(tg) === CoursesCurriculum__Level.id(level);
      });
  var milestoneTargetGroupIds = targetGroupsInLevel.filter(CoursesCurriculum__TargetGroup.milestone).map(CoursesCurriculum__TargetGroup.id);
  var milestoneTargetIds = targets.filter(function (t) {
          return milestoneTargetGroupIds.includes(CoursesCurriculum__Target.targetGroupId(t));
        }).map(CoursesCurriculum__Target.id);
  return statusOfTargets.filter(function (ts) {
              return milestoneTargetIds.includes(CoursesCurriculum__TargetStatus.targetId(ts));
            });
}

function issuedCertificate(course) {
  var csn = CoursesCurriculum__Course.certificateSerialNumber(course);
  if (csn !== undefined) {
    return React.createElement("div", {
                className: "max-w-3xl mx-auto text-center mt-4 bg-white lg:rounded-lg shadow-md px-6 pt-6 pb-8"
              }, React.createElement("div", {
                    className: "max-w-xl font-bold text-xl mx-auto mt-2 leading-tight"
                  }, t(undefined, undefined, "issued_certificate_heading")), React.createElement("a", {
                    className: "mt-4 mb-2 btn btn-primary",
                    href: "/c/" + csn
                  }, React.createElement(FaIcon.make, {
                        classes: "fas fa-certificate"
                      }), React.createElement("span", {
                        className: "ml-2"
                      }, t(undefined, undefined, "issued_certificate_button"))));
  } else {
    return null;
  }
}

function computeLevelUp(levelUpEligibility, course, studentLevel, targetGroups, targets, statusOfTargets) {
  var progressionBehavior = CoursesCurriculum__Course.progressionBehavior(course);
  var currentLevelNumber = CoursesCurriculum__Level.number(studentLevel);
  var statusOfCurrentMilestoneTargets = statusOfMilestoneTargets(targetGroups, targets, studentLevel, statusOfTargets);
  switch (levelUpEligibility) {
    case /* Eligible */0 :
        return /* LevelUp */4;
    case /* AtMaxLevel */1 :
        if (CoursesCurriculum__TargetStatus.allComplete(statusOfCurrentMilestoneTargets)) {
          return /* CourseComplete */2;
        } else {
          return /* Nothing */6;
        }
    case /* CurrentLevelIncomplete */3 :
        if (typeof progressionBehavior === "object") {
          return /* Nothing */6;
        }
        if (progressionBehavior === "Unlimited") {
          return /* Nothing */6;
        }
        var currentLevelAttempted = CoursesCurriculum__TargetStatus.allAttempted(statusOfCurrentMilestoneTargets);
        if (!currentLevelAttempted) {
          return /* Nothing */6;
        }
        var hasRejectedSubmissions = CoursesCurriculum__TargetStatus.anyRejected(statusOfCurrentMilestoneTargets);
        return {
                TAG: /* LevelUpBlocked */1,
                _0: currentLevelNumber,
                _1: hasRejectedSubmissions
              };
    case /* PreviousLevelIncomplete */4 :
        if (typeof progressionBehavior !== "object") {
          return /* Nothing */6;
        }
        var minimumLevelNumber = currentLevelNumber - progressionBehavior.VAL | 0;
        if (minimumLevelNumber >= 1) {
          return {
                  TAG: /* LevelUpLimited */0,
                  _0: currentLevelNumber,
                  _1: minimumLevelNumber
                };
        } else {
          return /* Nothing */6;
        }
    case /* TeamMembersPending */5 :
        return /* TeamMembersPending */5;
    case /* NoMilestonesInLevel */2 :
    case /* DateLocked */6 :
        return /* Nothing */6;
    
  }
}

function computeNotice(studentLevel, targetGroups, targets, statusOfTargets, course, student, preview, levelUpEligibility) {
  if (preview) {
    return /* Preview */0;
  } else if (CoursesCurriculum__Course.ended(course)) {
    return /* CourseEnded */1;
  } else if (CoursesCurriculum__Student.accessEnded(student)) {
    return /* AccessEnded */3;
  } else {
    return computeLevelUp(levelUpEligibility, course, studentLevel, targetGroups, targets, statusOfTargets);
  }
}

function navigationLink(direction, level, setState) {
  var match = direction === "Next" ? [
      undefined,
      t(undefined, undefined, "nav_next_level"),
      "Next",
      "fa-arrow-right"
    ] : [
      "fa-arrow-left",
      t(undefined, undefined, "nav_previous_level"),
      "Previous",
      undefined
    ];
  var longText = match[1];
  var arrow = function (icon) {
    return Belt_Option.mapWithDefault(icon, null, (function (icon) {
                  return React.createElement(FaIcon.make, {
                              classes: "fas " + icon
                            });
                }));
  };
  return React.createElement("button", {
              "aria-label": "Go to " + longText,
              className: "block w-full focus:outline-none p-4 text-center border rounded-lg bg-gray-50 hover:bg-gray-50 cursor-pointer hover:text-primary-500 focus:text-primary-500 focus:bg-gray-50 focus:ring-2 focus:ring-inset focus:ring-focusColor-500",
              onClick: (function (param) {
                  return Curry._1(setState, (function (state) {
                                return {
                                        selectedLevelId: CoursesCurriculum__Level.id(level),
                                        showLevelZero: state.showLevelZero,
                                        latestSubmissions: state.latestSubmissions,
                                        statusOfTargets: state.statusOfTargets,
                                        notice: state.notice,
                                        levelUpEligibility: state.levelUpEligibility
                                      };
                              }));
                })
            }, arrow(match[0]), React.createElement("span", {
                  className: "mx-2 hidden md:inline"
                }, longText), React.createElement("span", {
                  className: "mx-2 inline md:hidden"
                }, match[2]), arrow(match[3]));
}

function quickNavigationLinks(levels, selectedLevel, setState) {
  var previous = CoursesCurriculum__Level.previous(levels, selectedLevel);
  var next = CoursesCurriculum__Level.next(levels, selectedLevel);
  return React.createElement("div", {
              key: "quick-navigation-links"
            }, React.createElement("hr", {
                  className: "my-6"
                }), React.createElement("div", {
                  className: "container mx-auto max-w-3xl flex px-3 lg:px-0"
                }, previous !== undefined ? (
                    next !== undefined ? [
                        React.createElement("div", {
                              key: "previous",
                              className: "w-1/2 mr-2"
                            }, navigationLink("Previous", previous, setState)),
                        React.createElement("div", {
                              key: "next",
                              className: "w-1/2 ml-2"
                            }, navigationLink("Next", next, setState))
                      ] : React.createElement("div", {
                            className: "w-full"
                          }, navigationLink("Previous", previous, setState))
                  ) : (
                    next !== undefined ? React.createElement("div", {
                            className: "w-full"
                          }, navigationLink("Next", next, setState)) : null
                  )));
}

function CoursesCurriculum(Props) {
  var author = Props.author;
  var course = Props.course;
  var levels = Props.levels;
  var targetGroups = Props.targetGroups;
  var targets = Props.targets;
  var submissions = Props.submissions;
  var student = Props.student;
  var coaches = Props.coaches;
  var users = Props.users;
  var evaluationCriteria = Props.evaluationCriteria;
  var preview = Props.preview;
  var accessLockedLevels = Props.accessLockedLevels;
  var levelUpEligibility = Props.levelUpEligibility;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = url.path;
  var selectedTarget;
  if (match && match.hd === "targets") {
    var match$1 = match.tl;
    selectedTarget = match$1 ? Belt_Option.map(StringUtils.paramToId(match$1.hd), (function (targetId) {
              return ArrayUtils.unsafeFind((function (t) {
                            return CoursesCurriculum__Target.id(t) === targetId;
                          }), "Could not find selectedTarget with ID " + targetId, targets);
            })) : undefined;
  } else {
    selectedTarget = undefined;
  }
  var levelZero = levels.find(function (l) {
        return CoursesCurriculum__Level.number(l) === 0;
      });
  var levelZero$1 = levelZero === undefined ? undefined : Caml_option.some(levelZero);
  var studentLevelId = CoursesCurriculum__Student.levelId(student);
  var studentLevel = ArrayUtils.unsafeFind((function (l) {
          return CoursesCurriculum__Level.id(l) === studentLevelId;
        }), "Could not find studentLevel with ID " + studentLevelId, levels);
  var targetLevelId;
  if (selectedTarget !== undefined) {
    var targetGroupId = CoursesCurriculum__Target.targetGroupId(selectedTarget);
    var targetGroup = ArrayUtils.unsafeFind((function (t) {
            return CoursesCurriculum__TargetGroup.id(t) === targetGroupId;
          }), "Could not find targetGroup with ID " + targetGroupId, targetGroups);
    targetLevelId = CoursesCurriculum__TargetGroup.levelId(targetGroup);
  } else {
    targetLevelId = undefined;
  }
  var computeTargetStatus = function (param) {
    return CoursesCurriculum__TargetStatus.compute(preview, student, course, levels, targetGroups, targets, param);
  };
  var initialRender = React.useRef(true);
  var match$2 = React.useState(function () {
        var statusOfTargets = computeTargetStatus(submissions);
        var tmp;
        var exit = 0;
        if (preview && targetLevelId === undefined) {
          tmp = CoursesCurriculum__Level.id(CoursesCurriculum__Level.first(levels));
        } else {
          exit = 1;
        }
        if (exit === 1) {
          tmp = targetLevelId !== undefined && !(levelZero !== undefined && CoursesCurriculum__Level.id(levelZero) === targetLevelId) ? targetLevelId : studentLevelId;
        }
        return {
                selectedLevelId: tmp,
                showLevelZero: levelZero !== undefined && targetLevelId !== undefined ? CoursesCurriculum__Level.id(levelZero) === targetLevelId : false,
                latestSubmissions: submissions,
                statusOfTargets: statusOfTargets,
                notice: computeNotice(studentLevel, targetGroups, targets, statusOfTargets, course, student, preview, levelUpEligibility),
                levelUpEligibility: levelUpEligibility
              };
      });
  var setState = match$2[1];
  var state = match$2[0];
  var match$3 = state.showLevelZero;
  var currentLevelId = levelZero !== undefined && match$3 ? CoursesCurriculum__Level.id(levelZero) : state.selectedLevelId;
  var currentLevel = ArrayUtils.unsafeFind((function (l) {
          return CoursesCurriculum__Level.id(l) === currentLevelId;
        }), "Could not find currentLevel with id " + currentLevelId, levels);
  var selectedLevel = ArrayUtils.unsafeFind((function (l) {
          return CoursesCurriculum__Level.id(l) === state.selectedLevelId;
        }), "Could not find selectedLevel with id " + state.selectedLevelId, levels);
  React.useEffect((function () {
          if (initialRender.current) {
            initialRender.current = false;
          } else {
            var newStatusOfTargets = computeTargetStatus(state.latestSubmissions);
            Curry._1(setState, (function (state) {
                    return {
                            selectedLevelId: state.selectedLevelId,
                            showLevelZero: state.showLevelZero,
                            latestSubmissions: state.latestSubmissions,
                            statusOfTargets: newStatusOfTargets,
                            notice: computeNotice(studentLevel, targetGroups, targets, newStatusOfTargets, course, student, preview, state.levelUpEligibility),
                            levelUpEligibility: state.levelUpEligibility
                          };
                  }));
          }
          
        }), [state.latestSubmissions]);
  var targetGroupsInLevel = targetGroups.filter(function (tg) {
        return CoursesCurriculum__TargetGroup.levelId(tg) === currentLevelId;
      });
  var tmp;
  if (selectedTarget !== undefined) {
    var targetStatus = ArrayUtils.unsafeFind((function (ts) {
            return CoursesCurriculum__TargetStatus.targetId(ts) === CoursesCurriculum__Target.id(selectedTarget);
          }), "Could not find targetStatus for selectedTarget with ID " + CoursesCurriculum__Target.id(selectedTarget), state.statusOfTargets);
    tmp = React.createElement(CoursesCurriculum__Overlay.make, {
          target: selectedTarget,
          course: course,
          targetStatus: targetStatus,
          addSubmissionCB: (function (param, param$1) {
              return addSubmission(setState, param, param$1);
            }),
          targets: targets,
          statusOfTargets: state.statusOfTargets,
          users: users,
          evaluationCriteria: evaluationCriteria,
          coaches: coaches,
          preview: preview,
          author: author
        });
  } else {
    tmp = null;
  }
  var _anyOtherNotice = state.notice;
  return React.createElement("div", {
              "aria-label": "Curriculum",
              className: "bg-gray-50 pt-11 pb-8 -mt-7",
              role: "main"
            }, tmp, issuedCertificate(course), React.createElement(CoursesCurriculum__NoticeManager.make, {
                  notice: state.notice,
                  course: course
                }), _anyOtherNotice === 4 ? null : [
                React.createElement("div", {
                      key: "curriculum-body",
                      className: "relative"
                    }, React.createElement(CoursesCurriculum__LevelSelector.make, {
                          levels: levels,
                          studentLevel: studentLevel,
                          selectedLevel: selectedLevel,
                          preview: preview,
                          setSelectedLevelId: (function (selectedLevelId) {
                              return Curry._1(setState, (function (state) {
                                            return {
                                                    selectedLevelId: selectedLevelId,
                                                    showLevelZero: state.showLevelZero,
                                                    latestSubmissions: state.latestSubmissions,
                                                    statusOfTargets: state.statusOfTargets,
                                                    notice: state.notice,
                                                    levelUpEligibility: state.levelUpEligibility
                                                  };
                                          }));
                            }),
                          showLevelZero: state.showLevelZero,
                          setShowLevelZero: (function (showLevelZero) {
                              return Curry._1(setState, (function (state) {
                                            return {
                                                    selectedLevelId: state.selectedLevelId,
                                                    showLevelZero: showLevelZero,
                                                    latestSubmissions: state.latestSubmissions,
                                                    statusOfTargets: state.statusOfTargets,
                                                    notice: state.notice,
                                                    levelUpEligibility: state.levelUpEligibility
                                                  };
                                          }));
                            }),
                          levelZero: levelZero$1
                        }), ReactUtils.nullUnless(React.createElement("div", {
                              className: "text-center mt-2 max-w-3xl mx-auto"
                            }, React.createElement("a", {
                                  className: "btn btn-primary-ghost btn-small",
                                  href: "/school/courses/" + CoursesCurriculum__Course.id(course) + "/curriculum?level=" + String(CoursesCurriculum__Level.number(currentLevel))
                                }, React.createElement("i", {
                                      className: "fas fa-pencil-alt"
                                    }), React.createElement("span", {
                                      className: "ml-2"
                                    }, t(undefined, undefined, "edit_level_button")))), author), CoursesCurriculum__Level.isLocked(currentLevel) && accessLockedLevels ? React.createElement("div", {
                            className: "text-center p-3 mt-5 border rounded-lg bg-blue-100 max-w-3xl mx-auto",
                            dangerouslySetInnerHTML: {
                              __html: t([[
                                      "date",
                                      CoursesCurriculum__Level.unlockDateString(currentLevel)
                                    ]], undefined, "level_locked_for_students_notice")
                            }
                          }) : null, CoursesCurriculum__Level.isUnlocked(currentLevel) || accessLockedLevels ? (
                        Caml_obj.caml_equal(targetGroupsInLevel, []) ? React.createElement("div", {
                                className: "mx-auto py-10"
                              }, React.createElement("img", {
                                    className: "max-w-xs md:max-w-sm mx-auto",
                                    src: levelEmptyImage
                                  }), React.createElement("p", {
                                    className: "text-center font-semibold text-lg mt-4"
                                  }, t(undefined, undefined, "empty_level_content_notice"))) : CoursesCurriculum__TargetGroup.sort(targetGroupsInLevel).map(function (targetGroup) {
                                return renderTargetGroup(targetGroup, targets, state.statusOfTargets, author, CoursesCurriculum__Course.id(course));
                              })
                      ) : handleLockedLevel(currentLevel)),
                state.showLevelZero ? null : quickNavigationLinks(levels, selectedLevel, setState)
              ]);
}

var make = CoursesCurriculum;

export {
  levelLockedImage ,
  levelEmptyImage ,
  str ,
  t ,
  targetStatusClasses ,
  rendertarget ,
  renderTargetGroup ,
  addSubmission ,
  handleLockedLevel ,
  statusOfMilestoneTargets ,
  issuedCertificate ,
  computeLevelUp ,
  computeNotice ,
  navigationLink ,
  quickNavigationLinks ,
  make ,
  
}
/*  Not a pure module */
