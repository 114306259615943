// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as CoursesCurriculum__Target from "../types/CoursesCurriculum__Target.bs.js";
import * as CoursesCurriculum__Submission from "../types/CoursesCurriculum__Submission.bs.js";
import * as CoursesCurriculum__TargetStatus from "../types/CoursesCurriculum__TargetStatus.bs.js";
import * as CoursesCurriculum__TargetDetails from "../types/CoursesCurriculum__TargetDetails.bs.js";
import * as CoursesCurriculum__LevelUpEligibility from "../types/CoursesCurriculum__LevelUpEligibility.bs.js";
import * as CoursesCurriculum__CompletionInstructions from "./CoursesCurriculum__CompletionInstructions.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum__AutoVerify";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var partial_arg$1 = "shared";

function ts(param, param$1, param$2) {
  return I18n.t(partial_arg$1, param, param$1, param$2);
}

var Raw = {};

var query = "mutation AutoVerifySubmissionMutation($targetId: ID!)  {\nautoVerifySubmission(targetId: $targetId)  {\nsubmission  {\nid  \ncreatedAt  \n}\n\nlevelUpEligibility  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.autoVerifySubmission;
  var value$2 = value$1.submission;
  var value$3 = value$1.levelUpEligibility;
  var tmp;
  if (value$3 == null) {
    tmp = undefined;
  } else {
    var tmp$1;
    switch (value$3) {
      case "AtMaxLevel" :
          tmp$1 = "AtMaxLevel";
          break;
      case "CurrentLevelIncomplete" :
          tmp$1 = "CurrentLevelIncomplete";
          break;
      case "DateLocked" :
          tmp$1 = "DateLocked";
          break;
      case "Eligible" :
          tmp$1 = "Eligible";
          break;
      case "NoMilestonesInLevel" :
          tmp$1 = "NoMilestonesInLevel";
          break;
      case "PreviousLevelIncomplete" :
          tmp$1 = "PreviousLevelIncomplete";
          break;
      case "TeamMembersPending" :
          tmp$1 = "TeamMembersPending";
          break;
      default:
        tmp$1 = {
          NAME: "FutureAddedValue",
          VAL: value$3
        };
    }
    tmp = tmp$1;
  }
  return {
          autoVerifySubmission: {
            submission: !(value$2 == null) ? ({
                  id: value$2.id,
                  createdAt: value$2.createdAt
                }) : undefined,
            levelUpEligibility: tmp
          }
        };
}

function serialize(value) {
  var value$1 = value.autoVerifySubmission;
  var value$2 = value$1.levelUpEligibility;
  var levelUpEligibility = value$2 !== undefined ? (
      typeof value$2 === "object" ? value$2.VAL : (
          value$2 === "DateLocked" ? "DateLocked" : (
              value$2 === "TeamMembersPending" ? "TeamMembersPending" : (
                  value$2 === "Eligible" ? "Eligible" : (
                      value$2 === "PreviousLevelIncomplete" ? "PreviousLevelIncomplete" : (
                          value$2 === "NoMilestonesInLevel" ? "NoMilestonesInLevel" : (
                              value$2 === "CurrentLevelIncomplete" ? "CurrentLevelIncomplete" : "AtMaxLevel"
                            )
                        )
                    )
                )
            )
        )
    ) : null;
  var value$3 = value$1.submission;
  var submission;
  if (value$3 !== undefined) {
    var value$4 = value$3.createdAt;
    var value$5 = value$3.id;
    submission = {
      id: value$5,
      createdAt: value$4
    };
  } else {
    submission = null;
  }
  var autoVerifySubmission = {
    submission: submission,
    levelUpEligibility: levelUpEligibility
  };
  return {
          autoVerifySubmission: autoVerifySubmission
        };
}

function serializeVariables(inp) {
  return {
          targetId: inp.targetId
        };
}

function makeVariables(targetId, param) {
  return {
          targetId: targetId
        };
}

var AutoVerifySubmissionQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var AutoVerifySubmissionQuery_Graphql_error = include.Graphql_error;

var AutoVerifySubmissionQuery_decodeNotification = include.decodeNotification;

var AutoVerifySubmissionQuery_decodeObject = include.decodeObject;

var AutoVerifySubmissionQuery_decodeNotifications = include.decodeNotifications;

var AutoVerifySubmissionQuery_decodeErrors = include.decodeErrors;

var AutoVerifySubmissionQuery_flashNotifications = include.flashNotifications;

var AutoVerifySubmissionQuery_sendQuery = include.sendQuery;

var AutoVerifySubmissionQuery_query = include.query;

var AutoVerifySubmissionQuery_fetch = include.$$fetch;

var AutoVerifySubmissionQuery = {
  AutoVerifySubmissionQuery_inner: AutoVerifySubmissionQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: AutoVerifySubmissionQuery_Graphql_error,
  decodeNotification: AutoVerifySubmissionQuery_decodeNotification,
  decodeObject: AutoVerifySubmissionQuery_decodeObject,
  decodeNotifications: AutoVerifySubmissionQuery_decodeNotifications,
  decodeErrors: AutoVerifySubmissionQuery_decodeErrors,
  flashNotifications: AutoVerifySubmissionQuery_flashNotifications,
  sendQuery: AutoVerifySubmissionQuery_sendQuery,
  query: AutoVerifySubmissionQuery_query,
  $$fetch: AutoVerifySubmissionQuery_fetch,
  make: make
};

function redirect(link) {
  var $$window$1 = window;
  $$window$1.open(link, "_blank", "");
  
}

function handleSuccess(submission, levelUpEligibility, linkToComplete, addSubmissionCB) {
  Curry._2(addSubmissionCB, CoursesCurriculum__Submission.make(submission.id, DateFns.decodeISO(submission.createdAt), /* MarkedAsComplete */0, []), levelUpEligibility);
  if (linkToComplete !== undefined) {
    return redirect(linkToComplete);
  }
  
}

function createAutoVerifySubmission(target, linkToComplete, setSaving, addSubmissionCB, $$event) {
  $$event.preventDefault();
  Curry._1(setSaving, (function (param) {
          return true;
        }));
  Curry._3(make, undefined, undefined, {
          targetId: CoursesCurriculum__Target.id(target)
        }).then(function (response) {
        var details = response.autoVerifySubmission.submission;
        if (details !== undefined) {
          var levelUpEligibility = CoursesCurriculum__LevelUpEligibility.makeOptionFromJs(response.autoVerifySubmission.levelUpEligibility);
          handleSuccess(Caml_option.valFromOption(details), levelUpEligibility, linkToComplete, addSubmissionCB);
        } else {
          Curry._1(setSaving, (function (param) {
                  return false;
                }));
        }
        return Promise.resolve(undefined);
      });
  
}

function completeButtonText(title, iconClasses) {
  return React.createElement("span", undefined, React.createElement(FaIcon.make, {
                  classes: iconClasses + " mr-2"
                }), title);
}

function previewLinkToComplete(link) {
  return React.createElement("a", {
              className: "block text-primary-500 w-full text-center bg-gray-50 hover:bg-gray-300 hover:text-primary-600 p-4 rounded text-lg font-bold",
              href: link,
              target: "_blank"
            }, React.createElement("span", undefined, React.createElement(FaIcon.make, {
                      classes: "fas fa-external-link-alt mr-2"
                    }), tr(undefined, undefined, "visit_link")));
}

function autoVerify(target, linkToComplete, saving, setSaving, addSubmissionCB, preview) {
  if (preview && linkToComplete !== undefined) {
    return previewLinkToComplete(linkToComplete);
  }
  return React.createElement("button", {
              className: "flex rounded btn-success text-lg justify-center w-full font-bold p-4 curriculum-overlay__learn-auto-verify-btn ",
              disabled: saving || preview,
              onClick: (function (param) {
                  return createAutoVerifySubmission(target, linkToComplete, setSaving, addSubmissionCB, param);
                })
            }, saving ? completeButtonText(tr(undefined, undefined, "saving"), "fas fa-spinner fa-spin") : (
                linkToComplete !== undefined ? completeButtonText(tr(undefined, undefined, "visit_complete"), "fas fa-external-link-alt") : completeButtonText(tr(undefined, undefined, "mark_complete"), "fas fa-check-square")
              ));
}

function statusBar(string, linkToComplete) {
  var message = React.createElement("div", {
        className: "flex items-center"
      }, React.createElement("span", {
            className: "fa-stack text-lg mr-1 text-green-500"
          }, React.createElement("i", {
                className: "fas fa-certificate fa-stack-2x"
              }), React.createElement("i", {
                className: "fas fa-check fa-stack-1x fa-inverse"
              })), React.createElement("span", undefined, string));
  var visitLink = function (link) {
    return React.createElement("a", {
                className: "text-right w-full",
                href: link,
                target: "_blank"
              }, React.createElement("i", {
                    className: "fas fa-external-link-alt mr-2"
                  }), tr(undefined, undefined, "visit_link"));
  };
  return React.createElement("div", {
              className: "font-bold p-4 flex w-full items-center text-green-500 bg-green-100 justify-center"
            }, message, linkToComplete !== undefined ? visitLink(linkToComplete) : null);
}

function completionInstructionText(linkToComplete) {
  if (linkToComplete !== undefined) {
    return tr(undefined, undefined, "before_visiting");
  } else {
    return tr(undefined, undefined, "before_marking");
  }
}

function CoursesCurriculum__AutoVerify(Props) {
  var target = Props.target;
  var targetDetails = Props.targetDetails;
  var targetStatus = Props.targetStatus;
  var addSubmissionCB = Props.addSubmissionCB;
  var preview = Props.preview;
  var match = React.useState(function () {
        return false;
      });
  var linkToComplete = CoursesCurriculum__TargetDetails.linkToComplete(targetDetails);
  var match$1 = CoursesCurriculum__TargetStatus.status(targetStatus);
  return [
          React.createElement(CoursesCurriculum__CompletionInstructions.make, {
                targetDetails: targetDetails,
                title: completionInstructionText(linkToComplete),
                key: "completion-instructions"
              }),
          React.createElement("div", {
                key: "completion-button",
                className: "mt-5",
                id: "auto-verify-target"
              }, typeof match$1 === "number" ? (
                  match$1 !== 0 ? statusBar(ts(undefined, undefined, "completed"), linkToComplete) : autoVerify(target, linkToComplete, match[0], match[1], addSubmissionCB, preview)
                ) : null)
        ];
}

var TargetStatus;

var make$1 = CoursesCurriculum__AutoVerify;

export {
  str ,
  tr ,
  ts ,
  TargetStatus ,
  AutoVerifySubmissionQuery ,
  redirect ,
  handleSuccess ,
  createAutoVerifySubmission ,
  completeButtonText ,
  previewLinkToComplete ,
  autoVerify ,
  statusBar ,
  completionInstructionText ,
  make$1 as make,
  
}
/* include Not a pure module */
