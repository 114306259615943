// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Cohort from "../../../shared/types/Cohort.bs.js";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as LoadingV2 from "../../../shared/types/LoadingV2.bs.js";
import * as UserProxy from "../../../shared/types/UserProxy.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Pagination from "../../../shared/utils/Pagination.bs.js";
import * as ReactUtils from "../../../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as UserDetails from "../../../shared/types/UserDetails.bs.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as Shared__Level from "../../../shared/types/Shared__Level.bs.js";
import * as SkeletonLoading from "../../../shared/components/SkeletonLoading.bs.js";
import * as RescriptReactRouter from "../../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as CourseResourcesFilter from "../../../shared/components/CourseResourcesFilter.bs.js";
import * as CoursesStudents__StudentInfo from "../types/CoursesStudents__StudentInfo.bs.js";
import * as CoursesStudents__StudentsList from "./CoursesStudents__StudentsList.bs.js";
import * as CoursesStudents__DistributionInLevel from "../types/CoursesStudents__DistributionInLevel.bs.js";
import * as CoursesStudents__StudentDistribution from "./CoursesStudents__StudentDistribution.bs.js";

import "./CoursesStudents__Root.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesStudents__Root";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var partial_arg$1 = "shared";

function ts(param, param$1, param$2) {
  return I18n.t(partial_arg$1, param, param$1, param$2);
}

var Item = {};

var PagedStudents = Pagination.Make(Item);

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* UnsetSearchString */0 :
          return {
                  loading: state.loading,
                  students: state.students,
                  filterInput: "",
                  totalEntriesCount: state.totalEntriesCount,
                  reloadDistributionAt: state.reloadDistributionAt,
                  studentDistribution: state.studentDistribution
                };
      case /* BeginLoadingMore */1 :
          return {
                  loading: /* LoadingMore */0,
                  students: state.students,
                  filterInput: state.filterInput,
                  totalEntriesCount: state.totalEntriesCount,
                  reloadDistributionAt: state.reloadDistributionAt,
                  studentDistribution: state.studentDistribution
                };
      case /* BeginReloading */2 :
          return {
                  loading: LoadingV2.setReloading(state.loading),
                  students: state.students,
                  filterInput: state.filterInput,
                  totalEntriesCount: state.totalEntriesCount,
                  reloadDistributionAt: Caml_option.some(new Date()),
                  studentDistribution: state.studentDistribution
                };
      
    }
  } else {
    if (action.TAG === /* UpdateFilterInput */0) {
      return {
              loading: state.loading,
              students: state.students,
              filterInput: action._0,
              totalEntriesCount: state.totalEntriesCount,
              reloadDistributionAt: state.reloadDistributionAt,
              studentDistribution: state.studentDistribution
            };
    }
    var students = action._2;
    var match = state.loading;
    var updatedStudent = match ? students : Curry._1(PagedStudents.toArray, state.students).concat(students);
    return {
            loading: LoadingV2.setNotLoading(state.loading),
            students: Curry._3(PagedStudents.make, updatedStudent, action._1, action._0),
            filterInput: state.filterInput,
            totalEntriesCount: action._3,
            reloadDistributionAt: undefined,
            studentDistribution: Belt_Option.getWithDefault(action._4, state.studentDistribution)
          };
  }
}

var Raw = {};

var query = "query StudentsFromCoursesStudentsRootQuery($courseId: ID!, $after: String, $filterString: String, $skipIfLoadingMore: Boolean!)  {\ncourseStudents(courseId: $courseId, filterString: $filterString, first: 20, after: $after)  {\nnodes  {\nid  \ntaggings  \nuser  {\n...UserDetailsFragment   \n}\n\nlevel  {\n...LevelFragment   \n}\n\ncohort  {\n...CohortFragment   \n}\n\npersonalCoaches  {\n...UserProxyFragment   \n}\n\ndroppedOutAt  \n}\n\npageInfo  {\nendCursor  \nhasNextPage  \n}\n\ntotalCount  \n}\n\nstudentDistribution(courseId: $courseId, filterString: $filterString) @skip(if: $skipIfLoadingMore) {\nid  \nnumber  \nfilterName  \nstudentsInLevel  \nunlocked  \n}\n\n}\n" + Cohort.Fragment.query + Shared__Level.Fragment.query + UserDetails.Fragment.query + UserProxy.Fragment.query;

function parse(value) {
  var value$1 = value.courseStudents;
  var value$2 = value$1.nodes;
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.endCursor;
  var value$5 = value.studentDistribution;
  return {
          courseStudents: {
            nodes: value$2.map(function (value) {
                  var value$1 = value.taggings;
                  var value$2 = value.user;
                  var value$3 = value.level;
                  var value$4 = value.cohort;
                  var value$5 = value.personalCoaches;
                  var value$6 = value.droppedOutAt;
                  return {
                          id: value.id,
                          taggings: value$1.map(function (value) {
                                return value;
                              }),
                          user: UserDetails.Fragment.verifyArgsAndParse("UserDetailsFragment", value$2),
                          level: Shared__Level.Fragment.verifyArgsAndParse("LevelFragment", value$3),
                          cohort: Cohort.Fragment.verifyArgsAndParse("CohortFragment", value$4),
                          personalCoaches: value$5.map(function (value) {
                                return UserProxy.Fragment.verifyArgsAndParse("UserProxyFragment", value);
                              }),
                          droppedOutAt: !(value$6 == null) ? Caml_option.some(value$6) : undefined
                        };
                }),
            pageInfo: {
              endCursor: !(value$4 == null) ? value$4 : undefined,
              hasNextPage: value$3.hasNextPage
            },
            totalCount: value$1.totalCount
          },
          studentDistribution: !(value$5 == null) ? value$5.map(function (value) {
                  return {
                          id: value.id,
                          number: value.number,
                          filterName: value.filterName,
                          studentsInLevel: value.studentsInLevel,
                          unlocked: value.unlocked
                        };
                }) : undefined
        };
}

function serialize(value) {
  var value$1 = value.studentDistribution;
  var studentDistribution = value$1 !== undefined ? value$1.map(function (value) {
          var value$1 = value.unlocked;
          var value$2 = value.studentsInLevel;
          var value$3 = value.filterName;
          var value$4 = value.number;
          var value$5 = value.id;
          return {
                  id: value$5,
                  number: value$4,
                  filterName: value$3,
                  studentsInLevel: value$2,
                  unlocked: value$1
                };
        }) : null;
  var value$2 = value.courseStudents;
  var value$3 = value$2.totalCount;
  var value$4 = value$2.pageInfo;
  var value$5 = value$4.hasNextPage;
  var value$6 = value$4.endCursor;
  var endCursor = value$6 !== undefined ? value$6 : null;
  var pageInfo = {
    endCursor: endCursor,
    hasNextPage: value$5
  };
  var value$7 = value$2.nodes;
  var nodes = value$7.map(function (value) {
        var value$1 = value.droppedOutAt;
        var droppedOutAt = value$1 !== undefined ? Caml_option.valFromOption(value$1) : null;
        var value$2 = value.personalCoaches;
        var personalCoaches = value$2.map(function (value) {
              return UserProxy.Fragment.serialize(value);
            });
        var value$3 = value.cohort;
        var cohort = Cohort.Fragment.serialize(value$3);
        var value$4 = value.level;
        var level = Shared__Level.Fragment.serialize(value$4);
        var value$5 = value.user;
        var user = UserDetails.Fragment.serialize(value$5);
        var value$6 = value.taggings;
        var taggings = value$6.map(function (value) {
              return value;
            });
        var value$7 = value.id;
        return {
                id: value$7,
                taggings: taggings,
                user: user,
                level: level,
                cohort: cohort,
                personalCoaches: personalCoaches,
                droppedOutAt: droppedOutAt
              };
      });
  var courseStudents = {
    nodes: nodes,
    pageInfo: pageInfo,
    totalCount: value$3
  };
  return {
          courseStudents: courseStudents,
          studentDistribution: studentDistribution
        };
}

function serializeVariables(inp) {
  var a = inp.after;
  var a$1 = inp.filterString;
  return {
          courseId: inp.courseId,
          after: a !== undefined ? a : undefined,
          filterString: a$1 !== undefined ? a$1 : undefined,
          skipIfLoadingMore: inp.skipIfLoadingMore
        };
}

function makeVariables(courseId, after, filterString, skipIfLoadingMore, param) {
  return {
          courseId: courseId,
          after: after,
          filterString: filterString,
          skipIfLoadingMore: skipIfLoadingMore
        };
}

var StudentsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include.$$fetch;

var StudentsQuery_Graphql_error = include.Graphql_error;

var StudentsQuery_decodeNotification = include.decodeNotification;

var StudentsQuery_decodeObject = include.decodeObject;

var StudentsQuery_decodeNotifications = include.decodeNotifications;

var StudentsQuery_decodeErrors = include.decodeErrors;

var StudentsQuery_flashNotifications = include.flashNotifications;

var StudentsQuery_sendQuery = include.sendQuery;

var StudentsQuery_query = include.query;

var StudentsQuery_make = include.make;

var StudentsQuery = {
  StudentsQuery_inner: StudentsQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: StudentsQuery_Graphql_error,
  decodeNotification: StudentsQuery_decodeNotification,
  decodeObject: StudentsQuery_decodeObject,
  decodeNotifications: StudentsQuery_decodeNotifications,
  decodeErrors: StudentsQuery_decodeErrors,
  flashNotifications: StudentsQuery_flashNotifications,
  sendQuery: StudentsQuery_sendQuery,
  query: StudentsQuery_query,
  $$fetch: $$fetch,
  make: StudentsQuery_make
};

function getStudents(send, courseId, cursor, loadingMoreOpt, params) {
  var loadingMore = loadingMoreOpt !== undefined ? loadingMoreOpt : false;
  params.append("status", "active");
  params.append("request_origin", "review_interface");
  var filterString = params.toString();
  Curry._3($$fetch, undefined, undefined, makeVariables(courseId, cursor, filterString, loadingMore, undefined)).then(function (response) {
        var nodes = response.courseStudents.nodes;
        var students = nodes.map(function (s) {
              return CoursesStudents__StudentInfo.make(s.id, UserDetails.makeFromFragment(s.user), s.taggings, Shared__Level.makeFromFragment(s.level), Cohort.makeFromFragment(s.cohort), Belt_Option.map(s.droppedOutAt, DateFns.decodeISO), s.personalCoaches.map(UserProxy.makeFromFragment));
            });
        var studentDistribution = Belt_Option.map(response.studentDistribution, (function (p) {
                return p.map(function (d) {
                            return CoursesStudents__DistributionInLevel.make(d.id, d.number, d.studentsInLevel, d.unlocked, d.filterName);
                          });
              }));
        Curry._1(send, {
              TAG: /* LoadStudents */1,
              _0: response.courseStudents.pageInfo.endCursor,
              _1: response.courseStudents.pageInfo.hasNextPage,
              _2: students,
              _3: response.courseStudents.totalCount,
              _4: studentDistribution
            });
        return Promise.resolve(undefined);
      });
  
}

function applicableLevels(levels) {
  return levels.filter(function (level) {
              return Shared__Level.number(level) !== 0;
            });
}

function makeFilters(param) {
  return [
          CourseResourcesFilter.makeFilter("cohort", "Cohort", {
                TAG: /* DataLoad */0,
                _0: "Cohort"
              }, "green"),
          CourseResourcesFilter.makeFilter("level", "Level", {
                TAG: /* DataLoad */0,
                _0: "Level"
              }, "yellow"),
          CourseResourcesFilter.makeFilter("personal_coach", "Personal Coach", {
                TAG: /* DataLoad */0,
                _0: "Coach"
              }, "pink"),
          CourseResourcesFilter.makeFilter("student_tags", "Student Tags", {
                TAG: /* DataLoad */0,
                _0: "StudentTag"
              }, "focusColor"),
          CourseResourcesFilter.makeFilter("user_tags", "User Tags", {
                TAG: /* DataLoad */0,
                _0: "UserTag"
              }, "blue"),
          CourseResourcesFilter.makeFilter("email", "Search by Email", /* Search */0, "gray"),
          CourseResourcesFilter.makeFilter("name", "Search by Name", /* Search */0, "gray")
        ];
}

function computeInitialState(param) {
  return {
          loading: LoadingV2.empty(undefined),
          students: /* Unloaded */0,
          filterInput: "",
          totalEntriesCount: 0,
          reloadDistributionAt: undefined,
          studentDistribution: []
        };
}

function reloadStudents(courseId, params, send) {
  Curry._1(send, /* BeginReloading */2);
  return getStudents(send, courseId, undefined, undefined, params);
}

function onSelect(key, value, params) {
  params.set(key, value);
  return RescriptReactRouter.push("?" + params.toString());
}

function selectLevel(levels, params, levelId) {
  var level = ArrayUtils.unsafeFind((function (level) {
          return Shared__Level.id(level) === levelId;
        }), tr(undefined, undefined, "not_found") + levelId, levels);
  return onSelect("level", Shared__Level.name(level), params);
}

function showStudents(state, students) {
  return React.createElement("div", undefined, React.createElement(CoursesStudents__StudentsList.make, {
                  students: students
                }), Curry._4(PagedStudents.showStats, state.totalEntriesCount, students.length, "student", "students"));
}

function CoursesStudents__Root(Props) {
  var courseId = Props.courseId;
  var match = React.useReducer(reducer, computeInitialState(undefined));
  var send = match[1];
  var state = match[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var params = new URLSearchParams(url.search);
  React.useEffect((function () {
          reloadStudents(courseId, params, send);
          
        }), [
        courseId,
        url.search
      ]);
  var students = state.students;
  var tmp;
  if (typeof students === "number") {
    tmp = SkeletonLoading.multiple(10, SkeletonLoading.userCard(undefined));
  } else if (students.TAG === /* PartiallyLoaded */0) {
    var cursor = students._1;
    var times = state.loading;
    tmp = React.createElement("div", undefined, showStudents(state, students._0), times ? ReactUtils.nullUnless(React.createElement("button", {
                    className: "btn btn-primary-ghost cursor-pointer w-full mt-4",
                    onClick: (function (param) {
                        Curry._1(send, /* BeginLoadingMore */1);
                        return getStudents(send, courseId, cursor, true, params);
                      })
                  }, ts(undefined, undefined, "load_more")), ArrayUtils.isEmpty(times._0)) : SkeletonLoading.multiple(3, SkeletonLoading.card(undefined, undefined)));
  } else {
    tmp = showStudents(state, students._0);
  }
  return React.createElement("div", {
              "aria-label": "Students",
              className: "flex-1 flex flex-col overflow-y-auto",
              role: "main"
            }, React.createElement("div", {
                  className: "max-w-4xl 2xl:max-w-5xl mx-auto w-full mt-8 md:mt-25 px-4"
                }, React.createElement("div", {
                      className: "bg-gray-50 w-full"
                    }, React.createElement("h1", {
                          className: "text-2xl font-semibold"
                        }, "Students"), ReactUtils.nullIf(React.createElement("div", {
                              className: "p-5 bg-gray-100 rounded-lg mt-6"
                            }, React.createElement("p", {
                                  className: "text-gray-600 text-sm font-medium"
                                }, tr(undefined, undefined, "level_distribution_label")), React.createElement(CoursesStudents__StudentDistribution.make, {
                                  studentDistribution: state.studentDistribution,
                                  params: params
                                })), params.has("level")), React.createElement("div", {
                          className: "p-5 mt-6 bg-white rounded-md border border-gray-300 relative md:sticky md:top-16 z-10"
                        }, React.createElement(CourseResourcesFilter.make, {
                              courseId: courseId,
                              filters: makeFilters(undefined),
                              search: url.search,
                              sorter: CourseResourcesFilter.makeSorter("sort_by", [
                                    "Name",
                                    "First Created",
                                    "Last Created",
                                    "First Updated",
                                    "Last Updated"
                                  ], "Last Created")
                            })), React.createElement("div", {
                          className: "my-6"
                        }, tmp)), Curry._2(PagedStudents.showLoading, state.students, state.loading)));
}

var UserDetailsFragment;

var LevelFragment;

var CohortFragment;

var UserProxyFragment;

var make = CoursesStudents__Root;

export {
  str ,
  tr ,
  ts ,
  Item ,
  PagedStudents ,
  reducer ,
  UserDetailsFragment ,
  LevelFragment ,
  CohortFragment ,
  UserProxyFragment ,
  StudentsQuery ,
  getStudents ,
  applicableLevels ,
  makeFilters ,
  computeInitialState ,
  reloadStudents ,
  onSelect ,
  selectLevel ,
  showStudents ,
  make ,
  
}
/*  Not a pure module */
