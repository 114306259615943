// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Avatar from "../../../shared/Avatar.bs.js";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as Dropdown2 from "../../../shared/components/Dropdown2.bs.js";
import * as ReactUtils from "../../../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as AppRouter__User from "../types/AppRouter__User.bs.js";
import * as AppRouter__School from "../types/AppRouter__School.bs.js";
import * as AppRouter__Dropdown from "./AppRouter__Dropdown.bs.js";
import * as Notifications__Root from "../../../notifications/Notifications__Root.bs.js";
import * as Layout__UserControls from "../../shared/Layout__UserControls.bs.js";

import "./AppRouter__Header.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "components.AppRouter__Header";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function showLink(icon, text, href) {
  return React.createElement("div", {
              key: href,
              className: "whitespace-nowrap"
            }, React.createElement("a", {
                  className: "cursor-pointer block p-3 text-xs font-semibold text-gray-900 border-b border-gray-50 bg-white hover:text-primary-500 hover:bg-gray-50 focus:outline-none focus:text-primary-500 focus:bg-gray-50",
                  href: href,
                  rel: "nofollow"
                }, React.createElement(FaIcon.make, {
                      classes: "fas fw fa-" + icon
                    }), React.createElement("span", {
                      className: "pl-2"
                    }, text)));
}

function links(param) {
  return [
          showLink("edit", t(undefined, undefined, "edit_profile"), "/user/edit"),
          showLink("power-off", t(undefined, undefined, "sign_out"), "/users/sign_out")
        ];
}

function selected(user) {
  return React.createElement("button", {
              className: "md:hidden md:ml-2 h-10 w-10 rounded-full border-2 border-gray-300 hover:border-primary-500 focus:border-primary-500",
              title: "Show user controls"
            }, Belt_Option.mapWithDefault(AppRouter__User.avatarUrl(user), React.createElement(Avatar.make, {
                      name: AppRouter__User.name(user),
                      className: "inline-block object-contain rounded-full text-tiny"
                    }), (function (src) {
                    return React.createElement("img", {
                                className: "inline-block object-contain rounded-full text-tiny",
                                alt: AppRouter__User.name(user),
                                src: src
                              });
                  })));
}

function renderLinks(user) {
  return React.createElement(Dropdown2.make, {
              selected: selected(user),
              contents: links(undefined),
              right: true,
              key: "links-dropdown"
            });
}

function headerLink(key, link) {
  var tmp = {
    className: "no-underline bg-gray-50 md:bg-white hover:bg-gray-50 text-gray-900 rounded-lg hover:text-primary-500 w-full p-4 md:px-3 md:py-2",
    href: AppRouter__School.linkUrl(link)
  };
  var tmp$1 = AppRouter__School.localLinks(link) ? undefined : "noopener";
  if (tmp$1 !== undefined) {
    tmp.rel = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = AppRouter__School.localLinks(link) ? undefined : "_blank";
  if (tmp$2 !== undefined) {
    tmp.target = Caml_option.valFromOption(tmp$2);
  }
  return React.createElement("div", {
              key: key,
              className: "md:ml-2 text-sm font-semibold text-center cursor-default flex w-1/2 sm:w-1/3 md:w-auto justify-center"
            }, React.createElement("a", tmp, AppRouter__School.linkTitle(link)));
}

function signOutLink(param) {
  return React.createElement("div", {
              key: "Logout-button",
              className: "md:ml-2 text-sm font-semibold cursor-default flex w-1/2 sm:w-1/3 md:w-auto justify-center"
            }, React.createElement("div", {
                  className: "flex items-center justify-center"
                }, React.createElement("a", {
                      className: "border border-primary-500 rounded px-2 py-1 text-primary-500 text-xs md:text-sm md:leading-normal m-4 md:m-0 no-underline font-semibold",
                      href: "/users/sign_out",
                      rel: "nofollow"
                    }, React.createElement(FaIcon.make, {
                          classes: "fas fa-power-off"
                        }), React.createElement("span", {
                          className: "ml-2"
                        }, t(undefined, undefined, "sign_out")))));
}

function signInLink(param) {
  return React.createElement("div", {
              key: "SignIn-button",
              className: "md:ml-2 text-sm font-semibold cursor-default flex w-1/2 sm:w-1/3 md:w-auto justify-center"
            }, React.createElement("div", {
                  className: "flex items-center justify-center"
                }, React.createElement("a", {
                      className: "border border-primary-500 rounded px-2 py-1 text-primary-500 text-xs md:text-sm md:leading-normal m-4 md:m-0 no-underline font-semibold",
                      href: "/users/sign_in"
                    }, React.createElement(FaIcon.make, {
                          classes: "fas fa-power-off"
                        }), React.createElement("span", {
                          className: "ml-2"
                        }, t(undefined, undefined, "sign_in")))));
}

function notificationButton(hasNotifications) {
  return React.createElement(Notifications__Root.make, {
              wrapperClasses: "relative md:ml-1 pt-1 md:pt-0 text-sm font-semibold cursor-default flex w-8 h-8 md:w-9 md:h-9 justify-center items-center rounded-lg hover:bg-gray-50",
              buttonClasses: "font-semibold text-gray-900 hover:text-primary-500 w-full flex items-center justify-center ",
              iconClasses: "app-router-header__notifications-unread-bullet",
              hasNotifications: hasNotifications,
              key: "notifications-button"
            });
}

function isMobile(param) {
  return window.innerWidth < 768;
}

function headerLinks(links, isLoggedIn, user, hasNotifications) {
  var match = $$Array.to_list(links);
  var match$1 = window.innerWidth < 768;
  var match$2;
  if (match$1) {
    match$2 = [
      $$Array.of_list(match),
      []
    ];
  } else if (match) {
    var match$3 = match.tl;
    if (match$3) {
      var match$4 = match$3.tl;
      if (match$4) {
        var match$5 = match$4.tl;
        if (match$5) {
          var match$6 = match$5.tl;
          match$2 = match$6 ? [
              [
                match.hd,
                match$3.hd,
                match$4.hd
              ],
              [
                  match$5.hd,
                  match$6.hd
                ].concat($$Array.of_list(match$6.tl))
            ] : [
              $$Array.of_list(match),
              []
            ];
        } else {
          match$2 = [
            $$Array.of_list(match),
            []
          ];
        }
      } else {
        match$2 = [
          $$Array.of_list(match),
          []
        ];
      }
    } else {
      match$2 = [
        $$Array.of_list(match),
        []
      ];
    }
  } else {
    match$2 = [
      $$Array.of_list(match),
      []
    ];
  }
  var match$7 = window.innerWidth < 768;
  return match$2[0].map(function (l, index) {
                    return headerLink(String(index), l);
                  }).concat([React.createElement(AppRouter__Dropdown.make, {
                        links: match$2[1],
                        key: "more-links"
                      })]).concat([ReactUtils.nullUnless(notificationButton(hasNotifications), isLoggedIn && window.innerWidth >= 768)]).concat([user !== undefined ? (
                  match$7 ? signOutLink(undefined) : renderLinks(user)
                ) : signInLink(undefined)]);
}

function AppRouter__Header(Props) {
  var school = Props.school;
  var currentUser = Props.currentUser;
  var match = React.useState(function () {
        return window.innerWidth < 768;
      });
  var toggleMenuHidden = match[1];
  var menuHidden = match[0];
  React.useEffect(function () {
        var resizeCB = function (param) {
          return Curry._1(toggleMenuHidden, (function (param) {
                        return window.innerWidth < 768;
                      }));
        };
        window.addEventListener("resize", resizeCB);
        
      });
  var isLoggedIn = Belt_Option.isSome(currentUser);
  var hasNotifications = Belt_Option.mapWithDefault(currentUser, false, AppRouter__User.hasNotifications);
  var url = AppRouter__School.logoUrl(school);
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "mx-auto"
                }, React.createElement("nav", {
                      className: "flex justify-between items-center"
                    }, React.createElement("div", {
                          className: "flex w-full items-center justify-between"
                        }, React.createElement("a", {
                              className: "max-w-sm focus-within:outline-none",
                              href: Belt_Option.isSome(currentUser) ? "/dashboard" : "/"
                            }, url !== undefined ? React.createElement("img", {
                                    className: "h-9 md:h-12 object-contain flex text-sm items-center",
                                    alt: "Logo of " + AppRouter__School.name(school),
                                    src: url
                                  }) : React.createElement("div", {
                                    className: "p-2 rounded-lg bg-white text-gray-900 hover:bg-gray-50 hover:text-primary-600"
                                  }, React.createElement("span", {
                                        className: "text-xl font-bold leading-tight"
                                      }, AppRouter__School.name(school)))), ReactUtils.nullUnless(React.createElement("div", {
                                  className: "flex items-center space-x-2"
                                }, ReactUtils.nullUnless(notificationButton(hasNotifications), isLoggedIn), React.createElement("div", {
                                      onClick: (function (param) {
                                          return Curry._1(toggleMenuHidden, (function (menuHidden) {
                                                        return !menuHidden;
                                                      }));
                                        })
                                    }, React.createElement("div", {
                                          className: "app-router-header__menu-btn cursor-pointer hover:bg-gray-50 w-8 h-8 text-center relative focus:outline-none rounded-lg " + (
                                            menuHidden ? "" : "open"
                                          )
                                        }, React.createElement("span", {
                                              className: "app-router-header__menu-icon"
                                            }, React.createElement("span", {
                                                  className: "app-router-header__menu-icon-bar"
                                                }))))), window.innerWidth < 768)), !menuHidden && window.innerWidth >= 768 ? React.createElement("div", {
                            className: "relative flex justify-end items-center w-3/5 lg:w-3/4 flex-nowrap shrink-0 transition"
                          }, headerLinks(AppRouter__School.links(school), isLoggedIn, currentUser, hasNotifications), React.createElement(Layout__UserControls.make, {
                                user: currentUser
                              })) : null)), window.innerWidth < 768 && !menuHidden ? React.createElement("div", {
                    className: "relative mt-2 flex flex-row w-full flex-wrap bg-gray-50 rounded-lg shadow-lg transition"
                  }, headerLinks(AppRouter__School.links(school), isLoggedIn, currentUser, hasNotifications)) : null);
}

var make = AppRouter__Header;

export {
  str ,
  t ,
  showLink ,
  links ,
  selected ,
  renderLinks ,
  headerLink ,
  signOutLink ,
  signInLink ,
  notificationButton ,
  isMobile ,
  headerLinks ,
  make ,
  
}
/*  Not a pure module */
