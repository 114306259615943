// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../shared/utils/I18n.bs.js";
import * as $$Array from "../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../shared/components/FaIcon.bs.js";
import * as PfIcon from "../packages/pf-icon/src/PfIcon.bs.js";
import * as Dropdown from "../shared/components/Dropdown.bs.js";
import * as ArrayUtils from "../shared/utils/ArrayUtils.bs.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as WindowUtils from "../shared/utils/WindowUtils.bs.js";
import * as GraphqlQuery from "../shared/utils/GraphqlQuery.bs.js";
import * as TopicCategory from "../shared/types/TopicCategory.bs.js";
import * as DisablingCover from "../shared/components/DisablingCover.bs.js";
import * as TopicsShow__Post from "./types/TopicsShow__Post.bs.js";
import * as TopicsShow__Topic from "./types/TopicsShow__Topic.bs.js";
import * as TopicsShow__PostShow from "./components/TopicsShow__PostShow.bs.js";
import * as TopicsShow__Community from "./types/TopicsShow__Community.bs.js";
import * as TopicsShow__PostEditor from "./components/TopicsShow__PostEditor.bs.js";
import * as TopicsShow__LinkedTarget from "./types/TopicsShow__LinkedTarget.bs.js";
import * as TopicsShow__SubscriptionManager from "./components/TopicsShow__SubscriptionManager.bs.js";

import "./TopicsShow__Root.css"
;

var partial_arg = "components.TopicsShow__Root";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var partial_arg$1 = "shared";

function ts(param, param$1, param$2) {
  return I18n.t(partial_arg$1, param, param$1, param$2);
}

function str(prim) {
  return prim;
}

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* LikeFirstPost */0 :
          return {
                  topic: state.topic,
                  firstPost: TopicsShow__Post.addLike(state.firstPost),
                  replies: state.replies,
                  replyToPostId: state.replyToPostId,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* RemoveLikeFromFirstPost */1 :
          return {
                  topic: state.topic,
                  firstPost: TopicsShow__Post.removeLike(state.firstPost),
                  replies: state.replies,
                  replyToPostId: state.replyToPostId,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* RemoveReplyToPost */2 :
          return {
                  topic: state.topic,
                  firstPost: state.firstPost,
                  replies: state.replies,
                  replyToPostId: undefined,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* UnmarkReplyAsSolution */3 :
          return {
                  topic: state.topic,
                  firstPost: state.firstPost,
                  replies: TopicsShow__Post.unmarkSolution(state.replies),
                  replyToPostId: state.replyToPostId,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* StartChangingLockStatus */4 :
          return {
                  topic: state.topic,
                  firstPost: state.firstPost,
                  replies: state.replies,
                  replyToPostId: state.replyToPostId,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: true,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* FinishUnlockingTopic */5 :
          return {
                  topic: TopicsShow__Topic.unlock(state.topic),
                  firstPost: state.firstPost,
                  replies: state.replies,
                  replyToPostId: state.replyToPostId,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: false,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* Subscribe */6 :
          return {
                  topic: state.topic,
                  firstPost: state.firstPost,
                  replies: state.replies,
                  replyToPostId: state.replyToPostId,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: true
                };
      case /* Unsubscribe */7 :
          return {
                  topic: state.topic,
                  firstPost: state.firstPost,
                  replies: state.replies,
                  replyToPostId: state.replyToPostId,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: false
                };
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* SaveReply */0 :
          var replyToPostId = action._1;
          var newReply = action._0;
          if (replyToPostId === undefined) {
            return {
                    topic: state.topic,
                    firstPost: state.firstPost,
                    replies: $$Array.append([newReply], state.replies),
                    replyToPostId: state.replyToPostId,
                    topicTitle: state.topicTitle,
                    savingTopic: state.savingTopic,
                    changingLockedStatus: state.changingLockedStatus,
                    showTopicEditor: state.showTopicEditor,
                    topicCategory: state.topicCategory,
                    subscribed: state.subscribed
                  };
          }
          var updatedParentPost = TopicsShow__Post.addReply(TopicsShow__Post.id(newReply), TopicsShow__Post.find(replyToPostId, state.replies));
          return {
                  topic: state.topic,
                  firstPost: state.firstPost,
                  replies: $$Array.append([
                        newReply,
                        updatedParentPost
                      ], state.replies.filter(function (r) {
                            return TopicsShow__Post.id(r) !== replyToPostId;
                          })),
                  replyToPostId: undefined,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* AddNewReply */1 :
          return {
                  topic: state.topic,
                  firstPost: state.firstPost,
                  replies: state.replies,
                  replyToPostId: action._0,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* LikeReply */2 :
          var post = action._0;
          var updatedPost = TopicsShow__Post.addLike(post);
          return {
                  topic: state.topic,
                  firstPost: state.firstPost,
                  replies: $$Array.append([updatedPost], state.replies.filter(function (reply) {
                            return TopicsShow__Post.id(reply) !== TopicsShow__Post.id(post);
                          })),
                  replyToPostId: state.replyToPostId,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* RemoveLikeFromReply */3 :
          var post$1 = action._0;
          var updatedPost$1 = TopicsShow__Post.removeLike(post$1);
          return {
                  topic: state.topic,
                  firstPost: state.firstPost,
                  replies: $$Array.append([updatedPost$1], state.replies.filter(function (reply) {
                            return TopicsShow__Post.id(reply) !== TopicsShow__Post.id(post$1);
                          })),
                  replyToPostId: state.replyToPostId,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* UpdateFirstPost */4 :
          return {
                  topic: state.topic,
                  firstPost: action._0,
                  replies: state.replies,
                  replyToPostId: state.replyToPostId,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* UpdateReply */5 :
          var reply = action._0;
          return {
                  topic: state.topic,
                  firstPost: state.firstPost,
                  replies: $$Array.append([reply], state.replies.filter(function (r) {
                            return TopicsShow__Post.id(r) !== TopicsShow__Post.id(reply);
                          })),
                  replyToPostId: state.replyToPostId,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* ArchivePost */6 :
          var postId = action._0;
          return {
                  topic: state.topic,
                  firstPost: state.firstPost,
                  replies: state.replies.filter(function (r) {
                        return TopicsShow__Post.id(r) !== postId;
                      }),
                  replyToPostId: state.replyToPostId,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* UpdateTopicTitle */7 :
          return {
                  topic: state.topic,
                  firstPost: state.firstPost,
                  replies: state.replies,
                  replyToPostId: state.replyToPostId,
                  topicTitle: action._0,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* SaveTopic */8 :
          return {
                  topic: action._0,
                  firstPost: state.firstPost,
                  replies: state.replies,
                  replyToPostId: state.replyToPostId,
                  topicTitle: state.topicTitle,
                  savingTopic: false,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: false,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* ShowTopicEditor */9 :
          return {
                  topic: state.topic,
                  firstPost: state.firstPost,
                  replies: state.replies,
                  replyToPostId: state.replyToPostId,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: action._0,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* UpdateSavingTopic */10 :
          return {
                  topic: state.topic,
                  firstPost: state.firstPost,
                  replies: state.replies,
                  replyToPostId: state.replyToPostId,
                  topicTitle: state.topicTitle,
                  savingTopic: action._0,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* MarkReplyAsSolution */11 :
          return {
                  topic: state.topic,
                  firstPost: state.firstPost,
                  replies: TopicsShow__Post.markAsSolution(action._0, state.replies),
                  replyToPostId: state.replyToPostId,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* FinishLockingTopic */12 :
          return {
                  topic: TopicsShow__Topic.lock(action._0, state.topic),
                  firstPost: state.firstPost,
                  replies: state.replies,
                  replyToPostId: state.replyToPostId,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: false,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: state.topicCategory,
                  subscribed: state.subscribed
                };
      case /* UpdateTopicCategory */13 :
          return {
                  topic: state.topic,
                  firstPost: state.firstPost,
                  replies: state.replies,
                  replyToPostId: state.replyToPostId,
                  topicTitle: state.topicTitle,
                  savingTopic: state.savingTopic,
                  changingLockedStatus: state.changingLockedStatus,
                  showTopicEditor: state.showTopicEditor,
                  topicCategory: action._0,
                  subscribed: state.subscribed
                };
      
    }
  }
}

function subscribe(send, param) {
  return Curry._1(send, /* Subscribe */6);
}

function unsubscribe(send, param) {
  return Curry._1(send, /* Unsubscribe */7);
}

function addNewReply(send, replyToPostId, param) {
  return Curry._1(send, {
              TAG: /* AddNewReply */1,
              _0: replyToPostId
            });
}

function updateReply(send, reply) {
  return Curry._1(send, {
              TAG: /* UpdateReply */5,
              _0: reply
            });
}

function updateFirstPost(send, post) {
  return Curry._1(send, {
              TAG: /* UpdateFirstPost */4,
              _0: post
            });
}

function saveReply(send, replyToPostId, reply) {
  return Curry._1(send, {
              TAG: /* SaveReply */0,
              _0: reply,
              _1: replyToPostId
            });
}

function isTopicCreator(firstPost, currentUserId) {
  return Belt_Option.mapWithDefault(TopicsShow__Post.creatorId(firstPost), false, (function (id) {
                return id === currentUserId;
              }));
}

function archiveTopic(community) {
  window.location = TopicsShow__Community.path(community);
  
}

var Raw = {};

var query = "mutation UpdateTopicMutation($id: ID!, $title: String!, $topicCategoryId: ID)  {\nupdateTopic(id: $id, title: $title, topicCategoryId: $topicCategoryId)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.updateTopic;
  return {
          updateTopic: {
            success: value$1.success
          }
        };
}

function serialize(value) {
  var value$1 = value.updateTopic;
  var value$2 = value$1.success;
  var updateTopic = {
    success: value$2
  };
  return {
          updateTopic: updateTopic
        };
}

function serializeVariables(inp) {
  var a = inp.topicCategoryId;
  return {
          id: inp.id,
          title: inp.title,
          topicCategoryId: a !== undefined ? a : undefined
        };
}

function makeVariables(id, title, topicCategoryId, param) {
  return {
          id: id,
          title: title,
          topicCategoryId: topicCategoryId
        };
}

var UpdateTopicQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include.$$fetch;

var UpdateTopicQuery_Graphql_error = include.Graphql_error;

var UpdateTopicQuery_decodeNotification = include.decodeNotification;

var UpdateTopicQuery_decodeObject = include.decodeObject;

var UpdateTopicQuery_decodeNotifications = include.decodeNotifications;

var UpdateTopicQuery_decodeErrors = include.decodeErrors;

var UpdateTopicQuery_flashNotifications = include.flashNotifications;

var UpdateTopicQuery_sendQuery = include.sendQuery;

var UpdateTopicQuery_query = include.query;

var UpdateTopicQuery_make = include.make;

var UpdateTopicQuery = {
  UpdateTopicQuery_inner: UpdateTopicQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: UpdateTopicQuery_Graphql_error,
  decodeNotification: UpdateTopicQuery_decodeNotification,
  decodeObject: UpdateTopicQuery_decodeObject,
  decodeNotifications: UpdateTopicQuery_decodeNotifications,
  decodeErrors: UpdateTopicQuery_decodeErrors,
  flashNotifications: UpdateTopicQuery_flashNotifications,
  sendQuery: UpdateTopicQuery_sendQuery,
  query: UpdateTopicQuery_query,
  $$fetch: $$fetch,
  make: UpdateTopicQuery_make
};

function updateTopic(state, send, $$event) {
  $$event.preventDefault();
  Curry._1(send, {
        TAG: /* UpdateSavingTopic */10,
        _0: true
      });
  var topicCategoryId = Belt_Option.flatMap(state.topicCategory, (function (category) {
          return TopicCategory.id(category);
        }));
  var variables_id = TopicsShow__Topic.id(state.topic);
  var variables_title = state.topicTitle;
  var variables = {
    id: variables_id,
    title: variables_title,
    topicCategoryId: topicCategoryId
  };
  Curry._3($$fetch, undefined, undefined, variables).then(function (response) {
          if (response.updateTopic.success) {
            var topic = TopicsShow__Topic.updateTitle(state.topicTitle, state.topic);
            Curry._1(send, {
                  TAG: /* SaveTopic */8,
                  _0: topic
                });
          } else {
            Curry._1(send, {
                  TAG: /* UpdateSavingTopic */10,
                  _0: false
                });
          }
          return Promise.resolve(undefined);
        }).catch(function (param) {
        Curry._1(send, {
              TAG: /* UpdateSavingTopic */10,
              _0: false
            });
        return Promise.resolve(undefined);
      });
  
}

var Raw$1 = {};

var query$1 = "mutation LockTopicMutation($id: ID!)  {\nlockTopic(id: $id)  {\nsuccess  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value.lockTopic;
  return {
          lockTopic: {
            success: value$1.success
          }
        };
}

function serialize$1(value) {
  var value$1 = value.lockTopic;
  var value$2 = value$1.success;
  var lockTopic = {
    success: value$2
  };
  return {
          lockTopic: lockTopic
        };
}

function serializeVariables$1(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$1(id, param) {
  return {
          id: id
        };
}

var LockTopicQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = GraphqlQuery.Extender({
      Raw: Raw$1,
      query: query$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch$1 = include$1.$$fetch;

var LockTopicQuery_Graphql_error = include$1.Graphql_error;

var LockTopicQuery_decodeNotification = include$1.decodeNotification;

var LockTopicQuery_decodeObject = include$1.decodeObject;

var LockTopicQuery_decodeNotifications = include$1.decodeNotifications;

var LockTopicQuery_decodeErrors = include$1.decodeErrors;

var LockTopicQuery_flashNotifications = include$1.flashNotifications;

var LockTopicQuery_sendQuery = include$1.sendQuery;

var LockTopicQuery_query = include$1.query;

var LockTopicQuery_make = include$1.make;

var LockTopicQuery = {
  LockTopicQuery_inner: LockTopicQuery_inner,
  Raw: Raw$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  Graphql_error: LockTopicQuery_Graphql_error,
  decodeNotification: LockTopicQuery_decodeNotification,
  decodeObject: LockTopicQuery_decodeObject,
  decodeNotifications: LockTopicQuery_decodeNotifications,
  decodeErrors: LockTopicQuery_decodeErrors,
  flashNotifications: LockTopicQuery_flashNotifications,
  sendQuery: LockTopicQuery_sendQuery,
  query: LockTopicQuery_query,
  $$fetch: $$fetch$1,
  make: LockTopicQuery_make
};

var Raw$2 = {};

var query$2 = "mutation UnlockTopicMutation($id: ID!)  {\nunlockTopic(id: $id)  {\nsuccess  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = value.unlockTopic;
  return {
          unlockTopic: {
            success: value$1.success
          }
        };
}

function serialize$2(value) {
  var value$1 = value.unlockTopic;
  var value$2 = value$1.success;
  var unlockTopic = {
    success: value$2
  };
  return {
          unlockTopic: unlockTopic
        };
}

function serializeVariables$2(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$2(id, param) {
  return {
          id: id
        };
}

var UnlockTopicQuery_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2
};

var include$2 = GraphqlQuery.Extender({
      Raw: Raw$2,
      query: query$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch$2 = include$2.$$fetch;

var UnlockTopicQuery_Graphql_error = include$2.Graphql_error;

var UnlockTopicQuery_decodeNotification = include$2.decodeNotification;

var UnlockTopicQuery_decodeObject = include$2.decodeObject;

var UnlockTopicQuery_decodeNotifications = include$2.decodeNotifications;

var UnlockTopicQuery_decodeErrors = include$2.decodeErrors;

var UnlockTopicQuery_flashNotifications = include$2.flashNotifications;

var UnlockTopicQuery_sendQuery = include$2.sendQuery;

var UnlockTopicQuery_query = include$2.query;

var UnlockTopicQuery_make = include$2.make;

var UnlockTopicQuery = {
  UnlockTopicQuery_inner: UnlockTopicQuery_inner,
  Raw: Raw$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2,
  Graphql_error: UnlockTopicQuery_Graphql_error,
  decodeNotification: UnlockTopicQuery_decodeNotification,
  decodeObject: UnlockTopicQuery_decodeObject,
  decodeNotifications: UnlockTopicQuery_decodeNotifications,
  decodeErrors: UnlockTopicQuery_decodeErrors,
  flashNotifications: UnlockTopicQuery_flashNotifications,
  sendQuery: UnlockTopicQuery_sendQuery,
  query: UnlockTopicQuery_query,
  $$fetch: $$fetch$2,
  make: UnlockTopicQuery_make
};

function lockTopic(topicId, currentUserId, send) {
  return WindowUtils.confirm(undefined, t(undefined, undefined, "lock_topic_confirm"), (function (param) {
                Curry._1(send, /* StartChangingLockStatus */4);
                Curry._3($$fetch$1, undefined, undefined, {
                        id: topicId
                      }).then(function (response) {
                      if (response.lockTopic.success) {
                        Curry._1(send, {
                              TAG: /* FinishLockingTopic */12,
                              _0: currentUserId
                            });
                      }
                      return Promise.resolve(undefined);
                    });
                
              }));
}

function unlockTopic(topicId, send) {
  return WindowUtils.confirm(undefined, t(undefined, undefined, "unlock_topic_confirm"), (function (param) {
                Curry._1(send, /* StartChangingLockStatus */4);
                Curry._3($$fetch$2, undefined, undefined, {
                        id: topicId
                      }).then(function (response) {
                      if (response.unlockTopic.success) {
                        Curry._1(send, /* FinishUnlockingTopic */5);
                      }
                      return Promise.resolve(undefined);
                    });
                
              }));
}

function communityLink(community) {
  return React.createElement("a", {
              className: "btn btn-subtle",
              href: TopicsShow__Community.path(community)
            }, React.createElement("i", {
                  className: "fas fa-users"
                }), React.createElement("span", {
                  className: "ml-2"
                }, TopicsShow__Community.name(community)));
}

function topicCategory(topicCategories, topicCategoryId) {
  if (topicCategoryId !== undefined) {
    return ArrayUtils.unsafeFind((function (category) {
                  return TopicCategory.id(category) === topicCategoryId;
                }), "Unable to find topic category with ID: " + topicCategoryId, topicCategories);
  }
  
}

function categoryDropdownSelected(topicCategory) {
  var tmp;
  if (topicCategory !== undefined) {
    var match = TopicCategory.color(topicCategory);
    var style = {
      backgroundColor: match[0]
    };
    tmp = React.createElement("div", {
          className: "inline-flex items-center"
        }, React.createElement("div", {
              className: "h-3 w-3 rounded mt-px",
              style: style
            }), React.createElement("span", {
              className: "ml-2"
            }, TopicCategory.name(topicCategory)));
  } else {
    tmp = ts(undefined, undefined, "none");
  }
  return React.createElement("div", {
              "aria-label": t(undefined, undefined, "selected_category"),
              className: "flex justify-between text-sm bg-white border border-gray-300 rounded py-1 px-3 mt-1 focus:outline-none focus:bg-white focus:border-primary-300 cursor-pointer"
            }, tmp, React.createElement(FaIcon.make, {
                  classes: "ml-4 fas fa-caret-down"
                }));
}

function topicCategorySelector(send, selectedTopicCategory, availableTopicCategories) {
  var selectableTopicCategories = Belt_Option.mapWithDefault(selectedTopicCategory, availableTopicCategories, (function (topicCategory) {
          return availableTopicCategories.filter(function (availableTopicCategory) {
                      return TopicCategory.id(availableTopicCategory) !== TopicCategory.id(topicCategory);
                    });
        }));
  var topicCategoryList = selectableTopicCategories.map(function (topicCategory) {
        var match = TopicCategory.color(topicCategory);
        var style = {
          backgroundColor: match[0]
        };
        var categoryName = TopicCategory.name(topicCategory);
        return React.createElement("div", {
                    "aria-label": t(undefined, undefined, "select_category") + " " + categoryName,
                    className: "px-3 py-2 font-normal flex items-center",
                    onClick: (function (param) {
                        return Curry._1(send, {
                                    TAG: /* UpdateTopicCategory */13,
                                    _0: topicCategory
                                  });
                      })
                  }, React.createElement("div", {
                        className: "w-3 h-3 rounded mt-px",
                        style: style
                      }), React.createElement("span", {
                        className: "ml-2"
                      }, categoryName));
      });
  if (selectedTopicCategory !== undefined) {
    return [React.createElement("div", {
                    "aria-label": t(undefined, undefined, "select_no_category"),
                    className: "px-3 py-2 font-normal flex items-center",
                    onClick: (function (param) {
                        return Curry._1(send, {
                                    TAG: /* UpdateTopicCategory */13,
                                    _0: undefined
                                  });
                      })
                  }, React.createElement("div", {
                        className: "w-3 h-3 rounded bg-gray-300 mt-px"
                      }), React.createElement("span", {
                        className: "ml-2"
                      }, ts(undefined, undefined, "none")))].concat(topicCategoryList);
  } else {
    return topicCategoryList;
  }
}

function topicSolutionId(replies) {
  return Belt_Option.map(Caml_option.undefined_to_opt(replies.find(TopicsShow__Post.solution)), TopicsShow__Post.id);
}

function TopicsShow__Root(Props) {
  var topic = Props.topic;
  var firstPost = Props.firstPost;
  var replies = Props.replies;
  var users = Props.users;
  var currentUserId = Props.currentUserId;
  var moderator = Props.moderator;
  var community = Props.community;
  var target = Props.target;
  var topicCategories = Props.topicCategories;
  var subscribed = Props.subscribed;
  var match = React.useReducer(reducer, topic, (function (topic) {
          return {
                  topic: topic,
                  firstPost: firstPost,
                  replies: replies,
                  replyToPostId: undefined,
                  topicTitle: TopicsShow__Topic.title(topic),
                  savingTopic: false,
                  changingLockedStatus: false,
                  showTopicEditor: false,
                  topicCategory: topicCategory(topicCategories, TopicsShow__Topic.topicCategoryId(topic)),
                  subscribed: subscribed
                };
        }));
  var send = match[1];
  var state = match[0];
  var tmp;
  if (target !== undefined) {
    var id = TopicsShow__LinkedTarget.id(target);
    tmp = React.createElement("div", {
          className: "max-w-4xl w-full mt-5 lg:x-4 mx-auto"
        }, React.createElement("div", {
              className: "flex py-4 px-4 md:px-5 mx-3 lg:mx-0 bg-white border border-primary-500 shadow-md rounded-lg justify-between items-center"
            }, React.createElement("p", {
                  className: "w-3/5 md:w-4/5 text-sm"
                }, React.createElement("span", {
                      className: "font-semibold block text-xs"
                    }, t(undefined, undefined, "linked_target_label")), React.createElement("span", undefined, TopicsShow__LinkedTarget.title(target))), id !== undefined ? React.createElement("a", {
                    className: "btn btn-default",
                    href: "/targets/" + id
                  }, t(undefined, undefined, "view_target_button")) : null));
  } else {
    tmp = null;
  }
  var tmp$1;
  if (state.showTopicEditor) {
    tmp$1 = React.createElement(DisablingCover.make, {
          disabled: state.savingTopic,
          children: React.createElement("div", {
                className: "flex flex-col lg:ml-14 bg-gray-50 p-2 rounded border border-primary-200"
              }, React.createElement("input", {
                    className: "appearance-none block w-full bg-white text-gray-900 font-semibold border border-gray-300 rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                    type: "text",
                    value: state.topicTitle,
                    onChange: (function ($$event) {
                        return Curry._1(send, {
                                    TAG: /* UpdateTopicTitle */7,
                                    _0: $$event.target.value
                                  });
                      })
                  }), React.createElement("div", {
                    className: "flex flex-col md:flex-row md:justify-between md:items-end"
                  }, React.createElement("div", {
                        className: "flex flex-col items-left shrink-0"
                      }, React.createElement("span", {
                            className: "inline-block text-gray-600 text-tiny font-semibold mr-2"
                          }, t(undefined, undefined, "topic_category_label")), React.createElement(Dropdown.make, {
                            selected: categoryDropdownSelected(state.topicCategory),
                            contents: topicCategorySelector(send, state.topicCategory, topicCategories),
                            className: ""
                          })), React.createElement("div", {
                        className: "flex justify-end pt-4 md:pt-0"
                      }, React.createElement("button", {
                            className: "btn btn-subtle mr-3",
                            onClick: (function (param) {
                                return Curry._1(send, {
                                            TAG: /* ShowTopicEditor */9,
                                            _0: false
                                          });
                              })
                          }, t(undefined, undefined, "topic_editor_cancel_button")), React.createElement("button", {
                            className: "btn btn-primary",
                            disabled: state.topicTitle.trim() === "",
                            onClick: (function (param) {
                                return updateTopic(state, send, param);
                              })
                          }, t(undefined, undefined, "update_topic_button")))))
        });
  } else {
    var isLocked = Belt_Option.isSome(TopicsShow__Topic.lockedAt(state.topic));
    var topicId = TopicsShow__Topic.id(state.topic);
    var topicCategory$1 = state.topicCategory;
    var tmp$2;
    if (topicCategory$1 !== undefined) {
      var match$1 = TopicCategory.color(topicCategory$1);
      var style = {
        backgroundColor: match$1[0]
      };
      tmp$2 = React.createElement("div", {
            className: "py-2 flex items-center lg:pl-14 text-xs font-semibold"
          }, React.createElement("div", {
                className: "w-3 h-3 rounded",
                style: style
              }), React.createElement("span", {
                className: "ml-2"
              }, TopicCategory.name(topicCategory$1)));
    } else {
      tmp$2 = null;
    }
    tmp$1 = React.createElement("div", {
          className: "flex flex-col "
        }, React.createElement("div", {
              className: "topics-show__title-container flex items-center md:items-start justify-between mb-2"
            }, React.createElement("h3", {
                  "aria-label": t(undefined, undefined, "topic_title"),
                  className: "leading-snug lg:pl-14 text-base lg:text-2xl w-9/12"
                }, TopicsShow__Topic.title(state.topic)), React.createElement("span", {
                  className: "flex"
                }, moderator || isTopicCreator(firstPost, currentUserId) ? React.createElement("button", {
                        className: "topics-show__title-edit-button inline-flex items-center font-semibold p-2 md:py-1 bg-gray-50 hover:bg-gray-300 border rounded text-xs shrink-0 mt-2 ml-3",
                        onClick: (function (param) {
                            return Curry._1(send, {
                                        TAG: /* ShowTopicEditor */9,
                                        _0: true
                                      });
                          })
                      }, React.createElement("i", {
                            className: "far fa-edit"
                          }), React.createElement("span", {
                            className: "hidden md:inline-block ml-1"
                          }, t(undefined, undefined, "edit_topic_button"))) : null, moderator || isTopicCreator(firstPost, currentUserId) ? React.createElement("button", {
                        className: "topics-show__title-edit-button inline-flex items-center font-semibold p-2 md:py-1 bg-gray-50 hover:bg-gray-300 border rounded text-xs shrink-0 mt-2 ml-2",
                        disabled: state.changingLockedStatus,
                        onClick: (function (param) {
                            if (isLocked) {
                              return unlockTopic(topicId, send);
                            } else {
                              return lockTopic(topicId, currentUserId, send);
                            }
                          })
                      }, React.createElement(PfIcon.make, {
                            className: "fa fa-" + (
                              isLocked ? "unlock" : "lock"
                            )
                          }), React.createElement("span", {
                            className: "hidden md:inline-block ml-1"
                          }, isLocked ? t(undefined, undefined, "unlock_topic_button") : t(undefined, undefined, "lock_topic_button"))) : null)), tmp$2, React.createElement("div", {
              className: "lg:pl-14"
            }, React.createElement(TopicsShow__SubscriptionManager.make, {
                  topicId: TopicsShow__Topic.id(topic),
                  subscribed: state.subscribed,
                  subscribeCB: (function (param) {
                      return Curry._1(send, /* Subscribe */6);
                    }),
                  unsubscribeCB: (function (param) {
                      return Curry._1(send, /* Unsubscribe */7);
                    })
                })));
  }
  var _lockedAt = TopicsShow__Topic.lockedAt(state.topic);
  var tmp$3;
  if (_lockedAt !== undefined) {
    tmp$3 = React.createElement("div", {
          className: "flex p-4 bg-yellow-100 text-yellow-900 border border-yellow-500 border-l-4 rounded-r-md mt-2 mx-auto w-full max-w-4xl mb-4 text-sm justify-center items-center"
        }, React.createElement("div", {
              className: "w-6 h-6 text-yellow-500 shrink-0"
            }, React.createElement("i", {
                  className: "fa fa-lock"
                })), React.createElement("span", {
              className: "ml-2"
            }, t(undefined, undefined, "locked_topic_notice")));
  } else {
    var partial_arg = state.replyToPostId;
    var tmp$4 = {
      id: "add-reply-to-topic",
      topic: topic,
      currentUserId: currentUserId,
      handlePostCB: (function (param) {
          return Curry._1(send, {
                      TAG: /* SaveReply */0,
                      _0: param,
                      _1: partial_arg
                    });
        }),
      replies: state.replies,
      users: users,
      removeReplyToPostCB: (function (param) {
          return Curry._1(send, /* RemoveReplyToPost */2);
        })
    };
    if (state.replyToPostId !== undefined) {
      tmp$4.replyToPostId = Caml_option.valFromOption(state.replyToPostId);
    }
    tmp$3 = React.createElement(TopicsShow__PostEditor.make, tmp$4);
  }
  return React.createElement("div", {
              className: "bg-gray-50"
            }, React.createElement("div", {
                  className: "max-w-4xl w-full mt-5 pl-4 lg:pl-0 lg:mx-auto"
                }, communityLink(community)), React.createElement("div", {
                  className: "flex-col items-center justify-between"
                }, tmp, React.createElement("div", {
                      className: "max-w-4xl w-full mx-auto bg-white p-4 lg:p-8 my-4 border-t border-b md:border-0 lg:rounded-lg lg:shadow"
                    }, React.createElement("div", {
                          "aria-label": t(undefined, undefined, "topic_details")
                        }, tmp$1, React.createElement(TopicsShow__PostShow.make, {
                              post: state.firstPost,
                              topic: state.topic,
                              users: users,
                              posts: state.replies,
                              currentUserId: currentUserId,
                              moderator: moderator,
                              isTopicCreator: isTopicCreator(firstPost, currentUserId),
                              updatePostCB: (function (param) {
                                  return Curry._1(send, {
                                              TAG: /* UpdateFirstPost */4,
                                              _0: param
                                            });
                                }),
                              addNewReplyCB: (function (param) {
                                  return Curry._1(send, {
                                              TAG: /* AddNewReply */1,
                                              _0: undefined
                                            });
                                }),
                              addPostLikeCB: (function (param) {
                                  return Curry._1(send, /* LikeFirstPost */0);
                                }),
                              removePostLikeCB: (function (param) {
                                  return Curry._1(send, /* RemoveLikeFromFirstPost */1);
                                }),
                              markPostAsSolutionCB: (function (param) {
                                  
                                }),
                              unmarkPostAsSolutionCB: (function (param) {
                                  
                                }),
                              archivePostCB: (function (param) {
                                  return archiveTopic(community);
                                }),
                              topicSolutionId: topicSolutionId(state.replies),
                              key: TopicsShow__Post.id(state.firstPost)
                            })), React.createElement("h5", {
                          className: "pt-4 pb-2 lg:ml-14 border-b"
                        }, String(state.replies.length) + " " + ts(undefined, undefined, "replies")), $$Array.map((function (reply) {
                            var partial_arg = TopicsShow__Post.id(reply);
                            return React.createElement("div", {
                                        key: TopicsShow__Post.id(reply),
                                        className: "topics-show__replies-wrapper"
                                      }, React.createElement(TopicsShow__PostShow.make, {
                                            post: reply,
                                            topic: state.topic,
                                            users: users,
                                            posts: state.replies,
                                            currentUserId: currentUserId,
                                            moderator: moderator,
                                            isTopicCreator: isTopicCreator(firstPost, currentUserId),
                                            updatePostCB: (function (param) {
                                                return Curry._1(send, {
                                                            TAG: /* UpdateReply */5,
                                                            _0: param
                                                          });
                                              }),
                                            addNewReplyCB: (function (param) {
                                                return Curry._1(send, {
                                                            TAG: /* AddNewReply */1,
                                                            _0: partial_arg
                                                          });
                                              }),
                                            addPostLikeCB: (function (param) {
                                                return Curry._1(send, {
                                                            TAG: /* LikeReply */2,
                                                            _0: reply
                                                          });
                                              }),
                                            removePostLikeCB: (function (param) {
                                                return Curry._1(send, {
                                                            TAG: /* RemoveLikeFromReply */3,
                                                            _0: reply
                                                          });
                                              }),
                                            markPostAsSolutionCB: (function (param) {
                                                return Curry._1(send, {
                                                            TAG: /* MarkReplyAsSolution */11,
                                                            _0: TopicsShow__Post.id(reply)
                                                          });
                                              }),
                                            unmarkPostAsSolutionCB: (function (param) {
                                                return Curry._1(send, /* UnmarkReplyAsSolution */3);
                                              }),
                                            archivePostCB: (function (param) {
                                                return Curry._1(send, {
                                                            TAG: /* ArchivePost */6,
                                                            _0: TopicsShow__Post.id(reply)
                                                          });
                                              }),
                                            topicSolutionId: topicSolutionId(state.replies)
                                          }));
                          }), TopicsShow__Post.sort(state.replies))), React.createElement("div", {
                      className: "mt-4 px-4"
                    }, tmp$3)));
}

var make = TopicsShow__Root;

export {
  t ,
  ts ,
  str ,
  reducer ,
  subscribe ,
  unsubscribe ,
  addNewReply ,
  updateReply ,
  updateFirstPost ,
  saveReply ,
  isTopicCreator ,
  archiveTopic ,
  UpdateTopicQuery ,
  updateTopic ,
  LockTopicQuery ,
  UnlockTopicQuery ,
  lockTopic ,
  unlockTopic ,
  communityLink ,
  topicCategory ,
  categoryDropdownSelected ,
  topicCategorySelector ,
  topicSolutionId ,
  make ,
  
}
/*  Not a pure module */
